import { SVGProps } from 'react'

const WardrobeIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 18C9.38899 18 11.4519 16.6038 12.4172 14.5828L14 13L16 15H17L19 13L21 15L23.6464 12.3536C23.8417 12.1583 23.8417 11.8417 23.6464 11.6464L21.1464 9.14645C21.0527 9.05268 20.9255 9 20.7929 9H12.1973C11.1599 7.2066 9.22085 6 7 6C3.68629 6 1 8.68629 1 12C1 15.3137 3.68629 18 7 18ZM5 14C6.10457 14 7 13.1046 7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default WardrobeIcon
