module.exports = {
  primary: {
    400: '#DAA520',
    200: '#E4BF61',
  },
  mono: {
    900: '#1C1C1E',
    800: '#313035',
    700: '#3D3C41',
    600: '#525157',
    300: '#99989F',
    200: '#C4C3CC',
    100: '#E1E0E7',
  },
  bg: {
    900: '#1C1C1E',
    800: '#313035',
  },
  typoContrast: {
    900: '#1C1C1E',
    800: '#61636B',
    700: '#787B85',
  },
  typo: {
    100: '#F0F0F0',
    200: '#C4C3CC',
    300: '#99989F',
  },
  section1: {
    100: '#FFA39D',
    200: '#FF8982',
    300: '#FE6F6F',
    400: '#FF5151',
  },
  section2: {
    100: '#F0A670',
    200: '#EE9453',
    300: '#F07F2D',
    400: '#F37112',
  },
  section3: {
    100: '#FBA7D5',
    200: '#FA7CC1',
    300: '#F567B5',
    400: '#F3349C',
  },
  section4: {
    100: '#D79DEC',
    200: '#C776E4',
    300: '#BF5FE1',
    400: '#AB34D5',
  },
  section5: {
    100: '#B1D8F4',
    200: '#82BEE0',
    300: '#6CB5EA',
    400: '#47A0D2',
  },
  section6: {
    100: '#7EE7D4',
    200: '#41D5CA',
    300: '#27C4BB',
    400: '#26ABA2',
  },
  section7: {
    100: '#BDD47D',
    200: '#BDC750',
    300: '#9EC03F',
    400: '#A0AA37',
  },
  section8: {
    100: '#A5A8C0',
    200: '#BEC1D3',
    300: '#888CAC',
    400: '#9B9EB9',
  },
  section9: {
    100: '#C4C3CC',
    200: '#E1E0E7',
    300: '#9D9CAB',
    400: '#BAB8C7',
  },
  section10: {
    100: '#292929',
    200: '#333333',
    300: '#0A0A0A',
    400: '#121212',
  },
  section11: {
    100: '#34453A',
    200: '#2E3833',
    300: '#5ACB87',
    400: '#37B478',
  },
}
