import { HTMLProps, useCallback, useMemo } from 'react'
import { Image } from 'react-datocms'
import colors from '../../data/colors'
import {
  CARD_BUILDING_HEIGHT,
  CARD_BUILDING_WIDTH_DESKTOP,
  CARD_WIDTH,
} from '../../lib/static-values'
import ArrowRightLargeIcon from '../icons/arrow-right-large-icon'
import ArrowDownLargeIcon from '../icons/arrow-down-large-icon'
import { Card } from '../../types/types'
import Link from 'next/link'
import { useActiveCardStore } from '../../hooks/store/use-active-card'
import {
  getFavoritesString,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'
import {
  getTaggedString,
  useTaggedCardIdsStore,
} from '../../hooks/store/use-tagged-card-ids'
import {
  getActiveTagListString,
  useActiveTagListStore,
} from '../../hooks/store/use-active-tag-list'
import {
  getActiveTagString,
  useActiveTagStore,
} from '../../hooks/store/use-active-tag'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'

const useCardLink = (cardId: string) => {
  const favoritesString = useFavoritesStore(getFavoritesString)
  const taggedString = useTaggedCardIdsStore(getTaggedString)
  const detailPage = useActiveCardStore(
    useCallback(
      (state) => (state.activeCard?.id === cardId ? cardId : '-'),
      [],
    ),
  )
  const activeTagListId = useActiveTagListStore(getActiveTagListString)
  const activeTagId = useActiveTagStore(getActiveTagString)
  const orbit = useOrbitStore(getOrbit)

  return useMemo(() => {
    return `${cardId}/${orbit}/${detailPage}/false/0/${favoritesString}/${activeTagListId}/${activeTagId}/${taggedString}`
  }, [
    orbit,
    detailPage,
    favoritesString,
    activeTagListId,
    activeTagId,
    taggedString,
  ])
}

export const CFCard = ({
  hasTouch,
  cardRef,
  card,
  ...props
}: HTMLProps<HTMLAnchorElement> & {
  hasTouch: boolean
  cardRef: (card: HTMLAnchorElement | null) => void
  card: Card
}) => {
  const { title, img, subtitle, id } = card
  const url = useCardLink(id)

  return (
    <Link href={url} passHref shallow>
      <a
        {...props}
        className={`relative z-50 h-[${CARD_BUILDING_HEIGHT}px] flex text-typo-100 text-right ${props.className}`}
        style={{
          width: `${hasTouch ? CARD_WIDTH : CARD_BUILDING_WIDTH_DESKTOP}px`,
          height: `${CARD_BUILDING_HEIGHT}px`,
          ...props.style,
        }}
        ref={cardRef}
        data-id={card.id}
      >
        <div className="absolute top-0 desktop:top-px left-px desktop:left-0 right-px desktop:right-0 bottom-0 desktop:bottom-px bg-bg-800" />
        {img && (
          <div className="absolute top-0 desktop:top-px bottom-0 desktop:bottom-px left-px desktop:left-0 right-px desktop:right-0 overflow-hidden flex items-center justify-center">
            <Image
              data={img.responsiveImage}
              className="z-0 pointer-events-none absolute w-full h-auto "
            />

            <div
              className="absolute left-0 top-0 w-full h-full z-10 mix-blend-multiply bg-gradient-to-t from-transparent to-dark opacity-40"
              style={{
                background: `linear-gradient(180deg, ${colors.bg[900]}, ${
                  colors.bg[900] + '9e'
                } 57.29%, ${colors.bg[900] + '00'} 100%)`,
              }}
            />
          </div>
        )}
        <div className="z-10 desktop:flex w-full">
          <div
            className={
              'flex desktop:min-w-[204px] desktop:max-w-[204px] flex-col py-3'
            }
          >
            <div className="absolute left-3 desktop:left-auto bottom-2 desktop:bottom-3 desktop:right-3 cursor-pointer">
              {hasTouch ? <ArrowRightLargeIcon /> : <ArrowDownLargeIcon />}
            </div>

            <div className="px-4 pb-0 text-left font-black text-lg leading-none desktop:text-3xl desktop:px-3">
              {title}
              <br />
              {subtitle}
            </div>
          </div>
        </div>
      </a>
    </Link>
  )
}
