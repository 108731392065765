import create from 'zustand'

export type IsInitialStore = {
  isInitial: boolean
  setIsInitial: (payload: boolean) => void
}
export const useIsInitialStore = create<IsInitialStore>((set) => ({
  isInitial: false,
  setIsInitial: (payload) => set(() => ({ isInitial: payload })),
}))

export const getIsInitial = (state: IsInitialStore) => state.isInitial
