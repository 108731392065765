import { SVGProps } from 'react'

const ArrowUpIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`text-typo-100 ${props.className}`}
    >
      <path
        d="M5.05676 12.8497L4.35355 12.1465C4.15829 11.9513 4.15829 11.6347 4.35355 11.4394L11.6464 4.14652C11.8417 3.95126 12.1583 3.95126 12.3536 4.14652L19.6464 11.4394C19.8417 11.6347 19.8417 11.9513 19.6464 12.1465L18.9432 12.8497C18.7481 13.0449 18.4318 13.045 18.2364 12.85L13 7.62297L13 19.293C13 19.5691 12.7761 19.793 12.5 19.793L11.5 19.793C11.2239 19.793 11 19.5691 11 19.293L11 7.62297L5.76355 12.85C5.56824 13.045 5.2519 13.0449 5.05676 12.8497Z"
        fill="currentColor"
        {...pathProps}
      />
    </svg>
  )
}

export default ArrowUpIcon
