import { OrthographicCamera, PerspectiveCamera, Vector2, Vector3 } from 'three'

export const toScreenPos = (
  width: number,
  height: number,
  camera: PerspectiveCamera | OrthographicCamera,
  vector?: Vector3 | null,
) => {
  if (!vector) {
    return null
  }
  const widthHalf = 0.5 * width
  const heightHalf = 0.5 * height
  const target = vector.clone()

  target.project(camera)

  return new Vector2(
    Math.round((target.x * widthHalf + widthHalf) * 100) / 100,
    Math.round((-(target.y * heightHalf) + heightHalf) * 100) / 100,
  )
}
