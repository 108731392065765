import { SVGProps } from 'react'

const GiftShopIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3H8.79289C8.9255 3 9.05268 3.05268 9.14645 3.14645L11 5H9H6C5.44772 5 5 4.55228 5 4C5 3.44772 5.44772 3 6 3ZM2.5 7C2.22386 7 2 7.22386 2 7.5V10.5C2 10.7761 2.22386 11 2.5 11H11V7H2.5ZM11 13H4V20.5C4 20.7761 4.22386 21 4.5 21H11V13ZM18.0005 3H15.2076C15.075 3 14.9479 3.05268 14.8541 3.14645L13.0005 5H15.0005H18.0005C18.5528 5 19.0005 4.55228 19.0005 4C19.0005 3.44772 18.5528 3 18.0005 3ZM21.5 7C21.7761 7 22 7.22386 22 7.5V10.5C22 10.7761 21.7761 11 21.5 11H13V7H21.5ZM13.0009 13H20.0009V20.5C20.0009 20.7761 19.777 21 19.5009 21H13.0009V13Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default GiftShopIcon
