import { SVGProps } from 'react'

const ExitIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5C16.1046 5 17 4.10457 17 3C17 1.89543 16.1046 1 15 1C13.8954 1 13 1.89543 13 3C13 4.10457 13.8954 5 15 5ZM10.2441 18.49C10.6034 18.5575 10.9554 18.3484 11.0347 18.0203L11.8926 14.4706L13.9945 16.3529V21.3846C13.9945 21.7245 14.2951 22 14.666 22H15.3249C15.6957 22 15.9964 21.7245 15.9964 21.3846V14.9412L13.8944 13.0588L14.495 10.2353C15.6627 11.5023 17.3948 12.3903 19.3294 12.5591C19.6986 12.5913 20 12.3127 20 11.9729V11.3213C20 10.9814 19.698 10.7102 19.3303 10.6663C17.726 10.475 16.4009 9.60685 15.6961 8.44706L14.6952 6.94118C14.2948 6.37647 13.6943 6 12.9936 6C12.6933 6 12.5599 6 12.1929 6.09412L7.39224 8.00395C7.14689 8.10155 6.98814 8.32331 6.98814 8.56844V11.9729C6.98814 12.3127 7.28876 12.5882 7.65959 12.5882H8.31852C8.68935 12.5882 8.98997 12.3127 8.98997 11.9729V9.38824L10.7916 8.72941L9.19015 16.3529L4.95206 15.5396C4.58554 15.4693 4.22707 15.6874 4.15542 16.0242L4.01253 16.696C3.94207 17.0273 4.17587 17.3488 4.5366 17.4166L10.2441 18.49Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default ExitIcon
