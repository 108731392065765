import { OpeningHoursData } from '../../types/types'
import create from 'zustand'

export type OpeningHoursStore = {
  openingHours: OpeningHoursData | null
  setOpeningHours: (payload: OpeningHoursData) => void
}
export const useOpeningHoursStore = create<OpeningHoursStore>((set) => ({
  openingHours: null,
  setOpeningHours: (payload) => set(() => ({ openingHours: payload })),
}))

export const getOpeningHours = (state: OpeningHoursStore) => state.openingHours
