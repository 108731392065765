import { SVGProps } from 'react'

const CopyIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.625 9.24996C9.86559 8.49059 9.86559 7.25941 10.625 6.50004L14.7499 2.37515C15.5092 1.61578 16.7404 1.61578 17.4998 2.37515L21.6247 6.50004C22.384 7.25941 22.384 8.49059 21.6247 9.24996L17.4998 13.3749C16.7404 14.1342 15.5092 14.1342 14.7499 13.3749L14.4159 13.0409C14.2207 12.8457 14.2207 12.5291 14.4159 12.3338L15.0838 11.666C15.279 11.4707 15.5956 11.4707 15.7909 11.666L16.1248 11.9999L20.2497 7.875L16.1248 3.75011L11.9999 7.875L12.3339 8.20893C12.5291 8.40419 12.5291 8.72077 12.3339 8.91603L11.666 9.58389C11.4707 9.77915 11.1542 9.77915 10.9589 9.58389L10.625 9.24996Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.375 14.7495C14.1344 15.5089 14.1344 16.7401 13.375 17.4995L9.25015 21.6244C8.49078 22.3837 7.25959 22.3837 6.50022 21.6244L2.37533 17.4995C1.61596 16.7401 1.61596 15.5089 2.37533 14.7495L6.50022 10.6247C7.25959 9.86529 8.49078 9.86529 9.25015 10.6247L9.58408 10.9586C9.77934 11.1538 9.77934 11.4704 9.58408 11.6657L8.91622 12.3335C8.72096 12.5288 8.40437 12.5288 8.20911 12.3335L7.87518 11.9996L3.75029 16.1245L7.87518 20.2494L12.0001 16.1245L11.6661 15.7906C11.4709 15.5953 11.4709 15.2787 11.6661 15.0835L12.334 14.4156C12.5293 14.2204 12.8458 14.2204 13.0411 14.4156L13.375 14.7495Z"
        fill="currentColor"
      />
      <path
        d="M8.56248 15.437C8.1828 15.0573 8.1828 14.4417 8.56248 14.062L14.0623 8.56218C14.442 8.18249 15.0576 8.18249 15.4373 8.56218C15.817 8.94186 15.817 9.55745 15.4373 9.93714L9.93745 15.437C9.55776 15.8167 8.94217 15.8167 8.56248 15.437Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CopyIcon
