import create from 'zustand'
import { INITIAL_ROTATION } from '../../lib/static-values'

export type OrbitStore = {
  orbit: number
  setOrbit: (payload: number) => void
}
export const useOrbitStore = create<OrbitStore>((set) => ({
  orbit: INITIAL_ROTATION,
  setOrbit: (payload) => set(() => ({ orbit: payload })),
}))

export const getOrbit = (state: OrbitStore) => state.orbit
