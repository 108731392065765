import { Card, Floor } from '../../types/types'
import create from 'zustand'

export type ActiveCardStore = {
  activeCard: Card | null
  setActiveCard: (payload: Card | null) => void
}
export type ActiveFloorStore = {
  activeFloor: Floor | null
  setActiveFloor: (payload: Floor | null) => void
}
export type ActiveBuildingStore = {
  activeBuilding: string | null
  setActiveBuilding: (payload: string | null) => void
}
export const useActiveCardStore = create<ActiveCardStore>((set) => ({
  activeCard: null,
  setActiveCard: (payload) => set(() => ({ activeCard: payload })),
}))

export const useActiveFloorStore = create<ActiveFloorStore>((set) => ({
  activeFloor: null,
  setActiveFloor: (payload) => set(() => ({ activeFloor: payload })),
}))

export const useActiveBuildingStore = create<ActiveBuildingStore>((set) => ({
  activeBuilding: null,
  setActiveBuilding: (payload) => set(() => ({ activeBuilding: payload })),
}))

export const getActiveCard = (state: ActiveCardStore) => state.activeCard

export const getActiveFloor = (state: ActiveFloorStore) => state.activeFloor

export const getActiveBuilding = (state: ActiveBuildingStore) =>
  state.activeBuilding

export const getActiveCardString = (state: ActiveCardStore) =>
  state.activeCard?.id || '-'
