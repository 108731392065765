import create from 'zustand'
import { UsefulInfos } from '../../types/types'

export type UsefulInfosStore = {
  usefulInfos: UsefulInfos[]
  setUsefulInfos: (payload: UsefulInfos[]) => void
}

export const useUsefulInfos = create<UsefulInfosStore>((set) => ({
  usefulInfos: [],
  setUsefulInfos: (payload) => set(() => ({ usefulInfos: payload })),
}))

export const getUsefulInfos = (state: UsefulInfosStore) => state.usefulInfos
