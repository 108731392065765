import { SVGProps } from 'react'

export const IconChevronDown = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.241 8.37036C16.4375 8.16756 16.7628 8.16756 16.9592 8.37036L17.6631 9.09689C17.8509 9.29079 17.8509 9.59879 17.6631 9.79269L12.3592 15.2675C12.1628 15.4703 11.8375 15.4703 11.641 15.2675L6.33717 9.79269C6.14933 9.59879 6.14933 9.29079 6.33717 9.09689L7.04102 8.37036C7.23748 8.16756 7.56278 8.16756 7.75925 8.37036L11.641 12.3772C11.8375 12.58 12.1628 12.58 12.3592 12.3772L16.241 8.37036Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}
export default IconChevronDown
