import { SVGProps } from 'react'

const ArrowDownLargeIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
      {...props}
    >
      <path
        fill="#F0F0F0"
        d="M5.14645 21.5607C4.95118 21.3655 4.95118 21.0489 5.14645 20.8536L5.85355 20.1465C6.04882 19.9513 6.3654 19.9513 6.56066 20.1465L16.9999 30.5858L16.9999 1.5C16.9999 1.22386 17.2238 1 17.4999 1L18.4999 1C18.7761 1 18.9999 1.22386 18.9999 1.5L18.9999 30.5859L29.4393 20.1465C29.6346 19.9513 29.9512 19.9513 30.1464 20.1465L30.8536 20.8536C31.0488 21.0489 31.0488 21.3655 30.8536 21.5607L18.3536 34.0607C18.1583 34.256 17.8417 34.256 17.6464 34.0607L5.14645 21.5607Z"
        {...pathProps}
      />
    </svg>
  )
}

export default ArrowDownLargeIcon
