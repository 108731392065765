import { Card } from '../types/types'

export const emptyCard: Card = {
  id: '',
  title: '',
  subtitle: '',
  type: 'card',
  building: '',
  buildingId: '',
  floor: null,
  floorName: '',
  color: '',
  secondaryColor: false,
  img: null,
  roomTarget: null,
  isInactive: false,
  offset: -1,
  width: -1,
  height: -1,
  jumpToIndex: null,
  tags: [],
}
