import {
  MutableRefObject,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { Box, Html, useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { Box3, Color, Group, Material, Mesh, MeshPhongMaterial } from 'three'
import { useSpring } from '@react-spring/three'
import { Room } from '../../types/types'
import { hasIcon, renderIcon } from '../icons/render-icons'
import colors from '../../data/colors'
import { defaultMaterial } from './render-map'
import { renderSVG } from '../../lib/render-svg'
import { ThreeEvent } from '@react-three/fiber'
import {
  ActiveCardStore,
  useActiveCardStore,
} from '../../hooks/store/use-active-card'
import {
  useZoomLevelStore,
  ZoomLevelStore,
} from '../../hooks/store/use-zoom-level'
import { getIsReady, useIsReadyStore } from '../../hooks/store/use-is-ready'
import { getRooms, useRoomsStore } from '../../hooks/store/use-rooms'
import {
  getFirstBuildingCards,
  useFirstBuildingCards,
} from '../../hooks/store/use-first-building-cards'
import { getCards, useCardsStore } from '../../hooks/store/use-cards'

type GLTFResult = GLTF & {
  nodes: { [key: string]: Mesh }
  materials: {
    [key: string]: MeshPhongMaterial
  }
}

const handlePointerEnter = (e?: ThreeEvent<PointerEvent>) => {
  if (e?.intersections[0]?.object !== e?.object) {
    return
  }
  document.body.style.cursor = 'pointer'
}

const handlePointerLeave = (e?: ThreeEvent<PointerEvent>) => {
  if (
    e?.intersections[0]?.object.name
      .toLocaleLowerCase()
      .match(/(moser|chipperfield|sculpture)/g)
  ) {
    return
  }
  document.body.style.cursor = 'auto'
}

const RenderExteriorIcons = ({
  mesh,
  room,
  iconRef,
}: {
  mesh: Mesh
  room?: Room | null
  iconRef?: Ref<HTMLDivElement>
}) => {
  const mayHasIcon = useMemo(
    () =>
      room &&
      room.icons &&
      room.icons.find(({ icon }) => !!mesh.name.match(new RegExp(`${icon}`))),
    [room],
  )

  if (!hasIcon(mesh.name) || (room !== null && !mayHasIcon)) {
    return null
  }

  return (
    <mesh {...mesh}>
      <Html
        center
        occlude
        zIndexRange={[10, 20]}
        className="pointer-events-none text-primary-200"
        ref={iconRef}
      >
        {renderIcon({
          name: mesh.name,
          key: `${mesh.name}_${mesh.uuid}`,
          backgroundColor: mesh.name.match(/mainentrance/)
            ? 'currentColor'
            : 'default',
          pathProps: mesh.name.match(/mainentrance/)
            ? {
                fill: colors.typoContrast[900],
              }
            : undefined,
        })}
      </Html>
    </mesh>
  )
}

const RenderBuilding = ({
  building,
  room,
  meshRefs,
  iconRef,
  onPointerEnter,
  onPointerLeave,
  onPointerUp,
}: {
  onPointerUp?: (e?: ThreeEvent<PointerEvent>) => void
  onPointerEnter?: (e?: ThreeEvent<PointerEvent>) => void
  onPointerLeave?: (e?: ThreeEvent<PointerEvent>) => void
  building: { mesh: Mesh; material: Material }
  room: Room | undefined
  meshRefs: MutableRefObject<Mesh[]>
  iconRef?: Ref<HTMLDivElement>
}) => {
  const spriteRefs = useRef<Mesh[]>([])
  useEffect(() => {
    if (!spriteRefs.current || !spriteRefs.current.length) {
      return
    }

    spriteRefs.current.forEach((mesh) => {
      if (!mesh || !mesh.name.match(/mainentrance/)) {
        return
      }
      const mayHasIcon =
        room &&
        room.icons &&
        room.icons.length &&
        room.icons.find(({ icon }) => !!mesh.name.match(new RegExp(`${icon}`)))

      if (!mayHasIcon) {
        return
      }

      renderSVG(mesh, '/icons/ic_24_entrance.svg', 0.2, room)
    })
  }, [building])

  const meshIcons = useMemo(() => {
    if (!room?.icons.length) {
      return [] as Mesh[]
    }

    return building.mesh.children.filter((icon) => {
      const foundRoomIcon = room?.icons.find(({ icon: item, index }) => {
        const withIndex = icon.name.match(/-\d*$/)

        const cleanIndex = withIndex ? withIndex[0].replace('-', '') : 0

        const foundIndex =
          withIndex && index ? index.toString() === cleanIndex : true
        return (
          foundIndex &&
          icon &&
          !!icon.name.match(new RegExp(item as string)) &&
          !icon.name.match(/mainentrance/)
        )
      })

      return !!foundRoomIcon
    }) as Mesh[]
  }, [building, room])

  return (
    <group
      rotation={building.mesh.rotation}
      position={building.mesh.position}
      scale={building.mesh.scale}
      onPointerUp={onPointerUp}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      {building.mesh.children.map((item) => {
        const mesh = item as Mesh
        return (
          <mesh
            {...mesh}
            ref={(meshRef) => {
              if (!meshRef) {
                return
              }
              const uuid = (meshRef as Mesh).uuid
              const hasSprite = spriteRefs.current.find(
                (refMesh) => refMesh.uuid === uuid,
              )
              const hasRef = meshRefs.current.find(
                (refMesh) => refMesh.uuid === uuid,
              )
              !hasRef && meshRefs.current.push(meshRef as Mesh)

              !hasSprite && spriteRefs.current.push(meshRef as Mesh)
            }}
            castShadow
            receiveShadow
            geometry={mesh.geometry}
            material={building.material}
            key={mesh.uuid}
          />
        )
      })}
      {meshIcons.length
        ? meshIcons.map((mesh) => (
            <RenderExteriorIcons
              mesh={mesh}
              room={room}
              iconRef={iconRef}
              key={mesh.uuid}
            />
          ))
        : null}
    </group>
  )
}

const useShouldUpdate = () => {
  const shouldUpdate = useCallback((state: ActiveCardStore) => {
    return (
      !state.activeCard ||
      state.activeCard.building === 'Aussenraum' ||
      state.activeCard.type === 'building'
    )
  }, [])
  return useActiveCardStore(shouldUpdate)
}
const useBuilding = () => {
  const shouldUpdate = useCallback((state: ActiveCardStore) => {
    return !state.activeCard || state.activeCard.type === 'building'
  }, [])
  return useActiveCardStore(shouldUpdate)
}

const useIsTransparent = () => {
  const calcDistance = useCallback((state: ZoomLevelStore) => {
    return state.zoomLevel <= 2
  }, [])

  return useZoomLevelStore(calcDistance)
}

const useFirstCard = (building: 'Moser' | 'CF') => {
  const firstCards = useFirstBuildingCards(getFirstBuildingCards)
  return useMemo(() => {
    if (building === 'Moser') {
      return firstCards[0]
    }
    return firstCards[1]
  }, [])
}

export const RenderBuildings = ({
  url,
  moserRoom,
  cfRoom,
  boundingBox,
  handleBuildingClick,
}: {
  handleBuildingClick: (id: string) => void
  url: string
  moserRoom?: Room
  cfRoom?: Room
  boundingBox: MutableRefObject<Box3 | null>
}) => {
  const cards = useCardsStore(getCards)
  const isReady = useIsReadyStore(getIsReady)
  const { nodes } = useGLTF(url) as GLTFResult
  const group = useRef<Group>(null)
  const isInitial = useRef(true)
  const shouldShowGroup = useRef(false)
  const isTransparent = useIsTransparent()
  const activeCard = useActiveCardStore((state) => state.activeCard)
  const hitAreas = useRef<[width: number, height: number, depth: number][]>([])

  const rooms = useRoomsStore(getRooms)
  const setRooms = useRoomsStore(useCallback((state) => state.setRooms, []))

  const firstMoserCard = useFirstCard('Moser')
  const firstCfCard = useFirstCard('CF')

  const buildingMat = useMemo(() => {
    const mat = defaultMaterial.clone()
    mat.transparent = true
    return mat
  }, [])
  const streetMat = useMemo(() => {
    const mat = defaultMaterial.clone()
    mat.transparent = true
    mat.color = new Color(colors.section10[100])
    return mat
  }, [])

  const gardenMatPrimary = useMemo(() => {
    const mat = defaultMaterial.clone()
    mat.transparent = true
    mat.color = new Color(colors.section11[100])
    return mat
  }, [])

  const gardenMatSecondary = useMemo(() => {
    const mat = defaultMaterial.clone()
    mat.transparent = true
    mat.color = new Color(colors.section11[200])
    return mat
  }, [])

  const passageMat = useMemo(() => {
    const mat = defaultMaterial.clone()
    mat.transparent = true
    mat.color = new Color(colors.section10[200])
    return mat
  }, [])

  const sculptureMat = useMemo(() => {
    const mat = defaultMaterial.clone()
    mat.transparent = true
    mat.color = new Color(colors.mono[600])
    return mat
  }, [])

  const hitAreaMat = useMemo(() => {
    const mat = defaultMaterial.clone()
    mat.transparent = true
    mat.opacity = 0
    mat.depthWrite = false
    return mat
  }, [])

  const meshRefs = useRef<Mesh[]>([])
  const iconRefs = useRef<HTMLDivElement[]>([])
  const opacity = useRef(1)

  const meshes = useMemo(
    () =>
      Object.values(nodes).reduce((acc, mesh) => {
        if (mesh.parent?.name === 'Scene') {
          const material = buildingMat

          acc.push({ mesh: mesh as Mesh, material })
        }
        return acc
      }, [] as { mesh: Mesh; material: MeshPhongMaterial }[]),
    [nodes],
  )

  const moserMesh = useMemo(
    () => meshes.find(({ mesh }) => mesh.name === 'moser'),
    [meshes],
  )
  const cfMesh = useMemo(
    () => meshes.find(({ mesh }) => mesh.name === 'chipperfield'),
    [meshes],
  )
  const exterior = useMemo(() => {
    const exteriorMeshes = meshes.filter(
      ({ mesh }) =>
        mesh.name !== 'passage' &&
        mesh.name !== 'chipperfiled' &&
        mesh.name !== 'moser' &&
        !mesh.name.match(/sculpture/) &&
        mesh.geometry,
    )
    exteriorMeshes.forEach((item) => {
      item.material = streetMat
    })
    return exteriorMeshes
  }, [meshes])
  const garden = useMemo(() => {
    const gardenMeshes = meshes.filter(
      ({ mesh }) => mesh.name.match(/garden/) && mesh.geometry,
    )
    gardenMeshes.forEach((item) => {
      item.material = item.mesh.name.match(/primary/)
        ? gardenMatPrimary
        : gardenMatSecondary
    })
    return gardenMeshes
  }, [meshes])
  const passage = useMemo(
    () => meshes.find(({ mesh }) => mesh.name === 'passage'),
    [meshes],
  )

  const sculptureMesh = useMemo(() => {
    const filteredMeshes = meshes.filter(({ mesh }) =>
      mesh.name.match(/sculpture/),
    )
    filteredMeshes.forEach((item) => {
      item.material = sculptureMat.clone()
    })
    return filteredMeshes
  }, [meshes])

  useEffect(() => {
    sculptureMesh.forEach(({ mesh, material }) => {
      const mayHasRoom = rooms.find((room) => room.room === mesh.name)

      if (mayHasRoom) {
        if (mayHasRoom.color) {
          material.color = new Color(...mayHasRoom.color)
        }
        mayHasRoom.coords = mesh.position
      }
    })
    setRooms([...rooms])
  }, [])

  useEffect(() => {
    sculptureMesh.forEach(({ mesh, material }) => {
      if (activeCard?.floor !== 10) {
        material.color = new Color(sculptureMat.color)
        return
      }
      const mayHasRoom = rooms.find((room) => room.room === mesh.name)

      if (mayHasRoom) {
        if (mayHasRoom.color) {
          material.color = new Color(...mayHasRoom.color)
        }
        mayHasRoom.coords = mesh.position
      }
    })
  }, [activeCard])

  const meshIcons = useMemo(
    () =>
      Object.values(nodes).reduce((acc, mesh) => {
        if (
          !!mesh.name.match(/icon-mainentrance/) &&
          mesh.parent?.name === 'Scene'
        ) {
          acc.push(mesh)
        }
        return acc
      }, [] as Mesh[]),
    [nodes],
  )

  const isInOverview = useShouldUpdate()
  const isBuilding = useBuilding()

  const springValues = useCallback(() => {
    return {
      reset: true,
      opacity: isInOverview ? 1 : isTransparent ? 0.5 : 0,
      immediate: isInitial.current,
    }
  }, [isInOverview, isTransparent])

  const [, setSpring] = useSpring(() => ({
    ...springValues(),
    firstCall: true,
    onStart: ({ value }) => {
      if (!group.current) {
        return
      }

      if (!boundingBox.current) {
        boundingBox.current = new Box3()
        boundingBox.current.setFromObject(group.current)
      }

      group.current.visible = value.opacity > 0
      buildingMat.transparent = true
      streetMat.transparent = true
      passageMat.transparent = true
      gardenMatSecondary.transparent = true
      gardenMatPrimary.transparent = true
      sculptureMesh.forEach(({ material }) => {
        material.transparent = true
      })
    },
    onChange: ({ value }) => {
      if (group.current) {
        group.current.visible = value.opacity > 0
      }
      opacity.current = value.opacity
      streetMat.opacity = value.opacity
      passageMat.opacity = value.opacity
      buildingMat.opacity = value.opacity
      gardenMatPrimary.opacity = value.opacity
      gardenMatSecondary.opacity = value.opacity
      sculptureMesh.forEach(({ material }) => {
        material.opacity = value.opacity
      })

      if (moserMesh) {
        moserMesh.mesh.visible = value.opacity !== 0
      }
      if (cfMesh) {
        cfMesh.mesh.visible = value.opacity !== 0
      }
    },
    onRest: ({ value }) => {
      buildingMat.transparent = !shouldShowGroup.current
      streetMat.transparent = !shouldShowGroup.current
      passageMat.transparent = !shouldShowGroup.current
      gardenMatPrimary.transparent = !shouldShowGroup.current
      gardenMatSecondary.transparent = !shouldShowGroup.current
      sculptureMat.transparent = !shouldShowGroup.current
      sculptureMesh.forEach(({ material }) => {
        material.transparent = !shouldShowGroup.current
      })

      if (group.current) {
        group.current.visible = value.opacity > 0
      }
    },
  }))

  useEffect(() => {
    streetMat.opacity = isInOverview ? 1 : 0
    buildingMat.opacity = isInOverview ? 1 : 0

    if (!group.current || boundingBox.current) {
      return
    }
    boundingBox.current = new Box3()
    boundingBox.current.setFromObject(group.current)
  }, [])

  useEffect(() => {
    if (!isReady) {
      return
    }

    if (!isInOverview) {
      document.body.style.cursor = 'auto'
    }

    setSpring.start({ ...springValues(), firstCall: false })

    meshRefs.current.forEach((mesh) => {
      if (!mesh || !mesh.name.match(/mainentrance/)) {
        return
      }
      mesh.visible = !!isBuilding
    })

    iconRefs.current.forEach((ref) => {
      ref.style.display = isBuilding ? 'block' : 'none'
    })

    isInitial.current = false
  }, [isReady, isTransparent, isBuilding, isInOverview])

  const handlePointerUp = useCallback(
    (e?: ThreeEvent<PointerEvent>) => {
      //Make sure its called only on the closest object
      if (e?.object !== e?.intersections[0].object) {
        return
      }
      const mesh = e?.object as Mesh
      if (!mesh) {
        return
      }
      const isMoser = mesh.name.match(/moser/)
      const isCF = mesh.name.match(/chipperfield/)
      if (isMoser && (firstMoserCard || activeCard?.floor === 10)) {
        handleBuildingClick(firstMoserCard?.id)
      }
      if (isCF && (firstCfCard || activeCard?.floor === 10)) {
        handleBuildingClick(firstCfCard.id)
      }
    },
    [handleBuildingClick],
  )

  return (
    <group ref={group}>
      {exterior.map(({ mesh, material }) => {
        return (
          <mesh
            {...mesh}
            ref={(ref) => {
              if (!ref) {
                return
              }
              const uuid = (ref as Mesh).uuid
              const hasRef = meshRefs.current.find(
                (refMesh) => refMesh.uuid === uuid,
              )
              !hasRef && meshRefs.current.push(ref as Mesh)
            }}
            castShadow
            receiveShadow
            geometry={mesh.geometry}
            material={material}
            key={mesh.uuid}
          />
        )
      })}
      {sculptureMesh.map(({ mesh, material }, index) => {
        return (
          <mesh
            {...mesh}
            ref={(ref) => {
              if (!ref) {
                return
              }
              const uuid = (ref as Mesh).uuid
              const hasRef = meshRefs.current.find(
                (refMesh) => refMesh.uuid === uuid,
              )
              if (!hasRef) {
                meshRefs.current.push(ref as Mesh)

                const bounding = new Box3()
                bounding.setFromObject(ref as Mesh)

                const x = bounding.max.x - bounding.min.x
                const y = bounding.max.y - bounding.min.y
                const z = bounding.max.z - bounding.min.z

                const hitArea = [
                  x > 9 ? x : 9,
                  z > 9 ? z : 9,
                  y > 9 ? y : 9,
                ] as typeof hitAreas.current[0]

                hitAreas.current.push(hitArea)
              }
            }}
            castShadow
            receiveShadow
            geometry={mesh.geometry}
            material={material}
            key={mesh.uuid}
          >
            <Box
              args={hitAreas.current[index]}
              position={[0, 0, -(hitAreas.current[index]?.[2] || 0) / 2]}
              name={`${mesh.name}_hitarea`}
              material={hitAreaMat}
              castShadow={false}
              onPointerUp={(e) => {
                if (e.intersections[0]?.object !== e.object) {
                  return
                }
                const mayFoundRoom = rooms.find(
                  (room) => room.room === mesh.name,
                )
                const card = cards.find(
                  (entry) =>
                    entry.roomTarget === mayFoundRoom?.id ||
                    entry.rooms?.[0] === mayFoundRoom?.id,
                )
                card && handleBuildingClick(card.id)
              }}
              onPointerEnter={
                activeCard?.floor === 10 ? handlePointerEnter : undefined
              }
              onPointerLeave={
                activeCard?.floor === 10 ? handlePointerLeave : undefined
              }
            />
          </mesh>
        )
      })}
      {garden.map(({ mesh, material }) => {
        return (
          <mesh
            {...mesh}
            ref={(ref) => {
              if (!ref) {
                return
              }
              const uuid = (ref as Mesh).uuid
              const hasRef = meshRefs.current.find(
                (refMesh) => refMesh.uuid === uuid,
              )
              !hasRef && meshRefs.current.push(ref as Mesh)
            }}
            castShadow
            receiveShadow
            geometry={mesh.geometry}
            material={material}
            key={mesh.uuid}
          />
        )
      })}
      {passage && (
        <group {...(passage.mesh as unknown as Group)}>
          {passage.mesh.children.map((mesh) => {
            if (!(mesh as Mesh).geometry) {
              return
            }
            const passageMesh = mesh as Mesh
            return (
              <mesh
                {...passageMesh}
                ref={(ref) => {
                  if (!ref) {
                    return
                  }
                  const uuid = (ref as Mesh).uuid
                  const hasRef = meshRefs.current.find(
                    (refMesh) => refMesh.uuid === uuid,
                  )
                  !hasRef && meshRefs.current.push(ref as Mesh)
                }}
                castShadow
                receiveShadow
                geometry={passageMesh.geometry}
                material={passageMat}
                key={passageMesh.uuid}
              />
            )
          })}
        </group>
      )}
      {moserMesh && (
        <RenderBuilding
          building={moserMesh}
          room={moserRoom}
          meshRefs={meshRefs}
          iconRef={(ref) => {
            ref && iconRefs.current.push(ref)
          }}
          onPointerUp={isInOverview ? handlePointerUp : undefined}
          onPointerEnter={isInOverview ? handlePointerEnter : undefined}
          onPointerLeave={isInOverview ? handlePointerLeave : undefined}
        />
      )}
      {cfMesh && (
        <RenderBuilding
          building={cfMesh}
          room={cfRoom}
          meshRefs={meshRefs}
          iconRef={(ref) => {
            ref && iconRefs.current.push(ref)
          }}
          onPointerUp={isInOverview ? handlePointerUp : undefined}
          onPointerEnter={isInOverview ? handlePointerEnter : undefined}
          onPointerLeave={isInOverview ? handlePointerLeave : undefined}
        />
      )}
      {meshIcons.length &&
        meshIcons.map((icon) => {
          if (icon.name.match(/mainentrance/)) {
            return
          }
          return (
            <mesh {...icon} key={icon.uuid}>
              <Html
                center
                occlude
                zIndexRange={[10, 20]}
                className="pointer-events-none text-primary-200"
                ref={(ref) => {
                  ref && iconRefs.current.push(ref)
                }}
              >
                {renderIcon({
                  name: icon.name,
                  key: `${icon.name}_${icon.uuid}`,
                })}
              </Html>
            </mesh>
          )
        })}
    </group>
  )
}
