import { Room } from '../types/types'

export const rotateToSculpture = (activeRoom: Room | null) => {
  let minMax = undefined as
    | {
        min: number
        range: number
        animateTo: number
      }
    | undefined
  switch (activeRoom?.room) {
    case 'sculpture-1':
      minMax = {
        min: -173,
        range: 281,
        animateTo: -46,
      }
      break
    case 'sculpture-2':
      minMax = {
        min: -6,
        range: 141,
        animateTo: 30,
      }
      break
    case 'sculpture-3':
      minMax = {
        min: -81,
        range: 104,
        animateTo: -48,
      }
      break
    case 'sculpture-4':
      minMax = undefined
      break
    case 'sculpture-5':
      minMax = {
        min: -57,
        range: 101,
        animateTo: 2,
      }
      break
    default:
      minMax = undefined
      break
  }
  return minMax
}
