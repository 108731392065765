export const arrayIsSame: <T extends unknown, P extends unknown>(
  compareA: Array<T>,
  compareB: Array<P>,
) => boolean = (compareA, compareB) => {
  if (compareA.length !== compareB.length) {
    return false
  }
  const leftOvers = [...compareA]
  return compareB.every((item) => {
    const foundIndex = leftOvers.findIndex((foundItem) => foundItem === item)
    if (foundIndex > -1) {
      leftOvers.splice(foundIndex, 1)
    }
    return foundIndex > -1
  })
}
