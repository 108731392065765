import StarIconSmall from '../icons/star-icon-small'
import { DetailTag } from '../../types/types'
import { useMemo } from 'react'
import ArtistIcon from '../icons/artist-icon'
import ArtIcon from '../icons/art-icon'

export const DetailPill = ({ tag }: { tag: DetailTag }) => {
  const renderIcon = useMemo(() => {
    if (tag.tagType.name === 'artists') {
      return <ArtistIcon className="w-4 h-4 text-typoContrast-900" />
    }
    if (tag.tagType.name === 'art') {
      return <ArtIcon className="w-4 h-4 text-typoContrast-900" />
    }
    if (tag.tagType.name === 'tip') {
      return <StarIconSmall className="w-4 h-4 text-typoContrast-900" />
    }
  }, [tag])

  return (
    <div
      className="bg-primary-200 p-2 rounded-8 flex items-center pointer-events-auto relative border-2 border-primary-400 text-typoContrast-900 text-xs h-8"
      style={{ lineHeight: '1' }}
    >
      <div className="absolute top-0 left-0 w-full h-full" />
      {renderIcon}
      <div className="px-1 text-sm">{tag.name}</div>
    </div>
  )
}
