import { DetailedHTMLProps, HTMLAttributes, useEffect, useRef } from 'react'
import {
  CARD_HEIGHT,
  CARD_WIDTH_DESKTOP,
  CONNECTION_CIRCLE_RADIUS,
} from '../../lib/static-values'
import { Card } from '../../types/types'
import { useSpring } from 'react-spring'
import { getColorSection } from '../../lib/get-color-section'
import { centerCard } from '../../lib/center-card'
import {
  getWindowSize,
  useWindowSizeStore,
} from '../../hooks/store/use-window-size'
import { getHasTouch, useHasTouchStore } from '../../hooks/store/use-has-touch'
import {
  getEndPoints,
  useEndPointsStore,
} from '../../hooks/store/use-end-points'
import {
  getActiveCard,
  useActiveCardStore,
} from '../../hooks/store/use-active-card'
import { getOffset, useOffsetStore } from '../../hooks/store/use-offset'
import {
  getHoveredEndPoints,
  useHoveredEndPointsStore,
} from '../../hooks/store/use-hovered-end-points'
import { getHover, useHoverStore } from '../../hooks/store/use-hover'
import shallow from 'zustand/shallow'

const useConnectionLine = (
  width: number,
  height: number,
  hasTouch: boolean,
  pathEnd: { x: number; y: number },
  activeCard: Card | null,
  offset: number,
) => {
  const cardCenter = activeCard ? centerCard(activeCard, hasTouch) : 0

  const startPoint = (activeCard?.offset || 0) + cardCenter + offset

  const startPoints = {
    x: hasTouch ? width / 2 + startPoint : width - CARD_WIDTH_DESKTOP,
    y: hasTouch ? height - CARD_HEIGHT : height / 2 + startPoint,
  }

  const anchorPointX =
    pathEnd.x < startPoints.x
      ? (startPoints.x + pathEnd.x) / 2 - startPoints.x
      : -50

  const anchorPoint1 = {
    x: hasTouch ? startPoints.x : startPoints.x + anchorPointX,
    y: hasTouch ? (startPoints.y + pathEnd.y) / 2 : startPoints.y,
  }

  const anchorPoint2 = {
    x: hasTouch ? pathEnd.x : startPoints.x + anchorPointX,
    y: hasTouch ? (startPoints.y + pathEnd.y) / 2 : pathEnd.y,
  }

  const distanceToCenter =
    Math.abs(
      (hasTouch ? width : height) / 2 -
        (hasTouch ? width : height) / 2 +
        startPoint,
    ) /
    (width / 2)

  const path = `M${startPoints.x} ${startPoints.y} C ${anchorPoint1.x} ${anchorPoint1.y} ${anchorPoint2.x} ${anchorPoint2.y} ${pathEnd.x} ${pathEnd.y}`

  return {
    path,
    distanceToCenter,
  }
}

const useHoveredConnectionLine = (
  width: number,
  height: number,
  hasTouch: boolean,
  pathEnd: { x: number; y: number },
  activeCard: Card | null,
  offset: number,
) => {
  const cardCenter = activeCard ? centerCard(activeCard, hasTouch) : 0

  const startPoint = (activeCard?.offset || 0) + cardCenter + offset

  const startPoints = {
    x: hasTouch ? width / 2 + startPoint : width - CARD_WIDTH_DESKTOP,
    y: hasTouch ? height - CARD_HEIGHT : height / 2 + startPoint,
  }

  const anchorPointX =
    pathEnd.x < startPoints.x
      ? (startPoints.x + pathEnd.x) / 2 - startPoints.x
      : -50

  const anchorPoint1 = {
    x: hasTouch ? startPoints.x : startPoints.x + anchorPointX,
    y: hasTouch ? (startPoints.y + pathEnd.y) / 2 : startPoints.y,
  }

  const anchorPoint2 = {
    x: hasTouch ? pathEnd.x : startPoints.x + anchorPointX,
    y: hasTouch ? (startPoints.y + pathEnd.y) / 2 : pathEnd.y,
  }

  const hoveredPath = `M${startPoints.x} ${startPoints.y} C ${anchorPoint1.x} ${anchorPoint1.y} ${anchorPoint2.x} ${anchorPoint2.y} ${pathEnd.x} ${pathEnd.y}`
  return {
    hoveredPath,
  }
}

export const ConnectionLine = ({
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { width, height } = useWindowSizeStore(getWindowSize)
  const hasTouch = useHasTouchStore(getHasTouch)
  const endPoints = useEndPointsStore(getEndPoints)
  const activeCard = useActiveCardStore(getActiveCard, shallow)
  const offset = useOffsetStore(getOffset)

  const hoveredEndPoints = useHoveredEndPointsStore(getHoveredEndPoints)
  const hoveredCard = useHoverStore(getHover)

  const { path, distanceToCenter } = useConnectionLine(
    width,
    height,
    hasTouch,
    endPoints,
    activeCard,
    offset,
  )

  const { hoveredPath } = useHoveredConnectionLine(
    width,
    height,
    hasTouch,
    hoveredEndPoints,
    hoveredCard,
    offset,
  )

  const svgRef = useRef<SVGSVGElement | null>(null)
  const pathRef = useRef<SVGPathElement | null>(null)
  const circleRef = useRef<SVGCircleElement | null>(null)

  const hoveredPathRef = useRef<SVGPathElement | null>(null)
  const hoveredCircleRef = useRef<SVGCircleElement | null>(null)
  const lastHoveredCard = useRef<Card | null>(null)
  const lastHoveredEndPoints = useRef(hoveredEndPoints)
  const pathLength = useRef(0)
  const lastEndPoints = useRef(endPoints)
  const lastActiveCard = useRef(activeCard)

  const [, setSpring] = useSpring(() => ({
    from: {
      trim: 1,
    },
    to: { trim: 0 },
    config: { duration: 250 },
    onStart: () => {
      if (!hoveredPathRef.current || !hoveredCircleRef.current) {
        return
      }
    },
    onChange: ({ value: { trim } }) => {
      if (!hoveredPathRef.current || !hoveredCircleRef.current) {
        return
      }
      hoveredPathRef.current.style.opacity = '1'
      pathLength.current = hoveredPathRef.current.getTotalLength()
      hoveredCircleRef.current.style.opacity = trim <= 0.02 ? '1' : '0'
      hoveredPathRef.current.style.strokeDasharray = `${pathLength.current}`
      hoveredPathRef.current.style.strokeDashoffset = `${
        trim * pathLength.current
      }`
    },
  }))

  useEffect(() => {
    if (!svgRef.current) {
      return
    }
    svgRef.current.setAttribute('viewBox', `0 0 ${width} ${height}`)
  }, [width, height])

  useEffect(() => {
    if (!pathRef.current || !circleRef.current) {
      return
    }

    pathRef.current.setAttribute('d', path)
    circleRef.current.setAttribute('cx', `${endPoints.x}`)
    circleRef.current.setAttribute('cy', `${endPoints.y}`)

    const range = hasTouch ? 0.5 : 0.25

    const shouldHide =
      activeCard !== lastActiveCard.current &&
      endPoints === lastEndPoints.current

    lastActiveCard.current = activeCard
    lastEndPoints.current =
      activeCard?.type !== 'action_card' ? endPoints : { x: 0, y: 0 }

    const opacity =
      activeCard &&
      activeCard.type !== 'building' &&
      activeCard.type !== 'action_card' &&
      endPoints.x !== 0 &&
      endPoints.y !== 0 &&
      !shouldHide
        ? `${1 - distanceToCenter / range}`
        : '0'

    pathRef.current.style.opacity = opacity
    circleRef.current.style.opacity = opacity

    pathRef.current.style.stroke = activeCard
      ? getColorSection(activeCard)
      : 'none'
    circleRef.current.style.fill = activeCard
      ? getColorSection(activeCard)
      : 'none'
  }, [path, distanceToCenter, activeCard, width, height])

  useEffect(() => {
    if (!hoveredPathRef.current || !hoveredCircleRef.current) {
      return
    }
    lastHoveredCard.current = hoveredCard

    const shouldHide =
      (hoveredCard !== lastHoveredCard.current &&
        hoveredEndPoints === lastHoveredEndPoints.current) ||
      !hoveredCard ||
      hoveredCard.type === 'building' ||
      hoveredCard === activeCard ||
      (hoveredEndPoints.x === 0 && hoveredEndPoints.y === 0)

    lastHoveredCard.current = hoveredCard
    lastHoveredEndPoints.current = hoveredEndPoints

    hoveredPathRef.current.setAttribute('d', hoveredPath)
    hoveredCircleRef.current.setAttribute('cx', `${hoveredEndPoints.x}`)
    hoveredCircleRef.current.setAttribute('cy', `${hoveredEndPoints.y}`)

    hoveredPathRef.current.style.opacity = '0'
    hoveredCircleRef.current.style.opacity = '0'

    hoveredPathRef.current.style.display = 'none'
    hoveredCircleRef.current.style.display = 'none'

    if (shouldHide) {
      return
    }

    hoveredPathRef.current.style.display = 'block'
    hoveredCircleRef.current.style.display = 'block'

    hoveredPathRef.current.style.stroke = hoveredCard
      ? getColorSection(hoveredCard)
      : 'none'
    hoveredCircleRef.current.style.fill = hoveredCard
      ? getColorSection(hoveredCard)
      : 'none'

    setSpring.start({ reset: true })
  }, [hoveredCard, hoveredPath, width, height])

  return (
    <div
      {...props}
      className={`absolute top-0 left-0 z-10 pointer-events-none ${props.className}`}
    >
      <svg strokeWidth={2} ref={svgRef}>
        <path ref={pathRef} fill="none" />
        <circle r={CONNECTION_CIRCLE_RADIUS} ref={circleRef} />
        <path fill="none" className="stroke-current" ref={hoveredPathRef} />
        <circle
          r={CONNECTION_CIRCLE_RADIUS}
          className="fill-current"
          ref={hoveredCircleRef}
        />
      </svg>
    </div>
  )
}
