import { SVGProps } from 'react'

const BarIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 3H4L11 11V19L6.91506 20.0147C6.34239 20.157 6.44553 21 7.0356 21H16.9644C17.5545 21 17.6576 20.157 17.0849 20.0147L13 19V11L20 3Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default BarIcon
