import { Image, ResponsiveImageType } from 'react-datocms'
import { RippleEffect } from '../ripple/ripple'
import IconChevronLeft from '../icons/chevron-left'
import IconChevronRight from '../icons/chevron-right'
import { useSpring, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import { useCallback, useEffect, useRef, useState } from 'react'

export const ImageSlider = ({
  images,
}: {
  images: { responsiveImage: ResponsiveImageType }[]
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [slide, setSlide] = useState(0)
  const [spring, setSpring] = useSpring(() => ({ x: 0 }))

  const bind = useDrag(({ direction: [x], down }) => {
    if (down) {
      return
    }
    if (x > 0) {
      setSlide((state) => {
        const newState = state - 1
        if (newState >= 0) {
          return newState
        }
        return state
      })
    }
    if (x < 0) {
      setSlide((state) => {
        const newState = state + 1
        if (newState < images.length) {
          return newState
        }
        return state
      })
    }
  })

  const slideToNext = useCallback(() => {
    if (!ref.current) {
      return
    }
    setSlide((state) => {
      if (state < images.length - 1) {
        return state + 1
      }
      return state
    })
  }, [])

  const slideToPrev = useCallback(() => {
    if (!ref.current) {
      return
    }

    setSlide((state) => {
      if (state > 0) {
        return state - 1
      }
      return state
    })
  }, [])

  useEffect(() => {
    if (!ref.current) {
      return
    }
    setSpring.start({ x: ref.current.offsetWidth * slide })
  }, [slide])

  return (
    <div
      className="w-full flex items-center flex-col relative pt-6 pb-3"
      ref={ref}
      {...bind()}
    >
      <div className="w-full">
        {/*---------------------------- image ----------------------------*/}
        <div className="w-full relative pt-[100%]">
          <div className="absolute left-0 top-0 w-full h-full overflow-hidden">
            <animated.div
              className="relative w-full h-full flex items-center"
              style={{
                transform: spring.x.to((x) => `translate3d(-${x}px, 0, 0)`),
              }}
            >
              {images.length
                ? images.map(({ responsiveImage }, index) => {
                    return (
                      <figure
                        className="w-full flex-shrink-0 h-full"
                        key={`DetailPage_images_${index}`}
                      >
                        <Image
                          data={responsiveImage}
                          pictureClassName="object-contain"
                          className="h-full"
                        />
                        <figcaption className="absolute opacity-0">
                          {responsiveImage.title}
                        </figcaption>
                      </figure>
                    )
                  })
                : null}
            </animated.div>
          </div>
        </div>
        {/*---------------------------- navigation btns ----------------------------*/}
        <div
          className="absolute top-1/2 w-full flex items-center justify-between"
          style={{ transform: 'translate3d(0, -100%, 0)' }}
        >
          {slide > 0 ? (
            <button
              className="relative flex justify-center items-center w-13 h-13 rounded-full cursor-pointer ml-4.5 overflow-hidden"
              onClick={slideToPrev}
            >
              <RippleEffect
                className="opacity-30 left-0 top-0 w-full h-full bg-bg-800"
                style={{ position: 'absolute' }}
              />
              <IconChevronLeft className="opacity-100" />
            </button>
          ) : (
            <div />
          )}
          {slide < images.length - 1 && (
            <button
              className="relative flex justify-center items-center w-13 h-13 rounded-full cursor-pointer mr-4.5 overflow-hidden"
              onClick={slideToNext}
            >
              <RippleEffect
                className="opacity-30 left-0 top-0 w-full h-full bg-bg-800"
                style={{ position: 'absolute' }}
              />
              <IconChevronRight className="opacity-100" />
            </button>
          )}
        </div>
      </div>
      {/*---------------------------- pagination ----------------------------*/}
      <div className="h-[36px] mb-2 flex justify-around items-center">
        {images.length
          ? images.map((_, index) => {
              return (
                <div
                  className={`w-[8px] h-[8px] ml-6 cursor-pointer border border-typo-100 rounded ${
                    index === slide ? 'bg-mono-100' : 0
                  }`}
                  key={`DetailPage_pagination_${index}`}
                  onClick={() => {
                    setSlide(index)
                  }}
                />
              )
            })
          : null}
      </div>
      <div className="w-full overflow-hidden">
        <animated.div
          className="flex"
          style={{
            transform: spring.x.to((x) => `translate3d(-${x}px, 0, 0)`),
          }}
        >
          {images.length
            ? images.map(({ responsiveImage }, index) => {
                return responsiveImage.title ? (
                  <div
                    className="w-full text-xs pb-3 px-4.5 flex-shrink-0 text-typo-300"
                    key={`DetailPage_alt_${index}`}
                  >
                    {responsiveImage.title}
                  </div>
                ) : null
              })
            : null}
        </animated.div>
      </div>
    </div>
  )
}
