import {
  CARD_BUILDING_HEIGHT,
  CARD_BUILDING_WIDTH_DESKTOP,
} from './static-values'
import { OrthographicCamera, PerspectiveCamera } from 'three'

export const calcYOffset = (
  offset: { touch: number; desktop: number },
  hasTouch: boolean,
  height: number,
) => {
  const calcCardHeight = CARD_BUILDING_HEIGHT / height

  const { touch, desktop } = offset

  return -(hasTouch ? touch * calcCardHeight : desktop)
}

export const calcXOffset = (
  offset: { touch: number; desktop: number },
  hasTouch: boolean,
  width: number,
  camera: OrthographicCamera | PerspectiveCamera,
) => {
  const vec = camera.position.clone()
  vec.setFromMatrixColumn(camera.matrix, 0)

  const calcCardWidth = CARD_BUILDING_WIDTH_DESKTOP / width

  const { touch, desktop } = offset
  vec.multiplyScalar((hasTouch ? touch : desktop) * calcCardWidth)

  return vec
}
