import { SVGProps } from 'react'

const LinkIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.31314 7.76443L6.31314 6.76994C6.31314 6.4938 6.537 6.26994 6.81314 6.26994L17.1269 6.26994C17.403 6.26994 17.6269 6.4938 17.6269 6.76994V17.0836C17.6269 17.3598 17.403 17.5837 17.1269 17.5837L16.1324 17.5836C15.8564 17.5836 15.6326 17.3601 15.6324 17.0841L15.6257 9.68527L7.3738 17.9372C7.17854 18.1325 6.86196 18.1325 6.6667 17.9372L5.95959 17.2301C5.76433 17.0348 5.76433 16.7183 5.95959 16.523L14.2115 8.27105L6.81269 8.26443C6.53673 8.26418 6.31314 8.0404 6.31314 7.76443Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default LinkIcon
