import { SVGProps } from 'react'

const ArrowBackIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`text-typo-100 ${props.className}`}
    >
      <path
        d="M12.8497 5.05669L12.1465 4.35348C11.9513 4.15822 11.6347 4.15822 11.4394 4.35348L4.14652 11.6464C3.95126 11.8416 3.95126 12.1582 4.14652 12.3535L11.4394 19.6464C11.6347 19.8416 11.9513 19.8416 12.1465 19.6464L12.8497 18.9432C13.0449 18.748 13.045 18.4317 12.85 18.2364L7.62297 12.9999H19.293C19.5691 12.9999 19.793 12.7761 19.793 12.4999V11.4999C19.793 11.2238 19.5691 10.9999 19.293 10.9999H7.62297L12.85 5.76348C13.045 5.56816 13.0449 5.25183 12.8497 5.05669Z"
        fill="currentColor"
        {...pathProps}
      />
    </svg>
  )
}

export default ArrowBackIcon
