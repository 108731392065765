import { useNextTarget } from './use-next-target'
import ThreeStore from '../lib/store'
import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { useSpring } from '@react-spring/three'
import {
  BUILDING_TARGET,
  CF_ROTATION,
  INITIAL_ROTATION,
  MAX_DISTANCE,
  MIN_DISTANCE,
} from '../lib/static-values'
import { easeCubicInOut } from 'd3-ease'
import { MathUtils, Vector3 } from 'three'
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib/controls/OrbitControls'
import { getIsReady, useIsReadyStore } from './store/use-is-ready'
import { getActiveCard, useActiveCardStore } from './store/use-active-card'
import { getHasTouch, useHasTouchStore } from './store/use-has-touch'
import { useDistanceStore } from './store/use-distance'
import { rotateToSculpture } from '../lib/rotate-to-sculpture'
import { getActiveRoom, useActiveRoomStore } from './store/use-active-room'
import { Card } from '../types/types'
import { MOSER_BUILDING_ID, MOSER_CARD_ID } from '../lib/constants'

const animationDuration = 500
const target = {
  current: new Vector3(),
  next: new Vector3(),
}
const distance = {
  current: new Vector3(),
  last: new Vector3(),
  next: new Vector3(),
}

export const useAnimate = (
  controller: MutableRefObject<OrbitControlsImpl | null>,
) => {
  const isReady = useIsReadyStore(getIsReady)
  const activeCard = useActiveCardStore(getActiveCard)
  const activeRoom = useActiveRoomStore(getActiveRoom)
  const hasTouch = useHasTouchStore(getHasTouch)
  const setDistance = useDistanceStore(
    useCallback((state) => state.setDistance, []),
  )

  const cameraTarget = useNextTarget()
  const currentCard = useRef(activeCard)
  const lastCard = useRef<Card | null>(null)
  const isInitial = useRef(true)
  const currentOrbit = useRef(0)
  const sculptureMinMaxRot = useRef(rotateToSculpture(activeRoom))
  const shouldRotateToSculpture = useRef(false)

  const springValue = useCallback(() => {
    return {
      reset: true,
      cancel: !isReady,
      from: { animation: 0 },
      to: { animation: 1 },
      immediate: isInitial.current,
      config: {
        duration: isInitial.current ? undefined : animationDuration,
        easing: (t: number) => easeCubicInOut(t),
      },
    }
  }, [isReady])

  const [, setSpring] = useSpring(() => {
    return {
      ...springValue(),
      onStart: () => {
        if (!controller.current) {
          return
        }
        target.current = controller.current.target
        target.next = cameraTarget || BUILDING_TARGET
        distance.last.setX(controller.current.maxDistance)
        distance.next.setX(controller.current.maxDistance)
        currentOrbit.current = MathUtils.radToDeg(
          controller.current.maxAzimuthAngle,
        )
        if (
          (lastCard.current?.type === 'building' ||
            (lastCard.current?.building === 'Aussenraum' &&
              currentCard.current?.building !== 'Aussenraum')) &&
          (currentCard.current?.type === 'card' ||
            currentCard.current?.type === 'action_card')
        ) {
          distance.next.setX(MIN_DISTANCE)
        }
        if (currentCard.current && currentCard.current.type === 'building') {
          distance.next.setX(200)
        }
        if (
          !hasTouch &&
          currentCard.current?.building !== 'Aussenraum' &&
          (currentCard.current?.type === 'card' ||
            currentCard.current?.type === 'action_card')
        ) {
          distance.next.setX(MIN_DISTANCE)
        }
        if (
          !currentCard.current ||
          (currentCard.current.building === 'Aussenraum' &&
            lastCard.current?.building !== 'Aussenraum')
        ) {
          distance.next.setX(MAX_DISTANCE)
        }

        if (sculptureMinMaxRot.current === undefined) {
          return
        }

        const isMoreThanHalf = Math.abs(currentOrbit.current) > 180
        const isNegative = currentOrbit.current < 0

        const normalizeOrbit = isMoreThanHalf
          ? 180 - Math.abs(currentOrbit.current) + 180
          : currentOrbit.current
        const orbit = isMoreThanHalf
          ? normalizeOrbit * (isNegative ? 1 : -1)
          : normalizeOrbit

        const viewRange =
          sculptureMinMaxRot.current.min + sculptureMinMaxRot.current.range
        const viewMin = sculptureMinMaxRot.current.min

        if (orbit > viewRange || orbit < viewMin) {
          shouldRotateToSculpture.current = true
        }
      },
      onChange: ({ value }) => {
        if (
          currentCard.current?.floor !== 10 &&
          (currentCard.current?.building !== lastCard.current?.building ||
            (currentCard.current?.type === 'building' &&
              currentCard.current?.title !== lastCard.current?.title))
        ) {
          const isInitialRotation =
            !currentCard.current ||
            currentCard.current.buildingId === MOSER_BUILDING_ID ||
            (currentCard.current.building === null &&
              currentCard.current.id === MOSER_CARD_ID)
          if (isInitialRotation) {
            const newRotation =
              (currentOrbit.current - INITIAL_ROTATION) * value.animation
            ThreeStore.set({
              orbit: currentOrbit.current - newRotation,
              isRotating: true,
            })
          } else {
            const newRotation =
              (currentOrbit.current - CF_ROTATION) * value.animation
            ThreeStore.set({
              orbit: currentOrbit.current - newRotation,
              isRotating: true,
            })
          }
        }
        if (!controller.current) {
          return
        }
        controller.current.target.lerpVectors(
          target.current,
          target.next,
          value.animation,
        )

        distance.current.lerpVectors(
          distance.last,
          distance.next,
          value.animation,
        )

        if (
          shouldRotateToSculpture.current &&
          sculptureMinMaxRot.current !== undefined
        ) {
          const newRotation =
            (currentOrbit.current - sculptureMinMaxRot.current.animateTo) *
            value.animation
          ThreeStore.set({
            orbit: currentOrbit.current - newRotation,
            isRotating: true,
          })
        }

        controller.current.maxDistance = distance.current.x
        controller.current.minDistance = distance.current.x
      },
      onRest: () => {
        if (!controller.current) {
          return
        }
        lastCard.current = currentCard.current
        target.next = controller.current.target

        shouldRotateToSculpture.current = false

        setDistance(distance.current.x)
        ThreeStore.set({ distance: distance.current.x, isRotating: false })
      },
    }
  })

  useEffect(() => {
    const { isScrolling } = ThreeStore
    if (isScrolling) {
      return
    }
    currentCard.current = activeCard
    sculptureMinMaxRot.current = rotateToSculpture(activeRoom)

    setSpring.start(springValue())
    isInitial.current = false
  }, [activeCard, isReady])
}
