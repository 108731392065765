import { HTMLProps, useCallback, useMemo } from 'react'
import { Image } from 'react-datocms'
import colors from '../../data/colors'
import {
  CARD_BUILDING_HEIGHT,
  CARD_BUILDING_WIDTH_DESKTOP,
  CARD_WIDTH,
} from '../../lib/static-values'
import ArrowLeftLargeIcon from '../icons/arrow-left-large-icon'
import ArrowUpLargeIcon from '../icons/arrow-up-large-icon'
import { RippleEffect } from '../ripple/ripple'
import { Card } from '../../types/types'
import Link from 'next/link'
import { useActiveCardStore } from '../../hooks/store/use-active-card'
import {
  getFavoritesString,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'
import {
  getTaggedString,
  useTaggedCardIdsStore,
} from '../../hooks/store/use-tagged-card-ids'
import {
  getActiveTagListString,
  useActiveTagListStore,
} from '../../hooks/store/use-active-tag-list'
import {
  getActiveTagString,
  useActiveTagStore,
} from '../../hooks/store/use-active-tag'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'

const useCardLink = (cardId: string) => {
  const favoritesString = useFavoritesStore(getFavoritesString)
  const taggedString = useTaggedCardIdsStore(getTaggedString)
  const detailPage = useActiveCardStore(
    useCallback(
      (state) => (state.activeCard?.id === cardId ? cardId : '-'),
      [],
    ),
  )
  const activeTagListId = useActiveTagListStore(getActiveTagListString)
  const activeTagId = useActiveTagStore(getActiveTagString)
  const orbit = useOrbitStore(getOrbit)

  return useMemo(() => {
    return `${cardId}/${orbit}/${detailPage}/false/0/${favoritesString}/${activeTagListId}/${activeTagId}/${taggedString}`
  }, [
    orbit,
    detailPage,
    favoritesString,
    activeTagListId,
    activeTagId,
    taggedString,
  ])
}

export const MoserCard = ({
  hasTouch,
  card,
  cardRef,
  ...props
}: HTMLProps<HTMLDivElement> & {
  hasTouch: boolean
  cardRef: (card: HTMLDivElement | null) => void
  card: Card
}) => {
  const { title, subtitle, img, id } = card
  const link = useCardLink(id)
  return (
    <Link href={link} passHref shallow>
      <a>
        <RippleEffect
          {...props}
          className={`text-typo-100 relative z-50 h-full desktop:h-auto flex text-right ${props.className}`}
          style={{
            width: `${hasTouch ? CARD_WIDTH : CARD_BUILDING_WIDTH_DESKTOP}px`,
            height: `${CARD_BUILDING_HEIGHT}px`,
            ...props.style,
          }}
          ref={cardRef}
          data-id={id}
        >
          <div className="absolute top-0 desktop:top-px left-px desktop:left-0 right-px desktop:right-0 bottom-0 desktop:bottom-px bg-bg-800" />
          {img && img.responsiveImage && (
            <div className="absolute top-0 desktop:top-px bottom-0 desktop:bottom-px left-px desktop:left-0 right-px desktop:right-0 overflow-hidden flex justify-center items-center">
              <Image
                data={img.responsiveImage}
                className="z-0 pointer-events-none absolute w-full h-auto"
              />
              <div
                className={`absolute left-0 top-0 w-full h-full z-10 mix-blend-multiply bg-gradient-to-t from-transparent to-dark opacity-40`}
                style={{
                  //add correct alpha to hex
                  background: `linear-gradient(180deg, ${colors.bg[900]}, ${
                    colors.bg[900] + '9e'
                  } 57.29%, ${colors.bg[900] + '00'} 100%)`,
                }}
              />
            </div>
          )}
          <div className="z-10 desktop:flex w-full">
            <div className="absolute right-3 bottom-2 desktop:bottom-auto desktop:top-3 cursor-pointer">
              {hasTouch ? <ArrowLeftLargeIcon /> : <ArrowUpLargeIcon />}
            </div>
            <div className="px-4 pb-0 text-right desktop:text-left font-black text-lg leading-none desktop:text-3xl desktop:px-3 py-3">
              {title}
              <br />
              {subtitle}
            </div>
          </div>
        </RippleEffect>
      </a>
    </Link>
  )
}
