import create from 'zustand'

export type HoveredEndPointsStore = {
  hoveredEndPoints: { x: number; y: number }
  setHoveredEndPoints: (payload: { x: number; y: number }) => void
}
export const useHoveredEndPointsStore = create<HoveredEndPointsStore>(
  (set) => ({
    hoveredEndPoints: { x: 0, y: 0 },
    setHoveredEndPoints: (payload) =>
      set(() => ({ hoveredEndPoints: payload })),
  }),
)

export const getHoveredEndPoints = (state: HoveredEndPointsStore) =>
  state.hoveredEndPoints
