import { TeaserShopType } from '../../types/types'
import { Image } from 'react-datocms'
import Link from 'next/link'
import LinkIcon from '../icons/link-icon'
import { RippleEffect } from '../ripple/ripple'

export const TeaserShop = ({ teaser }: { teaser: TeaserShopType }) => {
  const refererUrl = window.location.href

  return (
    <div className="w-1/2 flex-shrink-0 px-2 desktop:py-2">
      <Link
        href={`${teaser.url}${
          teaser.withReferer ? `?referrer=${refererUrl}` : ''
        }`}
        passHref
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col h-full min-w-[169px]"
        >
          <RippleEffect className="bg-bg-800 h-full">
            <Image
              data={teaser.image.responsiveImage}
              pictureClassName="w-full h-[137px] desktop:h-[200px] object-cover"
            />
            <div className="p-3">
              <div className="flex items-center justify-between w-full text-base pt-1 leading-5">
                {teaser.text}
              </div>
              <div className="flex items-end justify-between w-full text-xs pt-1">
                <span className="text-typo-300">CHF {teaser.price}</span>
                <LinkIcon />
              </div>
            </div>
          </RippleEffect>
        </a>
      </Link>
    </div>
  )
}
