import { Vector3 } from 'three'

export const CARD_WIDTH = 220
export const CARD_WIDTH_DESKTOP = 330
export const CARD_BUILDING_WIDTH_DESKTOP = 382
export const CARD_HEIGHT = 140
export const GAP = 2
export const CARD_BUILDING_HEIGHT = 196
export const WIDTH_GAP = CARD_WIDTH + GAP
export const BUILDING_TARGET: Vector3 = new Vector3(64, -50, -169)
export const INITIAL_ROTATION = -30
export const CF_ROTATION = -150
export const ANIMATION_DELAY = 500
export const MOSER_COORDS: Vector3 = new Vector3(81, 0, -240)
export const CHIPPERFIELD_COORDS: Vector3 = new Vector3(57, 0, -99)
export const CONNECTION_CIRCLE_RADIUS = 10
export const SCREEN_MARGIN = 8
export const MAX_DISTANCE = 400
export const MIN_DISTANCE = 100
export const ANIMATION_DURATION = 250
