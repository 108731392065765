import { Link } from '../../types/types'
import create from 'zustand'

export type VisitorInfosStore = {
  visitorInfos: Link[]
  setVisitorInfos: (payload: Link[]) => void
}
export const useVisitorInfosStore = create<VisitorInfosStore>((set) => ({
  visitorInfos: [],
  setVisitorInfos: (payload) => set(() => ({ visitorInfos: payload })),
}))

export const getVisitorInfos = (state: VisitorInfosStore) => state.visitorInfos
