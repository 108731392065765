import { SVGProps } from 'react'

const PlusIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 13V22H13V13H22V11H13V2H11V11H2V13H11Z" fill="#1C1C1E" />
    </svg>
  )
}

export default PlusIcon
