import { Card, Room } from '../types/types'

export const updateFavoritesTags = (
  rooms: Room[],
  cards: Card[],
  cardIds: string[],
) => {
  const favoriteRooms: string[] = []

  cardIds.forEach((cardId) => {
    const foundCard = cards.find((card) => card.id === cardId)
    if (foundCard) {
      const foundMainRoom = rooms.find(
        (room) => foundCard.roomTarget === room.id,
      )
      if (foundMainRoom) {
        favoriteRooms.push(foundMainRoom.id)
        return
      }
      const foundRoom = rooms.find(
        (room) =>
          foundCard.rooms &&
          foundCard.rooms.find((cardRoom) => cardRoom === room.id),
      )
      if (foundRoom) {
        favoriteRooms.push(foundRoom.id)
        return
      }
    }
  })

  return [...favoriteRooms]
}
