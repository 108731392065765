import { Color, Mesh, MeshPhongMaterial } from 'three'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSpring } from '@react-spring/three'
import { animated } from '@react-spring/web'
import { Html } from '@react-three/drei'
import { Floor, Mutation, Room } from '../../types/types'
import {
  defaultMaterial,
  disabledColor,
  disabledColorSecondary,
} from './render-map'
import {
  ANIMATION_DELAY,
  CONNECTION_CIRCLE_RADIUS,
} from '../../lib/static-values'
import { renderIcon } from '../icons/render-icons'
import colors from '../../data/colors'
import PinIcon from '../icons/pin-icon'
import LikeFilledIcon from '../icons/like-filled-icon'
import { renderSVG } from '../../lib/render-svg'
import StarIcon from '../icons/star-icon'
import {
  ActiveBuildingStore,
  getActiveBuilding,
  getActiveFloor,
  useActiveBuildingStore,
  useActiveFloorStore,
} from '../../hooks/store/use-active-card'
import {
  FavoriteRoomsStore,
  useFavoriteRoomsStore,
} from '../../hooks/store/use-favorite-rooms'
import {
  TaggedRoomsStore,
  useTaggedRoomsStore,
} from '../../hooks/store/use-tagged-rooms'
import { getIsReady, useIsReadyStore } from '../../hooks/store/use-is-ready'
import {
  ActiveRoomStore,
  useActiveRoomStore,
} from '../../hooks/store/use-active-room'
import {
  useZoomLevelStore,
  ZoomLevelStore,
} from '../../hooks/store/use-zoom-level'

const useDelay: (
  room: Room | undefined,
  hasUpperStairs: boolean,
  activeFloor: Floor | null,
  activeBuilding: string | null,
) => number = (room, hasUpperStairs, activeFloor, activeBuilding) => {
  return useMemo(() => {
    if (
      activeFloor === null ||
      !room ||
      room.floor === null ||
      !activeBuilding
    ) {
      return 0
    }
    if (activeFloor < Math.floor(room.floor)) {
      return hasUpperStairs ? ANIMATION_DELAY : 0
    }
    return ANIMATION_DELAY
  }, [activeFloor, activeBuilding])
}

const useFavorite = (
  roomId: string | undefined,
  floor: Floor | null | undefined,
  building: string | null | undefined,
  activeFloor: Floor | null,
  activeBuilding: string | null,
) => {
  const getFavorite = useCallback(
    (state: FavoriteRoomsStore) => {
      if (
        !roomId ||
        floor === null ||
        floor === undefined ||
        !building ||
        activeFloor === null
      ) {
        return false
      }
      return (
        !!state.favoriteRooms.find((id) => id === roomId) &&
        Math.floor(activeFloor) === Math.floor(floor) &&
        building === activeBuilding
      )
    },
    [activeFloor, activeBuilding],
  )
  return useFavoriteRoomsStore(getFavorite)
}

const useTaggedRoom = (roomId: string | undefined) => {
  const getTaggedRoom = useCallback((state: TaggedRoomsStore) => {
    return !!state.taggedRooms.find((id) => id === roomId)
  }, [])
  return useTaggedRoomsStore(getTaggedRoom)
}

const useActiveFloor = (
  room: Room | undefined,
  activeFloor: Floor | null,
  activeBuilding: string | null,
) => {
  return useMemo(() => {
    if (activeFloor === null || !room || room.floor === null) {
      return false
    }
    return (
      Math.floor(activeFloor) === Math.floor(room.floor) &&
      activeBuilding === room.building
    )
  }, [activeFloor, activeBuilding])
}

const useLowerFloor = (
  room: Room | undefined,
  activeFloor: Floor | null,
  activeBuilding: string | null,
) => {
  return useMemo(() => {
    if (activeFloor === null || !room || room.floor === null) {
      return false
    }
    return (
      Math.floor(activeFloor) > Math.floor(room.floor) &&
      activeBuilding === room.building
    )
  }, [activeFloor, activeBuilding])
}

const useActiveBuilding = (room: Room | undefined) => {
  const getCurrentBuilding = useCallback((state: ActiveBuildingStore) => {
    if (!room) {
      return false
    }
    return state.activeBuilding === room.building
  }, [])
  return useActiveBuildingStore(getCurrentBuilding)
}

const useFocusRoom = (room: Room | undefined) => {
  const getActiveRoom = useCallback((state: ActiveRoomStore) => {
    if (!room || !state.activeRoom) {
      return false
    }
    return state.activeRoom.id === room.id
  }, [])
  return useActiveRoomStore(getActiveRoom)
}

const useShowIcons = () => {
  const showIcons = useCallback((state: ZoomLevelStore) => {
    return state.zoomLevel >= 4
  }, [])
  return useZoomLevelStore(showIcons)
}

export const RenderMesh = ({
  material,
  mesh,
  room,
  hasUpperStairs,
}: {
  mesh: Mesh
  material: MeshPhongMaterial
  hasUpperStairs: boolean
  room?: Room
}) => {
  const ref = useRef<Mesh>()
  const iconRef = useRef<Mesh[]>([])
  const color = useRef<Color>(new Color(material.color))
  const matRef = useRef<MeshPhongMaterial>(material)
  const matStairRef = useRef<MeshPhongMaterial>(defaultMaterial.clone())
  const stairColor = useRef<Color>(new Color(matStairRef.current.color))
  const isInitial = useRef(true)
  const shouldUpdate = useRef(false)
  const wasOnFloor = useRef(false)

  const activeFloor = useActiveFloorStore(getActiveFloor)
  const activeBuilding = useActiveBuildingStore(getActiveBuilding)

  const isReady = useIsReadyStore(getIsReady)

  const delay = useDelay(room, hasUpperStairs, activeFloor, activeBuilding)

  const isFavorite = useFavorite(
    room?.id,
    room?.floor,
    room?.building,
    activeFloor,
    activeBuilding,
  )
  const isTagged = useTaggedRoom(room?.id)
  const isActiveFloor = useActiveFloor(room, activeFloor, activeBuilding)
  const isLowerFloor = useLowerFloor(room, activeFloor, activeBuilding)
  const currentBuilding = useActiveBuilding(room)
  const isFocused = useFocusRoom(room)
  const shouldShowIcons = useShowIcons()

  useEffect(() => {
    matStairRef.current.color = new Color(colors.section9[100])
  }, [])

  const mutation = useRef<
    Pick<Mutation, 'wasInBuilding' | 'isInBuilding' | 'set'>
  >({
    wasInBuilding: currentBuilding,
    isInBuilding: currentBuilding,
    set: (state) => {
      mutation.current = { ...mutation.current, ...state }
    },
  })

  const childMeshes = useMemo(() => {
    return mesh.children.filter((m) => !!m.name.match(/-mesh/))
  }, [mesh])

  const childStairs = useMemo(() => {
    return mesh.children.filter((m) => !!m.name.match(/-stair/))
  }, [mesh])

  const { roomIcons, meshIcons } = useMemo(() => {
    if (!room || !room.icons.length) {
      return { roomIcons: [], meshIcons: [] }
    }

    const icons = [...room.icons]

    const meshes = mesh.children.filter((icon) => {
      const foundRoomIcon = icons.findIndex(({ icon: item, index }) => {
        const withIndex = icon.name.match(/-\d*$/)

        const cleanIndex = withIndex ? withIndex[0].replace('-', '') : 0

        const foundIndex =
          withIndex && index ? index.toString() === cleanIndex : true

        return (
          foundIndex && icon && !!icon.name.match(new RegExp(item as string))
        )
      })
      foundRoomIcon >= 0 && icons.splice(foundRoomIcon, 1)

      return foundRoomIcon >= 0
    })

    return {
      roomIcons: icons,
      meshIcons: meshes,
    }
  }, [mesh, room])

  const focusSpring = useSpring({
    circleWidth: isFocused
      ? CONNECTION_CIRCLE_RADIUS * 1.5
      : (isTagged || isFavorite) && room && roomIcons.length
      ? 4
      : 0,
  })

  const springValues = useCallback(() => {
    const { isInBuilding, wasInBuilding } = mutation.current
    return {
      isReady,
      visible: isActiveFloor || isLowerFloor,
      wasOnFloor: wasOnFloor.current,
      onFloor: isActiveFloor,
      opacity: room && isInBuilding && (isLowerFloor || isActiveFloor) ? 1 : 0,
      color: isLowerFloor
        ? room && room.floor && room.floor < 0
          ? disabledColorSecondary.toArray()
          : disabledColor.toArray()
        : color.current.toArray(),
      stairColor: isLowerFloor
        ? room && room.floor && room.floor < 0
          ? disabledColorSecondary.toArray()
          : disabledColor.toArray()
        : stairColor.current.toArray(),
      delay,
      immediate: isInitial.current,
      config: {
        duration: wasInBuilding === isInBuilding ? undefined : 0,
      },
    }
  }, [isActiveFloor, isLowerFloor, room, delay])

  const [, setSpring] = useSpring(() => ({
    ...springValues(),
    firstCall: true,
    onStart: ({ value }) => {
      if (!ref.current) {
        return
      }
      ref.current.visible = value.visible || value.wasOnFloor
    },
    onChange: ({ value }) => {
      if (!ref.current) {
        return
      }
      const geo = ref.current
      const mat = matRef.current as MeshPhongMaterial
      mat.color = new Color(value.color[0], value.color[1], value.color[2])
      mat.opacity = value.opacity
      mat.transparent = value.opacity !== 1
      geo.castShadow = value.opacity !== 0

      if (geo.children.length) {
        geo.children.forEach((child) => {
          if (!(child as Mesh).geometry) {
            return
          }
          child.castShadow = value.opacity === 1
          if (!child.name.match(/-stair/)) {
            return
          }
          const matChild = matStairRef.current as MeshPhongMaterial
          matChild.opacity = value.opacity
          matChild.transparent = value.opacity !== 1
          matChild.color = new Color(
            value.stairColor[0],
            value.stairColor[1],
            value.stairColor[2],
          )
        })
      }
    },
    onRest: ({ value }) => {
      mutation.current.wasInBuilding = mutation.current.isInBuilding
      wasOnFloor.current = isActiveFloor
      if (!ref.current) {
        return
      }
      ref.current.visible = value.visible
    },
  }))

  useEffect(() => {
    if (!isReady) {
      return
    }

    if (!ref.current || !room) {
      return
    }

    if (isInitial.current) {
      mutation.current.set({
        isInBuilding: currentBuilding,
      })

      if (iconRef.current && iconRef.current.length) {
        iconRef.current.forEach((iconMesh) => {
          if (!iconMesh || !iconMesh.name.match(/entrance/)) {
            return
          }
          const mayHasIcon =
            room.icons.length &&
            room.icons.find(
              ({ icon }) => !!iconMesh.name.match(new RegExp(`${icon}`)),
            )

          if (!mayHasIcon) {
            return
          }

          renderSVG(iconMesh, '/icons/ic_24_entrance.svg', 0.1, room)
        })
      }
    }

    mutation.current.set({
      isInBuilding: currentBuilding,
    })

    ref.current.layers.mask = isActiveFloor ? 1 : 2

    setSpring.start({ ...springValues(), firstCall: false, reset: true })

    shouldUpdate.current = isActiveFloor
    isInitial.current = false
  }, [isActiveFloor, isLowerFloor, isReady])

  return (
    <mesh
      ref={ref}
      {...mesh}
      castShadow
      receiveShadow
      geometry={mesh.geometry}
      material={matRef.current}
      key={mesh.uuid}
      renderOrder={1}
    >
      {childStairs.length &&
        childStairs.map((child) => {
          return (
            <mesh
              {...child}
              material={matStairRef.current}
              receiveShadow
              castShadow
              key={child.uuid}
              renderOrder={1}
            />
          )
        })}
      {childMeshes.length &&
        childMeshes.map((child) => {
          return (
            <mesh
              {...child}
              material={matRef.current}
              receiveShadow
              castShadow
              key={child.uuid}
              renderOrder={1}
            />
          )
        })}
      {room && shouldShowIcons && isActiveFloor
        ? meshIcons.length &&
          meshIcons.map((icon) => {
            return (
              <mesh
                {...icon}
                key={icon.uuid}
                ref={(iconMeshRef) => {
                  if (!iconMeshRef) {
                    return
                  }
                  const uuid = (iconMeshRef as Mesh).uuid
                  const hasMesh = iconRef.current.find(
                    (item) => item.uuid === uuid,
                  )
                  iconMeshRef &&
                    !hasMesh &&
                    iconRef.current.push(iconMeshRef as Mesh)
                }}
              >
                {!icon.name.match(/entrance/) && (
                  <Html
                    center
                    zIndexRange={[15, 40]}
                    className="pointer-events-none"
                  >
                    {renderIcon({
                      name: icon.name,
                      key: `${mesh.uuid}_${icon.uuid}`,
                    })}
                  </Html>
                )}
              </mesh>
            )
          })
        : null}
      {room && shouldShowIcons && isActiveFloor
        ? (roomIcons.length || isFavorite || isTagged) && (
            <Html
              zIndexRange={[18, 40]}
              key={`icon_${mesh.uuid}`}
              className="pointer-events-none flex items-center"
              style={{
                height: CONNECTION_CIRCLE_RADIUS * 2,
              }}
              center
            >
              {roomIcons.length ? (
                <animated.div
                  className={`${
                    isFocused || isTagged || isFavorite
                      ? 'absolute'
                      : 'relative'
                  } right-0 flex gap-2 h-0 items-center`}
                  style={{
                    right: focusSpring.circleWidth.to((width) => width),
                  }}
                >
                  {room.icons.map(({ icon }) => {
                    const hasMeshIcon = meshIcons.find(({ name }) =>
                      name.match(icon || ''),
                    )
                    if (hasMeshIcon) {
                      return null
                    }
                    return renderIcon({
                      name: icon as string,
                      key: `${mesh.uuid}_${icon}`,
                      props: {
                        className: 'mx-0.5',
                      },
                    })
                  })}
                </animated.div>
              ) : null}
              <animated.div
                className={`${
                  roomIcons.length || isFocused ? 'absolute' : 'relative'
                } left-0 flex gap-2 h-0 items-center`}
                style={{
                  left: focusSpring.circleWidth.to((width) => width),
                }}
              >
                {isFavorite && (
                  <PinIcon
                    wrapperProps={{
                      className:
                        'mx-0.5 flex justify-center flex-grow flex-shrink-0',
                    }}
                    isLikePin
                  >
                    <LikeFilledIcon />
                  </PinIcon>
                )}
                {isTagged && (
                  <PinIcon
                    wrapperProps={{
                      className:
                        'mx-0.5 flex justify-center flex-grow flex-shrink-0',
                    }}
                  >
                    <StarIcon className="text-typoContrast-900" />
                  </PinIcon>
                )}
              </animated.div>
            </Html>
          )
        : null}
    </mesh>
  )
}
