import create from 'zustand'

export type IsLoadingStore = {
  isLoading: boolean
  setIsLoading: (payload: boolean) => void
}
export const useIsLoadingStore = create<IsLoadingStore>((set) => ({
  isLoading: true,
  setIsLoading: (payload) => set(() => ({ isLoading: payload })),
}))

export const getIsLoading = (state: IsLoadingStore) => state.isLoading
