import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib/controls/OrbitControls'
import ThreeStore from '../lib/store'
import { MathUtils, Vector2, Vector3 } from 'three'
import {
  BUILDING_TARGET,
  CF_ROTATION,
  CHIPPERFIELD_COORDS,
  INITIAL_ROTATION,
  MAX_DISTANCE,
  MOSER_COORDS,
} from '../lib/static-values'
import { calcYOffset } from '../lib/calc-offset'
import { getHasTouch, useHasTouchStore } from './store/use-has-touch'
import { getcfCard, useCfCardStore } from './store/use-cf-card'
import { OffsetStore, useOffsetStore } from './store/use-offset'
import { getWindowSize, useWindowSizeStore } from './store/use-window-size'
import { getDistance, useDistanceStore } from './store/use-distance'
import { useCompassRotationStore } from './store/use-compass-rotation'

const setScrollDistance = (
  currentScroll: number,
  nextScroll: number,
  scrollRange: number,
) => {
  const scrollOffset =
    (Math.abs(currentScroll) - Math.abs(nextScroll)) / scrollRange

  if (scrollOffset < -1) {
    return -1
  }
  if (scrollOffset > 1) {
    return 1
  }
  if (scrollOffset < 0 && currentScroll < -1) {
    return 0
  }
  if (scrollOffset < 0 && currentScroll > 1) {
    return 0
  }
  return scrollOffset
}

const scrollDirection: { current: null | number; last: null | number } = {
  current: null,
  last: null,
}

const target = {
  current: new Vector3(),
  next: new Vector3(),
}
const targetDistance = {
  current: new Vector2(),
  next: new Vector2(),
}

const rotation = {
  current: new Vector2(),
  next: new Vector2(),
}

const useOffset = () => {
  const hasTouch = useHasTouchStore(getHasTouch)
  const cfCard = useCfCardStore(getcfCard)
  const getOffset = useCallback(
    (state: OffsetStore) => {
      const offset = state.offset
      const maxOffset = (hasTouch ? cfCard?.width : cfCard?.height) / 2
      const range = -(cfCard?.offset + offset)
      return Math.abs(range) <= maxOffset
        ? range / maxOffset
        : range < 0
        ? -1
        : 1
    },
    [hasTouch, cfCard],
  )
  return useOffsetStore(getOffset)
}

export const useScroll = (
  controller: MutableRefObject<OrbitControlsImpl | null>,
  cameraIsSet: MutableRefObject<boolean>,
) => {
  const scrollTo = useOffset()
  const currentScroll = useRef(scrollTo)
  const nextScroll = useRef(scrollTo)
  const started = useRef(true)
  const { height } = useWindowSizeStore(getWindowSize)
  const hasTouch = useHasTouchStore(getHasTouch)
  const distance = useDistanceStore(getDistance)
  const setDistance = useDistanceStore(
    useCallback((state) => state.setDistance, []),
  )
  const setCompassRotation = useCompassRotationStore(
    useCallback((state) => state.setCompassRotation, []),
  )

  useEffect(() => {
    const { isScrolling, set, orbit } = ThreeStore
    if (!controller.current || !cameraIsSet.current) {
      return
    }
    if (!isScrolling) {
      if (!started.current) {
        set({ orbit: rotation.next.x })

        started.current = true
      }
      return
    }
    if (started.current) {
      target.current.copy(controller.current.target)
      targetDistance.current.setX(distance)
      targetDistance.next.setX(distance)
      rotation.current.setX(orbit)
      rotation.next.setX(orbit)
      started.current = false
      scrollDirection.current = 0
      scrollDirection.last = 0
      return
    }
    scrollDirection.current =
      Math.abs(currentScroll.current) > Math.abs(scrollTo)
        ? -1
        : currentScroll.current === scrollTo
        ? 0
        : 1

    currentScroll.current = scrollTo

    if (scrollDirection.current !== scrollDirection.last) {
      if (scrollDirection.last === null) {
        scrollDirection.last = scrollDirection.current
      }
      target.current.copy(controller.current.target)
      nextScroll.current = scrollTo
      targetDistance.current.copy(targetDistance.next)
      rotation.current.copy(rotation.next)
    }

    const scrollRange =
      scrollDirection.current !== null
        ? Math.abs(
            (scrollDirection.current > 0 ? 1 : 0) -
              Math.abs(nextScroll.current),
          )
        : 0

    const scrollDistance = setScrollDistance(
      currentScroll.current,
      nextScroll.current,
      scrollRange,
    )

    const scrollToMoser = scrollTo <= 0

    const nextCardTarget = scrollToMoser
      ? MOSER_COORDS.clone().sub(
          new Vector3(
            0,
            calcYOffset({ touch: -50, desktop: 0 }, hasTouch, height),
            0,
          ),
        )
      : CHIPPERFIELD_COORDS.clone().sub(
          new Vector3(
            0,
            calcYOffset({ touch: -50, desktop: 0 }, hasTouch, height),
            0,
          ),
        )

    if (isNaN(scrollDistance)) {
      return
    }
    const newDistance = new Vector2(200, 0)

    if (scrollDirection.current !== null && scrollDirection.current < 0) {
      nextCardTarget
        .copy(BUILDING_TARGET)
        .sub(
          new Vector3(
            0,
            calcYOffset({ touch: 100, desktop: 30 }, hasTouch, height),
            0,
          ),
        )
      newDistance.setX(MAX_DISTANCE)
    }

    targetDistance.next.lerpVectors(
      targetDistance.current,
      newDistance,
      Math.abs(scrollDistance),
    )
    rotation.next.lerpVectors(
      rotation.current,
      new Vector2(
        scrollDirection.current > 0
          ? scrollToMoser
            ? INITIAL_ROTATION
            : CF_ROTATION
          : INITIAL_ROTATION,
        0,
      ),
      Math.abs(scrollDistance),
    )
    controller.current.maxDistance = targetDistance.next.x
    controller.current.minDistance = targetDistance.next.x
    setDistance(targetDistance.next.x)
    set({ distance: targetDistance.next.x })

    controller.current.maxAzimuthAngle = MathUtils.degToRad(rotation.next.x)
    controller.current.minAzimuthAngle = MathUtils.degToRad(rotation.next.x)

    controller.current.target.lerpVectors(
      target.current,
      nextCardTarget,
      Math.abs(scrollDistance),
    )

    set({ orbit: rotation.next.x })
    setCompassRotation(rotation.next.x)

    currentScroll.current = scrollTo

    scrollDirection.last = scrollDirection.current
  }, [scrollTo])
}
