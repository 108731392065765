import { Card } from '../../types/types'
import create from 'zustand'
import { emptyCard } from '../../lib/empty-card'

export type FirstBuildingCardsStore = {
  firstCards: [Card, Card]
  setFirstCard: (payload: [Card, Card]) => void
}
export const useFirstBuildingCards = create<FirstBuildingCardsStore>((set) => ({
  firstCards: [emptyCard, emptyCard],
  setFirstCard: (payload) => set(() => ({ firstCards: payload })),
}))

export const getFirstBuildingCards = (state: FirstBuildingCardsStore) => state.firstCards
