import create from 'zustand'

export type CfIndexStore = {
  cfIndex: number
  setCfIndex: (payload: number) => void
}
export const useCfIndexStore = create<CfIndexStore>((set) => ({
  cfIndex: -1,
  setCfIndex: (payload) => set(() => ({ cfIndex: payload })),
}))

export const getCfIndex = (state: CfIndexStore) => state.cfIndex
