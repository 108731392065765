import { SVGProps } from 'react'

const StarIconSmall = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ ...props.style }}
    >
      <path
        d="M7.6978 1.32287C7.81694 1.06458 8.18403 1.06458 8.30317 1.32287L9.99134 4.98281C10.0399 5.08808 10.1397 5.16056 10.2548 5.17421L14.2573 5.64877C14.5397 5.68226 14.6532 6.03139 14.4443 6.22451L11.4852 8.96104C11.4001 9.03975 11.362 9.15702 11.3846 9.27073L12.1701 13.224C12.2255 13.503 11.9285 13.7187 11.6803 13.5798L8.1633 11.6111C8.06214 11.5545 7.93883 11.5545 7.83767 11.6111L4.32066 13.5798C4.07245 13.7187 3.77547 13.503 3.8309 13.224L4.6164 9.27073C4.639 9.15702 4.60089 9.03975 4.51578 8.96104L1.55664 6.22451C1.34781 6.03139 1.46125 5.68226 1.74371 5.64877L5.74619 5.17421C5.86131 5.16056 5.96108 5.08808 6.00963 4.98281L7.6978 1.32287Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default StarIconSmall
