export const DetailBody = ({ data }: { data: string }) => {
  return (
    <div className="py-6 border-t border-mono-700">
      <p
        dangerouslySetInnerHTML={{ __html: data }}
        className="text-base detail"
      />
    </div>
  )
}
