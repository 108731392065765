import { Image } from 'react-datocms'
import PinIcon from '../icons/pin-icon'
import StarIcon from '../icons/star-icon'
import { RippleEffect } from '../ripple/ripple'
import { MouseEventHandler } from 'react'
import Link from 'next/link'
import { Tag } from '../../types/types'

export const NavListImage = ({
  onClick,
  tag,
  url,
}: {
  tag: Tag
  onClick?: MouseEventHandler<HTMLDivElement>
  url: string
}) => {
  return (
    <li className="relative w-[236px] desktop:w-1/3 p-2 cursor-pointer first:ml-5 md:first:ml-0">
      <Link href={url} passHref shallow>
        <a>
          <RippleEffect className="relative h-full" onClick={onClick}>
            {tag.image && (
              <>
                <Image
                  data={tag.image.responsiveImage}
                  className="pointer-events-none absolute top-0 left0"
                />
                <div className="absolute top-0 left-0 w-full h-full opacity-50 bg-bg-900" />
              </>
            )}
            <div
              className={`${
                tag.image ? 'absolute' : 'relative'
              } w-full h-full px-3 py-4 top-0 left-0`}
              style={{ touchAction: 'none' }}
            >
              <div className="absolute w-full h-full bg-bg-900 opacity-30 top-0 left-0" />
              <h3 className="relative font-black text-lg leading-none">
                {tag.name}
              </h3>
              <p className="relative leading-none font-normal text-sm mt-1">
                {tag.description}
              </p>
              <div className="absolute bottom-4 right-3 text-bg-800">
                <PinIcon>
                  <StarIcon pathProps={{ className: 'fill-current' }} />
                </PinIcon>
              </div>
            </div>
          </RippleEffect>
        </a>
      </Link>
    </li>
  )
}
