import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import Link from 'next/link'
import { Container } from './container/container'
import { Logo } from './icons/logo'
import { Cards } from './cards/cards'
import { ConnectionLine } from './connection-line/connection-line'
import {
  CARD_BUILDING_WIDTH_DESKTOP,
  INITIAL_ROTATION,
  MAX_DISTANCE,
} from '../lib/static-values'
import CompassIcon from './icons/compass-icon'
import { Menu } from './menu/menu'
import MenuIcon from './icons/menu-icon'
import ThreeStore from '../lib/store'
import { RippleEffect } from './ripple/ripple'
import { CardDetail } from './cards/card-detail'
import { Pill } from './pill/pill'
import { getShowMenu, useShowMenuStore } from '../hooks/store/use-show-menu'
import { useDistanceStore } from '../hooks/store/use-distance'
import {
  getDetailPageId,
  useDetailPageStore,
} from '../hooks/store/use-detail-page-id'
import {
  getCompassRotation,
  useCompassRotationStore,
} from '../hooks/store/use-compass-rotation'
import { getHasTouch, useHasTouchStore } from '../hooks/store/use-has-touch'
import { getOrbit, useOrbitStore } from '../hooks/store/use-orbit-store'
import {
  getActiveCardString,
  useActiveCardStore,
} from '../hooks/store/use-active-card'
import {
  getActiveTagListString,
  useActiveTagListStore,
} from '../hooks/store/use-active-tag-list'
import {
  getActiveTagString,
  useActiveTagStore,
} from '../hooks/store/use-active-tag'
import {
  getFavoritesString,
  useFavoritesStore,
} from '../hooks/store/use-favorites'
import {
  getTaggedString,
  useTaggedCardIdsStore,
} from '../hooks/store/use-tagged-card-ids'
import { getIsLoading, useIsLoadingStore } from '../hooks/store/use-is-loading'
import { LogoAnimation } from './logo-animation/logo-animation'
import { useRouter } from 'next/router'

const useMenuPath = (
  activeCardId: string,
  orbit: number,
  favoritesString: string,
  activeTagListId: string,
  activeTagId: string,
  taggedString: string,
) => {
  const { locale } = useRouter()
  return useMemo(() => {
    return `/${locale}/${activeCardId}/${orbit}/-/true/0/${favoritesString}/${activeTagListId}/${activeTagId}/${taggedString}`
  }, [
    activeCardId,
    orbit,
    favoritesString,
    activeTagListId,
    activeTagId,
    taggedString,
  ])
}

const useHomePath = (
  favoritesString: string,
  activeTagListId: string,
  activeTagId: string,
  taggedString: string,
) => {
  const { locale } = useRouter()
  return useMemo(() => {
    return `/${locale}/-/${INITIAL_ROTATION}/-/false/0/${favoritesString}/${activeTagListId}/${activeTagId}/${taggedString}`
  }, [favoritesString, activeTagListId, activeTagId, taggedString])
}

export const RenderPage = memo(() => {
  const showMenu = useShowMenuStore(getShowMenu)
  const setDistance = useDistanceStore(
    useCallback((state) => state.setDistance, []),
  )
  const detailPageId = useDetailPageStore(getDetailPageId)
  const compassRotation = useCompassRotationStore(getCompassRotation)

  const favoritesString = useFavoritesStore(getFavoritesString)
  const taggedString = useTaggedCardIdsStore(getTaggedString)
  const orbit = useOrbitStore(getOrbit)
  const activeCardId = useActiveCardStore(getActiveCardString)
  const activeTagListId = useActiveTagListStore(getActiveTagListString)
  const activeTagId = useActiveTagStore(getActiveTagString)
  const isLoading = useIsLoadingStore(getIsLoading)

  const hasTouch = useHasTouchStore(getHasTouch)

  const compassRef = useRef<SVGSVGElement>(null)

  const { locale } = useRouter()

  const language = useMemo(() => {
    switch (locale) {
      case 'ch-de':
        return 'de'
      case 'ch-fr':
        return 'fr'
      case 'ch-en':
        return 'en'
      default:
        return 'de'
    }
  }, [locale])
  const handleCompassRotation = (rotation: number) => {
    if (!compassRef.current) {
      return
    }
    compassRef.current.style.transform = `rotate3d(0,0,1, ${rotation - 50}deg`
  }

  const menuPath = useMenuPath(
    activeCardId,
    orbit,
    favoritesString,
    activeTagListId,
    activeTagId,
    taggedString,
  )
  const homePath = useHomePath(
    favoritesString,
    activeTagListId,
    activeTagId,
    taggedString,
  )

  useEffect(() => {
    handleCompassRotation(compassRotation)
  }, [compassRotation])

  return (
    <div className="absolute w-full h-full overflow-hidden" lang={language}>
      <header
        className="pointer-events-none z-40 pt-7 px-5 md:pt-16 md:px-8 desktop:pr-[392px] flex justify-between w-full flex-wrap"
        style={{
          paddingRight: hasTouch
            ? undefined
            : CARD_BUILDING_WIDTH_DESKTOP + 32 + 'px',
        }}
      >
        <h1 className="hidden">Kunsthaus Floorplan</h1>
        <div className="z-40 w-full flex justify-between items-center">
          <Link href={homePath} passHref>
            <a
              className="pointer-events-auto"
              onClick={() => {
                ThreeStore.set({ reset: true })
                setDistance(MAX_DISTANCE)
              }}
            >
              <Logo className="text-white" filterId="logoFilterId" />
            </a>
          </Link>
          <div className="flex items-center overflow-x-hidden">
            <Pill />
            <Link href={menuPath} passHref shallow>
              <a>
                <RippleEffect className="cursor-pointer pointer-events-auto rounded-full w-13 h-13 bg-bg-800 flex justify-center items-center desktop:hover:bg-mono-600">
                  <MenuIcon />
                </RippleEffect>
              </a>
            </Link>
          </div>
        </div>
        {/*<LocationIcon />*/}
        <div className="w-full flex justify-end items-center z-40">
          <div className="w-13 flex justify-center">
            <div className="mt-3 rounded-full w-8 h-8 bg-bg-800 flex justify-center items-center">
              <CompassIcon svgRef={compassRef} />
            </div>
          </div>
        </div>
        {!isLoading && <Menu />}
      </header>
      <main>
        <section>
          <Cards />
        </section>
        <section>{detailPageId && <CardDetail />}</section>
      </main>
      <aside
        className="absolute top-0 left:0 w-full h-full bg-gray-700"
        style={{
          display: (showMenu || detailPageId) && !isLoading ? 'none' : 'block',
        }}
      >
        <h2 className="hidden">3D Map</h2>
        <Container />
        <ConnectionLine className="absolute top-0 left-0 w-full h-full" />
      </aside>
      {isLoading && <LogoAnimation />}
    </div>
  )
})
