import { MutableRefObject, useEffect, useState } from 'react'
import { FloorIndicator } from './floor-indicator'
import { getFloors, useFloorsStore } from '../../hooks/store/use-floors'
import {
  getActiveFloor,
  useActiveFloorStore,
} from '../../hooks/store/use-active-card'

export const Floors = ({
  cardWrapper,
  hasTouch,
  setToggleFloorBackground,
  toggleFloorBackground,
}: {
  cardWrapper: MutableRefObject<HTMLDivElement | null>
  hasTouch: boolean
  setToggleFloorBackground: (state: boolean) => void
  toggleFloorBackground: boolean
}) => {
  const floors = useFloorsStore(getFloors)
  const activeFloor = useActiveFloorStore(getActiveFloor)

  const [activeIndex, setActiveIndex] = useState(-1)

  useEffect(() => {
    if (toggleFloorBackground) {
      return
    }
    setActiveIndex(-1)
  }, [toggleFloorBackground])

  return (
    <div>
      {floors.map((floor, i) => {
        {
          if (
            (activeFloor === 10 && floor.floor === 10) ||
            (activeFloor !== 10 && floor.floor !== 10)
          ) {
            return (
              <FloorIndicator
                wrapper={cardWrapper}
                floor={floor}
                hasTouch={hasTouch}
                key={`floor-${i}`}
                activeIndex={activeIndex}
                index={i}
                setActiveIndex={(index) => {
                  setActiveIndex(index)
                  setToggleFloorBackground(index > -1)
                }}
              />
            )
          }
        }
      })}
    </div>
  )
}
