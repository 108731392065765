import { OrbitControls } from '@react-three/drei'
import { Box3, MathUtils } from 'three'
import { MutableRefObject, useEffect, useRef } from 'react'
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib'
import ThreeStore from '../../lib/store'
import { usePan } from '../../hooks/use-pan'
import { useRotate } from '../../hooks/use-rotate'
import { useZoom } from '../../hooks/use-zoom'
import { useAnimate } from '../../hooks/use-animate'
import { useScroll } from '../../hooks/use-scroll'
import { useNextTarget } from '../../hooks/use-next-target'
import { getIsReady, useIsReadyStore } from '../../hooks/store/use-is-ready'

type ControllerProps = {
  panMovement: MutableRefObject<[number, number]>
  boundingBox: MutableRefObject<Box3 | null>
}

export const Controller = ({ boundingBox, panMovement }: ControllerProps) => {
  const controller = useRef<OrbitControlsImpl>(null)
  const isInitial = useRef(false)
  const cameraIsSet = useRef(false)

  const isReady = useIsReadyStore(getIsReady)

  const cameraTarget = useNextTarget()
  usePan(controller, panMovement, boundingBox)
  useRotate(controller)
  useZoom(controller)
  useAnimate(controller)
  useScroll(controller, cameraIsSet)

  useEffect(() => {
    if (!controller.current) {
      return
    }
    const { orbit } = ThreeStore

    controller.current.minDistance = ThreeStore.distance
    controller.current.maxDistance = ThreeStore.distance
    controller.current.minAzimuthAngle = MathUtils.degToRad(orbit)
    controller.current.maxAzimuthAngle = MathUtils.degToRad(orbit)
    isInitial.current = true
    cameraIsSet.current = true
    controller.current.target.copy(cameraTarget)
  }, [isReady])

  return (
    <OrbitControls
      ref={controller}
      enablePan={false}
      enableZoom={false}
      enableRotate={false}
      minPolarAngle={MathUtils.degToRad(60)}
      maxPolarAngle={MathUtils.degToRad(60)}
    />
  )
}
