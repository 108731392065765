import Image from 'next/image'
import { RippleEffect } from '../ripple/ripple'
import CloseIcon from '../icons/close-icon'
import { NavListImage } from './nav-list-image'
import { NavListItem } from './nav-list-item'
import ArrowForwardIcon from '../icons/arrow-forward-icon'
import { NavListLink } from './nav-list-link'
import LinkIcon from '../icons/link-icon'
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import Link from 'next/link'
import { LangLink } from './menu-lang-link'
import LikeFilledIcon from '../icons/like-filled-icon'
import { useDrag } from 'react-use-gesture'
import { animated, useSpring } from 'react-spring'
import { Tag } from '../../types/types'
import { TagsStore, useTagsStore } from '../../hooks/store/use-tags'
import {
  getVisitorInfos,
  useVisitorInfosStore,
} from '../../hooks/store/use-visitor-infos'
import {
  getQuicklinks,
  useQuicklinksStore,
} from '../../hooks/store/use-quicklinks'
import {
  getTranslations,
  TranslationsStore,
  useTranslationsStore,
} from '../../hooks/store/use-translations'
import {
  getOpeningHours,
  useOpeningHoursStore,
} from '../../hooks/store/use-opening-hours'
import { getHasTouch, useHasTouchStore } from '../../hooks/store/use-has-touch'
import {
  getActiveCardString,
  useActiveCardStore,
} from '../../hooks/store/use-active-card'
import {
  getFavoritesString,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'
import {
  getTaggedString,
  useTaggedCardIdsStore,
} from '../../hooks/store/use-tagged-card-ids'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'
import { UsefulInfos } from './useful-infos'
import {
  getUsefulInfos,
  useUsefulInfos,
} from '../../hooks/store/use-useful-infos'

const useTagLink = (
  cardId: string,
  favoritesString: string,
  taggedString: string,
  orbit: number,
) => {
  return useCallback(
    (navLevel: number, activeTagId: string) => {
      return `${cardId}/${orbit}/-/true/${navLevel}/${favoritesString}/${activeTagId}/${activeTagId}/${taggedString}`
    },
    [cardId, orbit, favoritesString, taggedString],
  )
}

const useTagListLink = (
  cardId: string,
  favoritesString: string,
  taggedString: string,
  orbit: number,
) => {
  return useCallback(
    (navLevel: number, activeTagListId: string) => {
      return `${cardId}/${orbit}/-/true/${navLevel}/${favoritesString}/${activeTagListId}/-/${taggedString}`
    },
    [cardId, orbit, favoritesString, taggedString],
  )
}

const useCloseLink = (
  cardId: string,
  favoritesString: string,
  taggedString: string,
  orbit: number,
) => {
  return useMemo(() => {
    return `${cardId}/${orbit}/-/false/0/${favoritesString}/-/-/${taggedString}`
  }, [cardId, orbit, favoritesString, taggedString])
}

const useTagLinks = () => {
  const getTagLinks = useCallback((state: TagsStore) => {
    return state.tags
      .filter(
        (tag) =>
          tag.tagType &&
          tag.tagType.name !== 'tip' &&
          tag.tagType.name !== 'favorites',
      )
      .reduce((acc, tag) => {
        const hasTagAlready = acc.find(
          (entry) =>
            tag.tagType &&
            entry.tagType &&
            entry.tagType.name === tag.tagType.name,
        )
        if (!hasTagAlready) {
          acc.push(tag)
        }
        return acc
      }, [] as Tag[])
  }, [])
  return useTagsStore(getTagLinks)
}

const useImageLinks = () => {
  const getImagesLinks = useCallback((state: TagsStore) => {
    const tipTags = state.tags.filter(
      (tag) => tag.tagType && tag.tagType.name === 'tip',
    )
    tipTags.sort(
      (current, next) =>
        (current.index !== null ? current.index : 0) -
        (next.index !== null ? next.index : 0),
    )
    return tipTags
  }, [])
  return useTagsStore(getImagesLinks)
}
const useFavoritesLink = () => {
  const getFavoriteLink = useCallback((state: TagsStore) => {
    return state.tags.find(
      (tag) => tag.tagType && tag.tagType.name === 'favorites',
    )
  }, [])
  return useTagsStore(getFavoriteLink)
}

const getUsefulInfosTitle = (state: TranslationsStore) =>
  state.translations.find((item) => item.id === '60013170')

export const MenuNav = forwardRef<HTMLUListElement>(({}, ref) => {
  const visitorInfos = useVisitorInfosStore(getVisitorInfos)
  const quicklinks = useQuicklinksStore(getQuicklinks)
  const translations = useTranslationsStore(getTranslations)
  const usefulInfosTitle = useTranslationsStore(getUsefulInfosTitle)
  const openingHours = useOpeningHoursStore(getOpeningHours)
  const usefulInfos = useUsefulInfos(getUsefulInfos)

  const cardId = useActiveCardStore(getActiveCardString)
  const favoritesString = useFavoritesStore(getFavoritesString)
  const taggedString = useTaggedCardIdsStore(getTaggedString)
  const orbit = useOrbitStore(getOrbit)

  const closeLink = useCloseLink(cardId, favoritesString, taggedString, orbit)

  const tagListLink = useTagListLink(
    cardId,
    favoritesString,
    taggedString,
    orbit,
  )
  const tagLink = useTagLink(cardId, favoritesString, taggedString, orbit)

  const hasTouch = useHasTouchStore(getHasTouch)
  const offset = useRef(0)
  const maxOffset = useRef(0)
  const tipsRef = useRef<HTMLUListElement | null>(null)
  const [move, setX] = useSpring(() => ({
    x: 0,
    down: false,
    onRest: ({ value: { down } }) => {
      if (down) {
        return
      }
      if (offset.current > 0) {
        offset.current = 0
        setX.start({ x: offset.current })
      }
      if (offset.current < maxOffset.current) {
        offset.current = maxOffset.current
        setX.start({ x: offset.current })
      }
    },
  }))
  const bind = useDrag(({ delta: [x], velocity, down }) => {
    if (!hasTouch) {
      return
    }
    if (!tipsRef.current || !tipsRef.current.parentElement) {
      return
    }
    if (!maxOffset.current) {
      maxOffset.current = -(
        tipsRef.current.offsetWidth - tipsRef.current.parentElement.offsetWidth
      )
    }

    offset.current += x * velocity

    setX.start(() => ({
      x: offset.current,
      down,
    }))
  })

  const [activeIndex, setActiveIndex] = useState(-1)

  const handleIndex = (index: number) => {
    setActiveIndex(activeIndex === index ? -1 : index)
  }

  const tagLinks = useTagLinks()

  const tagImageLinks = useImageLinks()

  const myFavorites = useFavoritesLink()
  const refererUrl = window.location.href
  return (
    <ul className="relative mb-20 pt-7 desktop:pt-0 " ref={ref}>
      <li>
        <div className="flex justify-between">
          <div className="flex">
            <LangLink lang="ch-de" />
            <LangLink lang="ch-en" />
            <LangLink lang="ch-fr" />
          </div>
          <div className="w-13 h-13 ">
            <Link href={closeLink} passHref shallow>
              <a className="fixed z-50 desktop:relative ">
                <RippleEffect className="cursor-pointer pointer-events-auto w-13 h-13 flex justify-center items-center bg-bg-800 desktop:hover:bg-mono-600 rounded-full">
                  <CloseIcon />
                </RippleEffect>
              </a>
            </Link>
          </div>
        </div>
        <h2 className="text-2.5xl font-black mb-4 mt-8">
          {translations.find((item) => item.id === '54029561')?.text}
        </h2>

        <div
          className="overflow-hidden md:mx-0 mx-[-20px] "
          {...bind()}
          style={{ touchAction: 'pan-y' }}
        >
          <div className="m-[-8px]">
            <animated.ul
              ref={tipsRef}
              className="flex desktop:flex-wrap justify-start items-start w-min desktop:w-full h-full"
              style={{
                transform: move.x.to((x) => `translate3d(${x}px, 0, 0)`),
              }}
            >
              {tagImageLinks.map((tag) => {
                return (
                  <NavListImage
                    tag={tag}
                    key={tag.id}
                    url={tagLink(2, tag.id)}
                  />
                )
              })}
            </animated.ul>
          </div>
        </div>
      </li>
      <li className="mt-4">
        {myFavorites && (
          <Link href={tagLink(2, myFavorites.id)} passHref shallow>
            <a>
              <NavListItem
                title={myFavorites.name}
                startIcon={<LikeFilledIcon />}
                icon={<ArrowForwardIcon />}
              />
            </a>
          </Link>
        )}
      </li>
      <li>
        <h2 className="text-2.5xl font-black mb-6 mt-8">
          {translations.find((item) => item.id === '54028241')?.text}
        </h2>
        <ul>
          {tagLinks.map((link) => {
            return (
              <li key={link.id}>
                <Link href={tagListLink(1, link.id)} passHref shallow>
                  <a>
                    <NavListItem
                      title={link.tagType.title}
                      icon={<ArrowForwardIcon />}
                    />
                  </a>
                </Link>
              </li>
            )
          })}
        </ul>
      </li>
      <li>
        <h2 className="text-2.5xl font-black mb-6 mt-8">
          {translations.find((item) => item.id === '54028247')?.text}
        </h2>
        <ul>
          {quicklinks.length
            ? quicklinks.map((link, index) => {
                return (
                  <li key={`${link.text}_${link.url}_${index}`}>
                    <NavListLink
                      href={link.url}
                      icon={<LinkIcon />}
                      title={link.text}
                      external
                      isLast={index === quicklinks.length - 1}
                      referer={link.withReferer}
                    />
                  </li>
                )
              })
            : null}
        </ul>
      </li>
      <li>
        <h2 className="text-2.5xl font-black mb-6 mt-8">
          {translations.find((item) => item.id === '54028259')?.text}
        </h2>
        <ul>
          {visitorInfos.length
            ? visitorInfos.map((link, index) => {
                return (
                  <li key={`${link.text}_${link.url}_${index}`}>
                    <NavListLink
                      href={link.url}
                      icon={<LinkIcon />}
                      title={link.text}
                      external
                      isLast={index === visitorInfos.length - 1}
                      referer={link.withReferer}
                    />
                  </li>
                )
              })
            : null}
          {openingHours && (
            <li>
              <h6 className="text-l font-black mt-5 mb-4">
                {openingHours.data.openingHour.title}
              </h6>
              <div
                className="text-l text-typo-200"
                itemScope
                itemType="https://schema.org/Museum"
              >
                {openingHours.display.map(({ hours, days }, index) => {
                  const maxDays = days.length
                  if (!days.length) {
                    return null
                  }
                  const renderDays = days.reduce((acc, entry, renderIndex) => {
                    if (entry.firstDay === entry.lastDay) {
                      acc += `${entry.name}${
                        maxDays > 0 && renderIndex < maxDays - 1 ? ', ' : ' '
                      }`
                      return acc
                    }
                    acc += `${entry.firstDay} - ${entry.lastDay}${
                      maxDays > 0 && renderIndex < maxDays - 1 ? ',' : ''
                    }`
                    return acc
                  }, '')
                  return (
                    <time
                      className="w-full block"
                      key={`time_${hours}_${renderDays}_${index}`}
                    >
                      {renderDays}{' '}
                      <span className="whitespace-nowrap">
                        {hours}
                        {index < openingHours.display.length - 1 ? ',' : ''}
                      </span>
                    </time>
                  )
                })}
                {openingHours.data.openingHour.time.map(({ hour, day }) => {
                  return day.map(({ shortName }) => {
                    return (
                      <meta
                        itemProp="openingHours"
                        content={`${shortName} ${hour.replace(' ', '')}`}
                        key={`meta_openingHours_${hour}_${shortName}`}
                      />
                    )
                  })
                })}
              </div>
              {openingHours.data.openingHour.link ? (
                <div className="flex py-4">
                  <Link
                    href={`${openingHours.data.openingHour.link.url}${
                      openingHours.data.openingHour.link.withReferer
                        ? `?referrer=${refererUrl}`
                        : ''
                    }`}
                    passHref
                  >
                    <a target="_blank" rel="noopener noreferrer">
                      <div className=" flex text-l items-center">
                        <LinkIcon className="pr-2" />
                        {openingHours.data.openingHour.link.text}
                      </div>
                    </a>
                  </Link>
                </div>
              ) : null}
            </li>
          )}
          {usefulInfos.length ? (
            <li>
              <h6 className="text-l font-black mt-5 mb-4">
                {usefulInfosTitle?.text || ''}
              </h6>
              {usefulInfos.map((info, index) => {
                return (
                  <UsefulInfos
                    title={info.title}
                    text={info.text}
                    isCollapsed={activeIndex != index}
                    onClick={() => {
                      handleIndex(index)
                    }}
                    isLast={index === usefulInfos.length - 1}
                    key={`${info.title}_${index}`}
                  />
                )
              })}
            </li>
          ) : null}
        </ul>
      </li>
      <li>
        <h2 className="text-2.5xl font-black mb-6 mt-8">
          Best of Swiss Web Award 2022
        </h2>
        <span className="block text-l text-typo-200 mb-6">
          {translations.find(({ id }) => id === '121634343')?.text}
        </span>

        <div className="relative w-full flex gap-x-4 md:gap-x-6">
          <div className="w-[110px] md:w-[170px]">
            <Image src="/imgs/bosw-creativity.png" width={946} height={946} />
          </div>
          <div className="w-[110px] md:w-[170px]">
            <Image src="/imgs/bosw-technology.png" width={946} height={946} />
          </div>
        </div>
      </li>
    </ul>
  )
})
