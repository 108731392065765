import create from 'zustand'

export type ShowMenuStore = {
  showMenu: boolean
  setShowMenu: (payload: boolean) => void
}
export const useShowMenuStore = create<ShowMenuStore>((set) => ({
  showMenu: false,
  setShowMenu: (payload) => set(() => ({ showMenu: payload })),
}))

export const getShowMenu = (state: ShowMenuStore) => state.showMenu
