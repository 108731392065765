import { SVGProps } from 'react'

const MenuIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5.5C3 5.22386 3.22386 5 3.5 5H20.5C20.7761 5 21 5.22386 21 5.5V6.5C21 6.77614 20.7761 7 20.5 7H3.5C3.22386 7 3 6.77614 3 6.5V5.5ZM3 11.5C3 11.2239 3.22386 11 3.5 11H16.5C16.7761 11 17 11.2239 17 11.5V12.5C17 12.7761 16.7761 13 16.5 13H3.5C3.22386 13 3 12.7761 3 12.5V11.5ZM3.5 17C3.22386 17 3 17.2239 3 17.5V18.5C3 18.7761 3.22386 19 3.5 19H20.5C20.7761 19 21 18.7761 21 18.5V17.5C21 17.2239 20.7761 17 20.5 17H3.5Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default MenuIcon
