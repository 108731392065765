import { RippleEffect } from '../ripple/ripple'
import { LinkList } from '../../types/types'
import Link from 'next/link'
import { renderIcon } from '../icons/render-icons'

export const ExternalLinks = ({ linkList }: { linkList: LinkList }) => {
  const refererUrl = window.location.href

  return (
    <div className="w-full py-6 border-t border-mono-700">
      <ul
        className="flex flex-row desktop:flex-wrap overflow-x-scroll desktop:overflow-x-visible pb-5"
        style={{ marginTop: '-4px', marginBottom: '-4px' }}
      >
        {linkList.links.length
          ? linkList.links.map((link, index) => {
              return (
                <li
                  className="mr-3 my-1 cursor-pointer flex-shrink-0"
                  key={`LinkList_${link.text}_${index}`}
                >
                  <Link
                    href={`${link.url}${
                      link.withReferer ? `?referrer=${refererUrl}` : ''
                    }`}
                    passHref
                  >
                    <a target="_blank" rel="noopener noreferrer">
                      <RippleEffect
                        className={`flex justify-center items-center bg-bg-800 rounded-full py-2 px-4 ${
                          link.startIcon ? 'pl-3' : ''
                        }`}
                      >
                        {link.startIcon &&
                          renderIcon({
                            key: link.startIcon + index,
                            name: link.startIcon,
                            withBG: false,
                            props: { className: 'w-4 h-4 mr-1' },
                          })}
                        <p className="text-typo-100 text-sm">{link.text}</p>
                        {link.endIcon &&
                          renderIcon({
                            key: link.endIcon + index,
                            name: link.endIcon,
                            withBG: false,
                            props: { className: 'w-4 h-4 ml-1' },
                          })}
                      </RippleEffect>
                    </a>
                  </Link>
                </li>
              )
            })
          : null}
      </ul>
    </div>
  )
}
