import { SVGProps } from 'react'

const StarIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5457 1.98442C11.7244 1.59699 12.2751 1.59699 12.4538 1.98442L14.986 7.47434C15.0589 7.63224 15.2085 7.74096 15.3812 7.76144L21.3849 8.47327C21.8086 8.52351 21.9788 9.0472 21.6655 9.33689L17.2268 13.4417C17.0991 13.5597 17.042 13.7357 17.0759 13.9062L18.2541 19.8361C18.3373 20.2545 17.8918 20.5782 17.5195 20.3698L12.244 17.4168C12.0922 17.3319 11.9073 17.3319 11.7555 17.4168L6.48001 20.3698C6.1077 20.5782 5.66222 20.2545 5.74537 19.8361L6.92363 13.9062C6.95752 13.7357 6.90036 13.5597 6.77269 13.4417L2.33398 9.33689C2.02073 9.0472 2.19089 8.52351 2.61459 8.47327L8.61831 7.76144C8.791 7.74096 8.94064 7.63224 9.01347 7.47434L11.5457 1.98442Z"
        fill="currentColor"
        {...pathProps}
      />
    </svg>
  )
}

export default StarIcon
