import { HTMLAttributes, PropsWithChildren, SVGProps } from 'react'

const PinIcon = ({
  isLikePin = false,
  fillProps,
  strokeProps,
  svgProps,
  wrapperProps,
  ...props
}: PropsWithChildren<{
  isLikePin?: boolean
  wrapperProps?: HTMLAttributes<HTMLDivElement>
  strokeProps?: SVGProps<SVGPathElement>
  fillProps?: SVGProps<SVGPathElement>
  svgProps?: SVGProps<SVGSVGElement>
}>) => {
  return (
    <div
      {...wrapperProps}
      style={{ width: '32px', height: '38px', ...wrapperProps?.style }}
      className={`relative flex justify-center ${wrapperProps?.className}`}
    >
      <svg
        width="32"
        height="38"
        viewBox="0 0 32 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...svgProps}
        className={`absolute left-0 top-0 ${
          svgProps ? svgProps.className : ''
        } ${isLikePin ? 'text-bg-800' : ''}`}
      >
        <path
          d="M32 16.2857C32 28.5 16.6667 38 16 38C15.3333 38 0 28.5 0 16.2857C0 7.29136 7.16344 0 16 0C24.8366 0 32 7.29136 32 16.2857Z"
          fill={isLikePin ? 'currentColor' : '#e4bf61'}
          {...fillProps}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8385 30.3063C26.6408 26.5184 30 21.5796 30 16.2857C30 8.36241 23.6988 2 16 2C8.30124 2 2 8.36241 2 16.2857C2 21.5796 5.35918 26.5184 9.16152 30.3063C11.0269 32.1646 12.9165 33.6628 14.3725 34.6911C15.0277 35.1538 15.5841 35.5137 16 35.7638C16.4159 35.5137 16.9723 35.1538 17.6275 34.6911C19.0835 33.6628 20.9731 32.1646 22.8385 30.3063ZM16 38C16.6667 38 32 28.5 32 16.2857C32 7.29136 24.8366 0 16 0C7.16344 0 0 7.29136 0 16.2857C0 28.5 15.3333 38 16 38Z"
          fill={isLikePin ? 'currentColor' : '#DAA520'}
          {...strokeProps}
        />
      </svg>
      <div className="relative pt-[5px] text-current">{props.children}</div>
    </div>
  )
}

export default PinIcon
