import { SVGProps } from 'react'

const ToiletsIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9997 3.5C10.9997 3.22386 11.2236 3 11.4997 3H12.4997C12.7759 3 12.9997 3.22386 12.9997 3.5V20.5C12.9997 20.7761 12.7759 21 12.4997 21H11.4997C11.2236 21 10.9997 20.7761 10.9997 20.5V3.5ZM7.49974 5.5C7.49974 6.32787 6.82828 7 5.99974 7C5.17187 7 4.49974 6.32787 4.49974 5.5C4.49974 4.67146 5.17187 4 5.99974 4C6.82828 4 7.49974 4.67146 7.49974 5.5ZM17.9997 7C18.8283 7 19.4997 6.32854 19.4997 5.5C19.4997 4.67146 18.8283 4 17.9997 4C17.1712 4 16.4997 4.67146 16.4997 5.5C16.4997 6.32854 17.1712 7 17.9997 7ZM4.4233 8C4.17888 8 3.97029 8.17671 3.9301 8.4178L3.09677 13.4178C3.04598 13.7226 3.281 14 3.58997 14H3.99974L4.9301 19.5822C4.97029 19.8233 5.17888 20 5.4233 20H6.57617C6.82059 20 7.02919 19.8233 7.06937 19.5822L7.99974 14H8.40951C8.71848 14 8.9535 13.7226 8.9027 13.4178L8.06937 8.4178C8.02919 8.17671 7.82059 8 7.57617 8H4.4233ZM16.4093 8C16.1709 8 15.9657 8.16823 15.919 8.40194L15.119 12.4019C15.0571 12.7113 15.2938 13 15.6093 13H15.9997L15.2191 15.3419C15.1112 15.6657 15.3521 16 15.6934 16H15.9997L16.905 19.6213C16.9607 19.8439 17.1607 20 17.3901 20H18.6093C18.8388 20 19.0387 19.8439 19.0944 19.6213L19.9997 16H20.306C20.6473 16 20.8883 15.6657 20.7803 15.3419L19.9997 13H20.3895C20.705 13 20.9416 12.7113 20.8798 12.4019L20.0798 8.40194C20.033 8.16823 19.8278 8 19.5895 8H16.4093Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default ToiletsIcon
