import create from 'zustand'
import { arrayToString } from '../../lib/array-to-string'

export type TaggedCardIdsStore = {
  taggedCardIds: string[]
  setTaggedCardIds: (payload: string[]) => void
}
export const useTaggedCardIdsStore = create<TaggedCardIdsStore>((set) => ({
  taggedCardIds: [],
  setTaggedCardIds: (payload) => set(() => ({ taggedCardIds: payload })),
}))

export const getTaggedCardIds = (state: TaggedCardIdsStore) =>
  state.taggedCardIds

export const getTaggedString = (state: TaggedCardIdsStore) =>
  arrayToString(state.taggedCardIds)
