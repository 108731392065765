import { Card } from '../../types/types'
import create from 'zustand'

export type HoverStore = {
  hoveredCard: Card | null
  setHoveredCard: (payload: Card | null) => void
}
export const useHoverStore = create<HoverStore>((set) => ({
  hoveredCard: null,
  setHoveredCard: (payload) => set(() => ({ hoveredCard: payload })),
}))

export const getHover = (state: HoverStore) => state.hoveredCard
