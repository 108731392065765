import { RippleEffect } from '../ripple/ripple'
import Link from 'next/link'
import { DetailedHTMLProps, HTMLAttributes, useCallback } from 'react'
import { useRouter } from 'next/router'
import { getLanguage } from '../../lib/get-language'
import { FetchData } from '../../types/types'
import { useAllCardsStore } from '../../hooks/store/use-all-cards'
import { useMoserCardStore } from '../../hooks/store/use-moser-card'
import { useMoserIndexStore } from '../../hooks/store/use-moser-index'
import { useCfCardStore } from '../../hooks/store/use-cf-card'
import { useCfIndexStore } from '../../hooks/store/use-cf-index'
import { useFloorsStore } from '../../hooks/store/use-floors'
import { useOpeningHoursStore } from '../../hooks/store/use-opening-hours'
import { useTranslationsStore } from '../../hooks/store/use-translations'
import { useTagsStore } from '../../hooks/store/use-tags'
import { useUsefulInfos } from '../../hooks/store/use-useful-infos'
import { useVisitorInfosStore } from '../../hooks/store/use-visitor-infos'
import { useQuicklinksStore } from '../../hooks/store/use-quicklinks'
import {
  getActivePill,
  useActivePillStore,
} from '../../hooks/store/use-active-pill'
import {
  getActiveTag,
  useActiveTagStore,
} from '../../hooks/store/use-active-tag'
import { filterCardsByTag } from '../../lib/filter-cards-by-tag'
import { useActiveCardListStore } from '../../hooks/store/use-active-card-list'
import {
  getFavorites,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'

export const LangLink = ({
  lang,
  ...rest
}: { lang?: 'ch-de' | 'ch-fr' | 'ch-en' } & DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  const { locale, asPath } = useRouter()
  const langText = lang === 'ch-de' ? 'DE' : lang === 'ch-fr' ? 'FR' : 'EN'

  const setMoserCard = useMoserCardStore(
    useCallback((state) => state.setMoserCard, []),
  )
  const setMoserIndex = useMoserIndexStore(
    useCallback((state) => state.setMoserIndex, []),
  )
  const setCFCard = useCfCardStore(useCallback((state) => state.setCfCard, []))
  const setCfIndex = useCfIndexStore(
    useCallback((state) => state.setCfIndex, []),
  )
  const setAllCards = useAllCardsStore(
    useCallback((state) => state.setAllCards, []),
  )
  const setFloors = useFloorsStore(useCallback((state) => state.setFloors, []))
  const setOpeningHours = useOpeningHoursStore(
    useCallback((state) => state.setOpeningHours, []),
  )
  const setTranslations = useTranslationsStore(
    useCallback((state) => state.setTranslations, []),
  )
  const setTags = useTagsStore(useCallback((state) => state.setTags, []))
  const setUsfulInfos = useUsefulInfos(
    useCallback((state) => state.setUsefulInfos, []),
  )
  const setVisitorInfos = useVisitorInfosStore(
    useCallback((state) => state.setVisitorInfos, []),
  )
  const setQuicklinks = useQuicklinksStore(
    useCallback((state) => state.setQuicklinks, []),
  )
  const setActivePill = useActivePillStore(
    useCallback((state) => state.setActivePill, []),
  )
  const activePill = useActivePillStore(getActivePill)
  const setActiveTag = useActiveTagStore(
    useCallback((state) => state.setActiveTag, []),
  )
  const activeTag = useActiveTagStore(getActiveTag)
  const setActiveCardList = useActiveCardListStore(
    useCallback((state) => state.setActiveCardList, []),
  )
  const favorites = useFavoritesStore(getFavorites)

  const fetchLang = useCallback(async (language?: string) => {
    const fetchData: () => Promise<FetchData> = async () => {
      return await fetch(`/api/change-lang/${getLanguage(language)}`)
        .then((data) => {
          return data.json()
        })
        .then((r) => {
          return r
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const {
      cfCard,
      cfCardIndex,
      cards,
      moserCardIndex,
      moserCard,
      openingHours,
      usefulInfos,
      visitorInfos,
      quicklinks,
      floors,
      translations,
      tags,
    } = await fetchData()

    setCfIndex(cfCardIndex)
    cfCard && setCFCard(cfCard)
    moserCard && setMoserCard(moserCard)
    setMoserIndex(moserCardIndex)
    setAllCards(cards)
    setFloors(floors)
    setOpeningHours(openingHours)
    setTranslations(translations)
    setTags(tags)
    setUsfulInfos(usefulInfos)
    setVisitorInfos(visitorInfos)
    setQuicklinks(quicklinks)

    const foundActiveTag =
      tags.find((entry) => entry.id === activeTag?.id) || null
    const foundActivePill =
      tags.find((entry) => entry.id === activePill?.id) || null
    setActivePill(foundActivePill)
    setActiveTag(foundActiveTag)

    if (foundActiveTag && foundActiveTag?.tagType.name !== 'favorites') {
      setActiveCardList(filterCardsByTag(foundActiveTag, cards))
      return
    }

    const filteredCards = cards.filter((card) =>
      favorites.find((id) => id === card.id),
    )

    setActiveCardList([...filteredCards])
  }, [])

  if (lang !== locale) {
    return (
      <Link href={`/${lang}${asPath}`} locale={lang} shallow passHref>
        <a
          onClick={() => {
            fetchLang(lang)
          }}
        >
          <RippleEffect
            className={`cursor-pointer pointer-events-auto w-13 h-13 flex justify-center items-center bg-bg-800 desktop:hover:bg-mono-600 rounded-full mr-3 ${rest.className}`}
          >
            <span className="text-l">{langText}</span>
          </RippleEffect>
        </a>
      </Link>
    )
  }
  return (
    <div
      className={`pointer-events-auto w-13 h-13 flex justify-center items-center bg-primary-200 rounded-full mr-3 ${rest.className}`}
    >
      <span className="text-l">{langText}</span>
    </div>
  )
}
