import { SVGProps } from 'react'

const ArrowLeftLargeIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
      {...props}
    >
      <path
        fill="#F0F0F0"
        d="M14.4394 5.14645C14.6347 4.95118 14.9513 4.95118 15.1465 5.14645L15.8536 5.85355C16.0489 6.04882 16.0489 6.3654 15.8536 6.56066L5.41436 16.9999H34.5002C34.7763 16.9999 35.0002 17.2238 35.0002 17.4999V18.4999C35.0002 18.7761 34.7763 18.9999 34.5002 18.9999H5.41421L15.8536 29.4393C16.0489 29.6346 16.0489 29.9512 15.8536 30.1464L15.1465 30.8536C14.9513 31.0488 14.6347 31.0488 14.4394 30.8536L1.93942 18.3536C1.74415 18.1583 1.74415 17.8417 1.93942 17.6464L14.4394 5.14645Z"
        {...pathProps}
      />
    </svg>
  )
}

export default ArrowLeftLargeIcon
