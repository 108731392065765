import create from 'zustand'

export type HasTouchStore = {
  hasTouch: boolean
  setHasTouch: (payload: boolean) => void
}
export const useHasTouchStore = create<HasTouchStore>((set) => ({
  hasTouch: false,
  setHasTouch: (payload) => set(() => ({ hasTouch: payload })),
}))

export const getHasTouch = (state: HasTouchStore) => state.hasTouch
