import create from 'zustand'

export type TranslationsStore = {
  translations: { id: string; text: string }[]
  setTranslations: (payload: { id: string; text: string }[]) => void
}
export const useTranslationsStore = create<TranslationsStore>((set) => ({
  translations: [],
  setTranslations: (payload) => set(() => ({ translations: payload })),
}))

export const getTranslations = (state: TranslationsStore) => state.translations
