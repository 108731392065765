import { MutableRefObject } from 'react'
import { Card } from '../types/types'
import { SpringRef } from 'react-spring'
import { centerCard } from './center-card'

export const handleCardOffset = (
  down: boolean,
  dx: number,
  dy: number,
  velocity: number,
  movementX: number,
  movementY: number,
  offset: MutableRefObject<number>,
  hasTouch: boolean,
  closestCard: MutableRefObject<Card | null | undefined>,
  cards: Card[],
  cfCardIndex: number,
  setSpring: SpringRef<{ offset: number; down: boolean }>,
) => {
  const delta = hasTouch ? dx : dy
  const movement = delta + delta * velocity

  offset.current = offset.current + movement

  if (movementX < 2 && movementX > -2 && movementY < 2 && movementY > -2) {
    return
  }

  if (!down) {
    const invertOffset = offset.current * -1
    const middleCardOffset = invertOffset
    const cardLength = cards.length
    closestCard.current = cards.find((card, i) => {
      const distanceToCardCenter =
        middleCardOffset - centerCard(card, hasTouch) - card.offset

      const range = hasTouch ? card.width / 2 : card.height / 2
      if (Math.abs(distanceToCardCenter) < range) {
        return card
      }
      if (i === 0 && distanceToCardCenter < range) {
        return card
      }
      if (i === cardLength - 1 && distanceToCardCenter > range) {
        return card
      }
    })
    if (!closestCard.current) {
      return
    }

    if (closestCard.current.type === 'floor') {
      const foundIndex = cards.findIndex(
        (card) => card.id === closestCard.current?.id,
      )

      if (closestCard.current.building === 'Moser') {
        closestCard.current = cards[foundIndex - 1]
      } else {
        closestCard.current = cards[foundIndex + 1]
      }
    }

    if (
      closestCard.current &&
      closestCard.current.title === 'Moser' &&
      closestCard.current.type === 'building' &&
      closestCard.current.offset +
        centerCard(closestCard.current, hasTouch, 0.75) <
        invertOffset
    ) {
      offset.current = -cards[cfCardIndex]?.offset
      closestCard.current = null
    } else if (
      closestCard.current &&
      closestCard.current.title === 'Chipperfield' &&
      closestCard.current.type === 'building' &&
      closestCard.current.offset +
        centerCard(closestCard.current, hasTouch, 0.25) >
        invertOffset
    ) {
      offset.current = -cards[cfCardIndex]?.offset
      closestCard.current = null
    } else {
      offset.current = -(
        closestCard.current.offset + centerCard(closestCard.current, hasTouch)
      )
    }
  }

  if (-(cards[0].offset + centerCard(cards[0], hasTouch)) < offset.current) {
    offset.current = -(cards[0].offset + centerCard(cards[0], hasTouch))
    closestCard.current = cards[0]
  }
  if (
    -(
      cards[cards.length - 1].offset +
      centerCard(cards[cards.length - 1], hasTouch)
    ) > offset.current
  ) {
    offset.current = -(
      cards[cards.length - 1].offset +
      centerCard(cards[cards.length - 1], hasTouch)
    )
    closestCard.current = cards[cards.length - 1]
  }

  setSpring.start(() => ({
    offset: offset.current,
    down,
    config: {
      duration: undefined,
    },
  }))
}
