import create from 'zustand'

export type DetailPageIdStore = {
  detailPageId: string | null
  setDetailPageId: (payload: string | null) => void
}
export const useDetailPageStore = create<DetailPageIdStore>((set) => ({
  detailPageId: null,
  setDetailPageId: (payload) => set(() => ({ detailPageId: payload })),
}))
export const getDetailPageId = (state: DetailPageIdStore) => state.detailPageId

export const getDetailPageString = (state: DetailPageIdStore) =>
  state.detailPageId || '-'
