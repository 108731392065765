import { SVGProps } from 'react'

const InfoIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C11.0335 2 10.25 2.7835 10.25 3.75C10.25 4.7165 11.0335 5.5 12 5.5C12.9665 5.5 13.75 4.7165 13.75 3.75C13.75 2.7835 12.9665 2 12 2ZM9 8.5C9 8.22386 9.22386 8 9.5 8H11H13V19H14.5C14.7761 19 15 19.2239 15 19.5V20.5C15 20.7761 14.7761 21 14.5 21H13H11H9.5C9.22386 21 9 20.7761 9 20.5V19.5C9 19.2239 9.22386 19 9.5 19H11V10H9.5C9.22386 10 9 9.77614 9 9.5V8.5Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default InfoIcon
