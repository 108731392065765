export const getLanguage = (locale?: string) => {
  switch (locale) {
    case 'ch-en':
      return 'en'
    case 'ch-fr':
      return 'fr'
    case 'ch-de':
      return 'de'
    default:
      return 'de'
  }
}
