import { BUILDING_TARGET } from '../lib/static-values'
import { Vector3 } from 'three'
import { useCallback, useMemo, useRef } from 'react'
import { Card, Room } from '../types/types'
import { calcYOffset } from '../lib/calc-offset'
import { getWindowSize, useWindowSizeStore } from './store/use-window-size'
import { useActiveCardStore } from './store/use-active-card'
import { useRoomsStore } from './store/use-rooms'
import shallow from 'zustand/shallow'
import { getHasTouch, useHasTouchStore } from './store/use-has-touch'

const multiplier = 0.5
const newTarget = new Vector3()

const calcNextCoords: (
  card: Card | null | undefined,
  rooms: Room[],
) => Vector3 | null = (card, rooms) => {
  if (card && card.type === 'building') {
    const mainRoom = rooms.find(
      (entry) => card.roomTarget && card.roomTarget === entry.id,
    )
    if (mainRoom) {
      return mainRoom && mainRoom.coords ? mainRoom.coords.clone() : null
    }
  }
  if (card && card.floor !== null) {
    const floor = rooms.filter(
      (room) =>
        room.building === card.building &&
        room.floor === card.floor &&
        room.coords !== null,
    )
    const accFloorCoords = floor.length
      ? floor.reduce((acc, room) => {
          if (room.coords !== null) {
            acc.add(room.coords)
          }
          return acc
        }, new Vector3() as Vector3)
      : null
    if (accFloorCoords !== null && floor.length) {
      return accFloorCoords.divideScalar(floor.length).clone()
    }
  }
  return BUILDING_TARGET.clone()
}
const nextTarget = { coords: new Vector3(), hasTarget: true }

const findActiveRoom = (activeCard: Card | null, rooms: Room[]) => {
  return (
    rooms.find(
      (room) =>
        activeCard &&
        (room.id === activeCard.roomTarget ||
          (activeCard.rooms && activeCard.rooms.find((id) => room.id === id))),
    ) || null
  )
}

export const useNextTarget = () => {
  const isInitial = useRef(true)
  const { height } = useWindowSizeStore(getWindowSize)
  const { activeCard, isBuilding } = useActiveCardStore(
    useCallback(
      (state) => ({
        activeCard: state.activeCard,
        isBuilding: state.activeCard && state.activeCard.type === 'building',
      }),
      [],
    ),
    shallow,
  )
  const { calculatedCoords, activeRoom } = useRoomsStore(
    useCallback(
      (state) => ({
        calculatedCoords: calcNextCoords(activeCard, state.rooms),
        activeRoom: findActiveRoom(activeCard, state.rooms),
      }),
      [activeCard],
    ),
    shallow,
  )
  const hasTouch = useHasTouchStore(getHasTouch)

  return useMemo(() => {
    const shouldUpdate = activeCard?.type !== 'action_card'

    if (!shouldUpdate || activeRoom?.coords === null) {
      return newTarget
    }

    isInitial.current &&
      activeRoom &&
      activeRoom.coords &&
      newTarget.copy(activeRoom.coords)

    isInitial.current = false

    calculatedCoords && nextTarget.coords.copy(calculatedCoords)
    nextTarget.hasTarget = !!calculatedCoords

    if (!activeRoom || !activeRoom.coords || !nextTarget.hasTarget) {
      newTarget.copy(
        BUILDING_TARGET.clone().sub(
          new Vector3(
            0,
            calcYOffset({ touch: 100, desktop: 30 }, hasTouch, height),
            0,
          ),
        ),
      )
      return newTarget
    }

    newTarget.copy(activeRoom.coords)

    newTarget.lerp(nextTarget.coords, multiplier)

    newTarget.sub(
      new Vector3(
        0,
        isBuilding
          ? calcYOffset({ touch: -50, desktop: 0 }, hasTouch, height)
          : calcYOffset({ touch: -25, desktop: -5 }, hasTouch, height),
        0,
      ),
    )
    return newTarget
  }, [height, hasTouch, activeCard, isBuilding, calculatedCoords, activeRoom])
}
