import create from 'zustand'

export type WindowSizeStore = {
  windowSize: { width: number; height: number }
  setWindowSize: (payload: { width: number; height: number }) => void
}
export const useWindowSizeStore = create<WindowSizeStore>((set) => ({
  windowSize: { width: 0, height: 0 },
  setWindowSize: (payload) => set(() => ({ windowSize: payload })),
}))

export const getWindowSize = (state: WindowSizeStore) => {
  return state.windowSize
}
