import { RippleEffect } from '../ripple/ripple'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

export const NavListButton = ({
  icon,
  startIcon,
  isLast,
  title,
  ...props
}: {
  icon?: JSX.Element
  startIcon?: JSX.Element
  isLast?: boolean
  title: string
} & DetailedHTMLProps<
  HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  return (
    <button
      className={`relative flex justify-between item-center w-full px-3 pt-3.5 pb-3.5 bg-bg-800 desktop:hover:bg-mono-600  text-l ${
        isLast ? '' : 'mb-0.5'
      } items-center`}
      {...props}
    >
      {startIcon && <div className="mr-2.5">{startIcon}</div>}
      <div className="flex-grow text-left" style={{ flexBasis: '100%' }}>
        {title}
      </div>
      <div className="flex-grow">{icon && icon}</div>
      <RippleEffect className="left-0 top-0 w-full h-full" absolute />
    </button>
  )
}
