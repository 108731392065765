import create from 'zustand'

export type MoserIndexStore = {
  moserIndex: number
  setMoserIndex: (payload: number) => void
}
export const useMoserIndexStore = create<MoserIndexStore>((set) => ({
  moserIndex: -1,
  setMoserIndex: (payload) => set(() => ({ moserIndex: payload })),
}))

export const getMoserIndex = (state: MoserIndexStore) => state.moserIndex
