import { Color, Mesh, Object3D } from 'three'
import colors from '../data/colors'
import { defaultMaterial, MeshData } from '../components/model/render-map'
import { Room } from '../types/types'

const splitStairName = (mesh: Mesh | Object3D) => {
  const isLower = mesh.name.match(/-lowerstairs/)
  const isUpper = mesh.name.match(/-upperstairs/)
  const matchName = !!isLower || !!isUpper
  return matchName
    ? mesh.name.split(isLower ? /-lowerstairs/ : /-upperstairs/)[0]
    : ''
}

const isStair = (value: Object3D | null) => {
  const findName =
    value &&
    (value.name.match(/-lowerstairs/) || value.name.match(/-upperstairs/))
  return !!findName || false
}

export const isUpperStair = (mesh: Mesh | Object3D) => {
  const isUpper = mesh.name.match(/-upperstairs/)
  return !!isUpper
}

export type Stairs = { lowerStairs: MeshData[]; upperStairs: MeshData[] }

export const getMap = (children: (Mesh | Object3D)[], rooms: Room[]) => {
  const stairs: Stairs = {
    lowerStairs: [],
    upperStairs: [],
  }
  const meshes: MeshData[] = []

  children.forEach((value) => {
    const mayIsStair = isStair(value)
    const roomName = mayIsStair ? splitStairName(value) : value.name
    const roomEntry = rooms.find((item) => item.room === roomName)

    if (!roomEntry) {
      return
    }

    if (mayIsStair && value.children.length) {
      const material = defaultMaterial.clone()
      material.color = new Color(colors.section9[100])
      const mayIsUpperStair = isUpperStair(value)
      stairs[mayIsUpperStair ? 'upperStairs' : 'lowerStairs'].push({
        mesh: value,
        room: roomEntry,
        material: material,
      })
      return
    }
    if ((value as Mesh).geometry && value.parent?.name === 'Scene') {
      const material = defaultMaterial.clone()
      if (roomEntry.color) {
        material.color = new Color().fromArray(roomEntry.color)
      }
      roomEntry.coords = value.position
      meshes.push({
        mesh: value,
        room: roomEntry,
        material: material,
      })
    }
    return
  })
  return { stairs, meshes, rooms }
}
