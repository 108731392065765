import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { Box3, Color, Group, Mesh, MeshPhongMaterial, Object3D } from 'three'
import { Room } from '../../types/types'
import { GroupProps, ThreeEvent } from '@react-three/fiber'
import { RenderMesh } from './render-mesh'
import colors from '../../data/colors'
import { RenderStair } from './render-stair'
import { getRooms, useRoomsStore } from '../../hooks/store/use-rooms'
import { getCards, useCardsStore } from '../../hooks/store/use-cards'
import { useIsReadyStore } from '../../hooks/store/use-is-ready'
import { getMap } from '../../lib/gltf-loader'

type GLTFResult = GLTF & {
  nodes: { [key: string]: Mesh }
  materials: {
    [key: string]: MeshPhongMaterial
  }
}

export type MeshData = {
  mesh: Object3D | Mesh
  room: Room | undefined
  material: MeshPhongMaterial
}

export const defaultMaterial = new MeshPhongMaterial({
  color: new Color(colors.section9[200]),
  transparent: false,
  opacity: 1,
})

export const disabledColor = new Color(colors.section10[200])
export const disabledColorSecondary = new Color(colors.section10[100])

export const RenderMap = ({
  url,
  groupProps,
  handleRoomClick,
  boundingBox,
}: {
  url: string
  groupProps?: GroupProps
  handleRoomClick: (id: string) => void
  boundingBox: MutableRefObject<Box3 | null>
}) => {
  const rooms = useRoomsStore(getRooms)
  const cards = useCardsStore(getCards)
  const setRooms = useRoomsStore(useCallback((state) => state.setRooms, []))
  const setIsReady = useIsReadyStore(
    useCallback((state) => state.setIsReady, []),
  )
  const group = useRef<Group>()
  const { nodes } = useGLTF(url) as GLTFResult

  const {
    stairs: { lowerStairs, upperStairs },
    meshes,
  } = useMemo(() => getMap(Object.values(nodes), rooms), [nodes, rooms])

  const hasUpperStairs = useCallback(
    (room: Room | undefined) => {
      if (!room) {
        return false
      }
      const floorHasUpperStairs = upperStairs.find(
        (stair) =>
          stair.room &&
          stair.room.floor === room.floor &&
          stair.room.building === room.building,
      )
      return !!floorHasUpperStairs
    },
    [upperStairs],
  )

  useEffect(() => {
    setRooms([...rooms])
    setIsReady(true)

    // make sure it will only be called once
    if (!group.current) {
      return
    }
    boundingBox.current = new Box3()
    boundingBox.current.setFromObject(group.current)
  }, [nodes])

  const handlePointerUP = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      //Make sure its called only on the closest object
      if (e.object !== e.intersections[0].object) {
        return
      }
      const mesh = e.object
      if (!mesh) {
        return
      }
      const room = rooms.find((item) => item.room === mesh.name)
      if (!room) {
        return
      }
      const foundCard = cards.find(({ rooms: cardRooms, roomTarget }) => {
        if (roomTarget === room.id) {
          return true
        }
        return cardRooms?.find((id) => id === room.id)
      })
      if (!foundCard) {
        return
      }
      handleRoomClick(foundCard.id)
    },
    [rooms, handleRoomClick],
  )

  return (
    <group ref={group} {...groupProps} onPointerUp={handlePointerUP}>
      {meshes.map(({ mesh, room, material }) => {
        return (
          <RenderMesh
            mesh={mesh as Mesh}
            material={material}
            key={mesh.uuid}
            room={room}
            hasUpperStairs={hasUpperStairs(room)}
          />
        )
      })}

      {lowerStairs.length &&
        lowerStairs.map(({ mesh, room, material }) => {
          if (!room) {
            return null
          }
          const maxStairs = mesh.children.length
          return (
            <group
              key={mesh.uuid}
              position={mesh.position}
              rotation={mesh.rotation}
              scale={mesh.scale}
            >
              {maxStairs &&
                mesh.children.map((stair, index) => (
                  <RenderStair
                    room={room}
                    mesh={stair as Mesh}
                    material={material.clone()}
                    index={index}
                    key={stair.uuid}
                    maxStairs={maxStairs}
                    hasUpperStairs={hasUpperStairs(room)}
                  />
                ))}
            </group>
          )
        })}
      {upperStairs.length &&
        upperStairs.map(({ mesh, room, material }) => {
          if (!room) {
            return null
          }
          const maxStairs = mesh.children.length
          return (
            <group
              key={mesh.uuid}
              position={mesh.position}
              rotation={mesh.rotation}
              scale={mesh.scale}
            >
              {maxStairs &&
                mesh.children.map((stair, index) => (
                  <RenderStair
                    room={room}
                    mesh={stair as Mesh}
                    material={material.clone()}
                    maxStairs={maxStairs}
                    key={stair.uuid}
                    index={index}
                    isUpperStair
                  />
                ))}
            </group>
          )
        })}
    </group>
  )
}
