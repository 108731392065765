import { useCallback, useEffect } from 'react'
import { getWindowSize, useWindowSizeStore } from './store/use-window-size'
import { useHasTouchStore } from './store/use-has-touch'
import { useBreakPointsStore } from './store/use-break-points'
import { useIsFontReadyStore } from './store/use-is-font-ready'

export const useBreakPoints = () => {
  const { width } = useWindowSizeStore(getWindowSize)
  const setHasTouch = useHasTouchStore(
    useCallback((state) => state.setHasTouch, []),
  )
  const setBreakPoints = useBreakPointsStore(
    useCallback((state) => state.setBreakPoints, []),
  )
  const setIsFontReady = useIsFontReadyStore(
    useCallback((state) => state.setIsFontReady, []),
  )

  useEffect(() => {
    const fonts = async () => {
      return await Promise.all([(document as any).fonts]).then(([r]) => {
        setIsFontReady(r.status === 'loaded')
      })
    }
    fonts()
  })

  useEffect(() => {
    setHasTouch('ontouchstart' in window)
  }, [])

  useEffect(() => {
    const calcBreakPoints = () => {
      switch (true) {
        case width >= 1024:
          return 'lg'
        case width >= 768:
          return 'md'
        default:
          return 'sm'
      }
    }
    setBreakPoints(calcBreakPoints())
  }, [width])
}
