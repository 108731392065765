import { useRef } from 'react'
import { animated, useSpring } from 'react-spring'

export const UsefulInfosCollapsible = ({
  isCollapsed,
  text,
}: {
  isCollapsed: boolean
  text: string
}) => {
  const collapsibleWrapper = useRef<HTMLDivElement | null>(null)

  const { scale } = useSpring({
    scale: isCollapsed ? 0 : 1,
  })

  return (
    <animated.div
      className="overflow-hidden"
      style={{
        height: scale.to(
          (s) => `${(collapsibleWrapper.current?.offsetHeight || 0) * s}px`,
        ),
      }}
    >
      <animated.div
        ref={collapsibleWrapper}
        className="pt-6"
        style={{ display: scale.to((s) => (s === 0 ? 'none' : 'block')) }}
      >
        <p
          className="text-l text-typo-200 pb-8 px-3 usefulInfos"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </animated.div>
    </animated.div>
  )
}
