import { SVGProps } from 'react'

const EntranceIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49992 3C6.6715 3 5.99992 3.67157 5.99992 4.5V8.5C5.99992 8.77614 6.22378 9 6.49992 9H7.49992C7.77607 9 7.99992 8.77614 7.99992 8.5V5H16.9999V19H7.99992V15.5C7.99992 15.2239 7.77607 15 7.49992 15H6.49992C6.22378 15 5.99992 15.2239 5.99992 15.5V19.5C5.99992 20.3284 6.6715 21 7.49992 21H17.4999C18.3284 21 18.9999 20.3284 18.9999 19.5V4.5C18.9999 3.67157 18.3284 3 17.4999 3H7.49992ZM15.0605 11.6464C15.2558 11.8417 15.2558 12.1583 15.0605 12.3536L11.0605 16.3536C10.8652 16.5488 10.5487 16.5488 10.3534 16.3536L9.6463 15.6464C9.45103 15.4512 9.45103 15.1346 9.6463 14.9393L11.5857 12.9999H3.5C3.22386 12.9999 3 12.7761 3 12.4999V11.4999C3 11.2238 3.22386 10.9999 3.5 10.9999H11.5856L9.6463 9.06066C9.45103 8.8654 9.45103 8.54882 9.6463 8.35355L10.3534 7.64645C10.5487 7.45118 10.8652 7.45118 11.0605 7.64645L15.0605 11.6464Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default EntranceIcon
