import { useFrame, useThree } from '@react-three/fiber'
import { Vector3 } from 'three'
import { useCallback, useRef } from 'react'
import { toScreenPos } from '../lib/to-screen-pos'
import ThreeStore from '../lib/store'
import { collectSimilarCards } from '../lib/collect-similar-cards'
import { useEndPointsStore } from '../hooks/store/use-end-points'
import { useHoveredEndPointsStore } from '../hooks/store/use-hovered-end-points'
import { getIsReady, useIsReadyStore } from '../hooks/store/use-is-ready'
import { getHover, useHoverStore } from '../hooks/store/use-hover'
import {
  getActiveRoom,
  useActiveRoomStore,
} from '../hooks/store/use-active-room'
import { getRooms, useRoomsStore } from '../hooks/store/use-rooms'

export const ScreenPosition = () => {
  const setEndPoints = useEndPointsStore(
    useCallback((state) => state.setEndPoints, []),
  )
  const setHoveredEndPoints = useHoveredEndPointsStore(
    useCallback((state) => state.setHoveredEndPoints, []),
  )
  const isReady = useIsReadyStore(getIsReady)
  const hoveredCard = useHoverStore(getHover)
  const activeRoom = useActiveRoomStore(getActiveRoom)
  const rooms = useRoomsStore(getRooms)

  const lastCameraPos = useRef<Vector3>(new Vector3(0, 0, 0))
  const lastHoveredCard = useRef(hoveredCard)
  const currentLastCard = useRef(ThreeStore.lastCard)
  const lastIsReady = useRef(false)
  const lastWidth = useRef(0)
  const lastHeight = useRef(0)

  const {
    size: { width, height },
    camera,
  } = useThree()

  useFrame(() => {
    const { lastCard } = ThreeStore
    if (
      camera.position.distanceTo(lastCameraPos.current) === 0 &&
      lastHoveredCard.current === hoveredCard &&
      currentLastCard.current === lastCard &&
      lastIsReady.current === isReady &&
      lastWidth.current === width &&
      lastHeight.current === height
    ) {
      return
    }
    lastWidth.current = width
    lastHeight.current = height
    currentLastCard.current = lastCard

    lastIsReady.current = isReady

    const hoveredRoom = collectSimilarCards(rooms, hoveredCard)
    const offset = activeRoom?.room.match(/sculpture/) ? 10 : 0

    const coords = activeRoom ? activeRoom.coords : null

    const activeScreenCoords = toScreenPos(width, height, camera, coords)
    const hoveredScreenCoords = toScreenPos(
      width,
      height,
      camera,
      hoveredRoom ? hoveredRoom.coords : null,
    )

    setEndPoints({
      x: activeScreenCoords?.x || 0,
      y: activeScreenCoords ? activeScreenCoords.y + offset : 0,
    })

    setHoveredEndPoints({
      x: hoveredScreenCoords?.x || 0,
      y: hoveredScreenCoords ? hoveredScreenCoords.y + offset : 0,
    })

    lastHoveredCard.current = hoveredCard

    lastCameraPos.current = camera.position.clone()
  })

  return null
}
