export const BulletList = ({
  title,
  list,
}: {
  title: string
  list: string[]
}) => {
  return (
    <div className="flex w-full justify-start w-full py-6 border-t border-mono-700">
      <div className="text-base text-typo-100  w-[136px]">
        <p>{title}</p>
      </div>
      <ul className="text-typo-200 list-disc  w-[210px]">
        {list.length
          ? list.map((item, index) => {
              return <li key={`bulletList_${item}_${index}`}>{item}</li>
            })
          : null}
      </ul>
    </div>
  )
}
