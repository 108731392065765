import { SVGProps } from 'react'

const LikeOutlineIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.55 20.7052L12 22L13.45 20.715L13.4895 20.6798C18.6174 16.1088 22 13.0935 22 9.3951C22 6.37384 19.58 4 16.5 4C15.24 4 14.2142 4.70466 13.3551 5.54006C12.9282 5.95508 12.5425 6.40237 12.1897 6.81156C12.1254 6.88613 12.0622 6.95944 12 7.03106C11.9378 6.95944 11.8746 6.88613 11.8103 6.81156C11.4575 6.40237 11.0718 5.95508 10.6449 5.54006C9.78579 4.70466 8.75997 4 7.5 4C4.42 4 2 6.37384 2 9.3951C2 13.1 5.39442 16.1193 10.5373 20.6939L10.55 20.7052ZM12.1212 19.2202C14.7365 16.8889 16.7428 15.0962 18.1198 13.4437C19.4753 11.8169 20 10.5794 20 9.3951C20 7.51455 18.5119 6 16.5 6C16.1303 6 15.7126 6.15556 15.1707 6.59632C14.6624 7.00965 14.2174 7.52448 13.7029 8.11969C13.6398 8.19266 13.5757 8.26683 13.5103 8.34218L12 10.0819L10.4897 8.34218C10.4243 8.26683 10.3602 8.19266 10.2971 8.11969C9.78259 7.52448 9.33756 7.00965 8.82933 6.59632C8.28738 6.15556 7.86966 6 7.5 6C5.48808 6 4 7.51455 4 9.3951C4 10.5794 4.52467 11.8167 5.87981 13.4419C7.25667 15.0932 9.2634 16.884 11.8792 19.2108L11.8821 19.2134L12.0051 19.3232L12.1192 19.222C12.1199 19.2214 12.1205 19.2208 12.1212 19.2202Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default LikeOutlineIcon
