import { FloorData } from '../../types/types'
import create from 'zustand'

export type FloorsStore = {
  floors: FloorData[]
  setFloors: (payload: FloorData[]) => void
}
export const useFloorsStore = create<FloorsStore>((set) => ({
  floors: [],
  setFloors: (payload) => set(() => ({ floors: payload })),
}))

export const getFloors = (state: FloorsStore) => state.floors
