import { NavListLink } from '../menu/nav-list-link'
import ArrowForwardIcon from '../icons/arrow-forward-icon'
import CopyIcon from '../icons/copy-icon'
import MailIcon from '../icons/mail-icon'
import { useMemo, useRef, useState } from 'react'
import { NavListButton } from '../menu/nav-list-button'
import { animated, useSpring } from 'react-spring'
import {
  getTranslations,
  useTranslationsStore,
} from '../../hooks/store/use-translations'

export const Share = ({
  subject,
  body,
  url,
  isCollapsed,
}: {
  subject: string
  body: string
  url: string
  isCollapsed: boolean
}) => {
  const shareWrapper = useRef<HTMLDivElement | null>(null)
  const translations = useTranslationsStore(getTranslations)

  const shareByMail = useMemo(() => {
    return translations.find(({ id }) => id === '54206818')?.text || ''
  }, [translations])

  const copyText = useMemo(() => {
    return translations.find(({ id }) => id === '54206833')?.text || ''
  }, [translations])

  const successCopied = useMemo(() => {
    return translations.find(({ id }) => id === '54303345')?.text || ''
  }, [translations])

  const [isCopied, setIsCopied] = useState(false)

  const handleCopyTo = () => {
    navigator.clipboard.writeText(url).then(() => setIsCopied(true))
  }

  const { scale } = useSpring({
    scale: isCollapsed ? 0 : 1,
  })

  return (
    <animated.div
      className="overflow-hidden"
      style={{
        height: scale.to(
          (s) => `${(shareWrapper.current?.offsetHeight || 0) * s}px`,
        ),
      }}
    >
      <animated.div
        ref={shareWrapper}
        className="pt-6"
        style={{ display: scale.to((s) => (s === 0 ? 'none' : 'block')) }}
      >
        <NavListButton
          title={isCopied ? successCopied : copyText}
          icon={<ArrowForwardIcon />}
          startIcon={<CopyIcon />}
          onClick={handleCopyTo}
        />
        <NavListLink
          title={shareByMail}
          icon={<ArrowForwardIcon />}
          startIcon={<MailIcon />}
          href={`mailto:?subject=${subject}&body=${body}%0D%0A%0D%0A${url}`}
          isLast
        />
      </animated.div>
    </animated.div>
  )
}
