import create from 'zustand'

export type IsClientStore = {
  isClient: boolean
  setIsClient: (payload: boolean) => void
}
export const useIsClientStore = create<IsClientStore>((set) => ({
  isClient: false,
  setIsClient: (payload) => set(() => ({ isClient: payload })),
}))

export const getIsClient = (state: IsClientStore) => state.isClient
