import { Card, FloorData } from '../../types/types'
import { MutableRefObject, useCallback, useRef } from 'react'
import { FloorIndicatorMenu } from '../floor-indicator/floor-indicator-menu'
import { RippleEffect } from '../ripple/ripple'
import { useSpring } from '@react-spring/three'
import { animated } from 'react-spring'
import { ANIMATION_DURATION } from '../../lib/static-values'
import shallow from 'zustand/shallow'
import {
  CardsStore,
  getCards,
  useCardsStore,
} from '../../hooks/store/use-cards'
import { OffsetStore, useOffsetStore } from '../../hooks/store/use-offset'
import {
  getWindowSize,
  useWindowSizeStore,
} from '../../hooks/store/use-window-size'

const useCardOffset = (cardId: string) => {
  const getEndCard = useCallback((state: CardsStore) => {
    const card = state.cards.find(({ id }) => id === cardId)
    return {
      offset: card?.offset || 0,
      width: card?.width || 0,
      height: card?.height || 0,
    }
  }, [])
  return useCardsStore(getEndCard, shallow)
}

const useOffset = (
  floor: FloorData,
  ref: MutableRefObject<HTMLDivElement | null>,
  wrapper: MutableRefObject<HTMLDivElement | null>,
  hasTouch: boolean,
  cards: Card[],
) => {
  const getOffset = useCallback(
    (state: OffsetStore) => {
      if (!ref.current || !wrapper.current) {
        return 0
      }
      const startCard = cards.find(({ id }) => id === floor.start)
      const endCard = cards.find(({ id }) => id === floor.end)
      if (!endCard || !startCard) {
        return 0
      }
      const halfWindowSize =
        (hasTouch
          ? wrapper.current.offsetWidth
          : wrapper.current.offsetHeight) / 2

      const offset = -state.offset - startCard.offset - halfWindowSize

      const max =
        endCard.offset -
        startCard.offset +
        (hasTouch
          ? endCard.width - ref.current.offsetWidth
          : endCard.height - ref.current.offsetWidth)

      if (offset < 0) {
        return 0
      }
      if (offset > max) {
        return max
      }
      return offset
    },
    [cards, hasTouch],
  )
  return useOffsetStore(getOffset)
}

const useShouldFlip = (hasTouch: boolean, cards: Card[], floor: FloorData) => {
  const { width, height } = useWindowSizeStore(getWindowSize)
  const calcOffset = useCallback(
    (state: OffsetStore) => {
      const startCard = cards.find(({ id }) => id === floor.start)
      const offset = state.offset + (startCard?.offset || 0)
      const isAtBottom = offset + 300 > height / 2
      const isAtRight = offset + 244 > width / 2
      return hasTouch ? isAtRight : isAtBottom
    },
    [hasTouch, cards, width, height],
  )
  return useOffsetStore(calcOffset)
}

export const FloorIndicator = ({
  wrapper,
  hasTouch,
  floor,
  activeIndex,
  index,
  setActiveIndex,
}: {
  wrapper: MutableRefObject<HTMLDivElement | null>
  floor: FloorData
  hasTouch: boolean
  activeIndex: number
  index: number
  setActiveIndex: (index: number) => void
}) => {
  const divRef = useRef<HTMLDivElement | null>(null)
  const floorRef = useRef<HTMLDivElement | null>(null)
  const cards = useCardsStore(getCards)

  const startCard = useCardOffset(floor.start)

  const offset = useOffset(floor, floorRef, wrapper, hasTouch, cards)

  const shouldFlip = useShouldFlip(hasTouch, cards, floor)

  const { opacity } = useSpring({
    opacity: activeIndex === index ? 1 : 0,
    config: {
      duration: ANIMATION_DURATION,
    },
  })

  const AnimatedFloorIndicatorMenu = animated(FloorIndicatorMenu)

  return (
    <div
      ref={divRef}
      className="z-50 flex items-center absolute origin-top-left left-0 h-[36px] top-0 sm:top-[-6px]"
      style={{
        left: hasTouch ? startCard.offset : undefined,
        top: hasTouch ? undefined : startCard.offset,

        transform: hasTouch
          ? `translate3d(${offset}px, 0, 0)`
          : `translate3d(4px, ${offset}px, 0) rotate3d(0, 0, 1, -90deg)`,
      }}
    >
      <div
        style={
          hasTouch
            ? {}
            : {
                transform: 'translate3d(-100%, 0, 0)',
              }
        }
      >
        <div
          className={`relative ${
            floor.building === 'Chipperfield'
              ? hasTouch
                ? 'pl-4.5'
                : 'pr-4.5'
              : hasTouch
              ? 'pr-4.5'
              : 'pl-4.5'
          }`}
          ref={floorRef}
        >
          <button
            onClick={() => setActiveIndex(index === activeIndex ? -1 : index)}
            className="whitespace-nowrap flex items-center pt-[10px] pb-[8px] left-0 pl-4.5 desktop:pl-4.5 pr-4.5 desktop:pr-4.5 rounded-l-[20px] desktop:rounded-l-[20px] rounded-r-[20px] desktop:rounded-r-[20px] bg-bg-800 hover:bg-mono-600 disabled:bg-mono-700 text-typo-100 disabled:text-typo-300 cursor-pointer overflow-hidden"
          >
            <RippleEffect>
              <span className="font-normal text-lg relative leading-5.5">
                {floor.name}
              </span>
            </RippleEffect>
          </button>
          {activeIndex === index && (
            <AnimatedFloorIndicatorMenu
              floor={floor}
              handleFloorClick={() => {
                setActiveIndex(-1)
              }}
              className={hasTouch || shouldFlip ? 'items-end' : 'items-start'}
              style={{
                transformOrigin: shouldFlip ? 'bottom left' : 'top right',
                transform: hasTouch
                  ? undefined
                  : `translate3d(0, ${
                      shouldFlip ? -100 : 100
                    }%, 0) rotate3d(0, 0, 1, 90deg)`,
                top: hasTouch ? -270 : shouldFlip ? -250 : -277,
                left: shouldFlip
                  ? hasTouch
                    ? 'auto'
                    : floor.building === 'Chipperfield'
                    ? 0
                    : 18
                  : hasTouch
                  ? floor.building === 'Chipperfield'
                    ? 18
                    : 0
                  : 'auto',
                right: shouldFlip
                  ? hasTouch
                    ? floor.building === 'Chipperfield'
                      ? 0
                      : 18
                    : 'auto'
                  : hasTouch
                  ? 'auto'
                  : floor.building === 'Chipperfield'
                  ? 18
                  : 0,
                opacity: opacity,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
