import { Card } from '../types/types'
import { MAX_DISTANCE, MIN_DISTANCE } from './static-values'

export const calcDistance = (activeCard: Card | null) => {
  if (activeCard && activeCard.type === 'building') {
    return 200
  }
  if (!activeCard) {
    return MAX_DISTANCE
  }
  return MIN_DISTANCE
}
