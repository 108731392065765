import { useCallback, useEffect } from 'react'
import { getIsClient, useIsClientStore } from './store/use-is-client'
import { useWindowSizeStore } from './store/use-window-size'

export const useWindowSize = () => {
  const isClient = useIsClientStore(getIsClient)
  const setWindowSize = useWindowSizeStore(
    useCallback((state) => state.setWindowSize, []),
  )

  const changeWindowSize = useCallback(() => {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches

    !isPWA &&
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }, [])

  const changeOrientation = useCallback(() => {
    const isPortrait = window.matchMedia('(orientation: portrait)').matches
    const isPWA = window.matchMedia('(display-mode: standalone)').matches

    if (!isPortrait && isPWA) {
      setWindowSize({
        width: window.screen.height,
        height: window.screen.width,
      })
      return
    }
    if (isPortrait && isPWA) {
      setWindowSize({
        width: window.screen.width,
        height: window.screen.height,
      })
      return
    }
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }, [isClient])

  useEffect(() => {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches

    changeWindowSize()
    isPWA && changeOrientation()
    window.addEventListener('resize', changeWindowSize)

    window.addEventListener('orientationchange', changeOrientation)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
      window.removeEventListener('orientationchange', changeOrientation)
    }
  }, [changeWindowSize])

  return isClient
}
