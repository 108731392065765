import { DetailedHTMLProps, HTMLAttributes } from 'react'

export const Youtube = ({
  id,
  title,
  ...props
}: { id: string; title: string } & DetailedHTMLProps<
  HTMLAttributes<HTMLIFrameElement>,
  HTMLIFrameElement
>) => {
  return (
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${id}`}
      title={title}
      frameBorder="0"
      allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className={`w-full h-full absolute top-0 left-0`}
      {...props}
    />
  )
}
