import {
  HTMLProps,
  memo,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import Link from 'next/link'
import { Image } from 'react-datocms'
import { Card as CardType, Floor } from '../../types/types'
import colors from '../../data/colors'
import {
  CARD_HEIGHT,
  CARD_WIDTH,
  CARD_WIDTH_DESKTOP,
} from '../../lib/static-values'
import LikeOutlineIcon from '../icons/like-outline-icon'
import { animated, config, useSpring } from 'react-spring'
import LikeFilledIcon from '../icons/like-filled-icon'
import { RippleEffect } from '../ripple/ripple'
import { getColorSection } from '../../lib/get-color-section'
import {
  ActiveBuildingStore,
  ActiveCardStore,
  ActiveFloorStore,
  useActiveBuildingStore,
  useActiveCardStore,
  useActiveFloorStore,
} from '../../hooks/store/use-active-card'
import {
  FavoritesStore,
  getFavorites,
  getFavoritesString,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'
import {
  getTaggedString,
  useTaggedCardIdsStore,
} from '../../hooks/store/use-tagged-card-ids'
import {
  getActiveTagListString,
  useActiveTagListStore,
} from '../../hooks/store/use-active-tag-list'
import {
  getActiveTagString,
  useActiveTagStore,
} from '../../hooks/store/use-active-tag'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'
import { useHoverStore } from '../../hooks/store/use-hover'
import PlusIcon from '../icons/plus-icon'
import {
  getBreakPoints,
  useBreakPointsStore,
} from '../../hooks/store/use-break-points'

const useActiveCard = (cardId: string) => {
  const getIsActiveCard = useCallback(
    (state: ActiveCardStore) => {
      return state.activeCard?.id === cardId
    },
    [cardId],
  )
  return useActiveCardStore(getIsActiveCard)
}

const useActiveFloor = (cardFloor: Floor | null) => {
  const getIsActiveFloor = useCallback(
    (state: ActiveFloorStore) => {
      return (
        cardFloor !== null &&
        state.activeFloor !== null &&
        Math.floor(state.activeFloor) === Math.floor(cardFloor)
      )
    },
    [cardFloor],
  )
  return useActiveFloorStore(getIsActiveFloor)
}

const useActiveBuilding = (cardBuilding: string | null) => {
  const getIsActiveBuilding = useCallback(
    (state: ActiveBuildingStore) => {
      return state.activeBuilding === cardBuilding
    },
    [cardBuilding],
  )
  return useActiveBuildingStore(getIsActiveBuilding)
}

const useFavorite = (cardId: string) => {
  const getFavorite = useCallback(
    (state: FavoritesStore) => !!state.favorites.find((id) => id === cardId),
    [cardId],
  )
  return useFavoritesStore(getFavorite)
}

const useCardLink = (cardId: string) => {
  const favoritesString = useFavoritesStore(getFavoritesString)
  const taggedString = useTaggedCardIdsStore(getTaggedString)
  const detailPage = useActiveCardStore(
    useCallback(
      (state) => (state.activeCard?.id === cardId ? cardId : '-'),
      [cardId],
    ),
  )
  const activeTagListId = useActiveTagListStore(getActiveTagListString)
  const activeTagId = useActiveTagStore(getActiveTagString)
  const orbit = useOrbitStore(getOrbit)

  return useMemo(() => {
    return `${cardId}/${orbit}/${detailPage}/false/0/${favoritesString}/${activeTagListId}/${activeTagId}/${taggedString}`
  }, [
    orbit,
    detailPage,
    favoritesString,
    activeTagListId,
    activeTagId,
    taggedString,
    cardId,
  ])
}

export const Card = memo(
  ({
    card,
    hasTouch,
    cardRef,
    isAnimating,
    ...props
  }: HTMLProps<HTMLAnchorElement> & {
    card: CardType
    hasTouch: boolean
    cardRef: (card: HTMLDivElement | null) => void
    isAnimating: MutableRefObject<boolean>
  }) => {
    const { title, subtitle, img } = card
    const isComingSoon = title === 'Coming soon'
    const likeWrapRef = useRef<HTMLDivElement | null>(null)
    const isLikedRef = useRef<HTMLDivElement | null>(null)
    const isNotLikedRef = useRef<HTMLDivElement | null>(null)

    const isActiveFloor = useActiveFloor(card.floor)
    const isActiveBuilding = useActiveBuilding(card.building)

    const cardLink = useCardLink(card.id)

    const isActiveCard = useActiveCard(card.id)
    const favorites = useFavoritesStore(getFavorites)

    const breakPoints = useBreakPointsStore(getBreakPoints)
    const smallBreakPoint = breakPoints === 'sm'

    const setHoveredCard = useHoverStore(
      useCallback((state) => state.setHoveredCard, []),
    )

    const isFavorite = useFavorite(card.id)

    const setFavorites = useFavoritesStore(
      useCallback((state) => state.setFavorites, []),
    )

    const setNewFavorites = useCallback(() => {
      const foundFavorite = !!favorites.find((id) => id === card.id)
      if (foundFavorite) {
        setFavorites(favorites.filter((id) => id !== card.id))
        return
      }
      favorites.push(card.id)
      setFavorites([...favorites])
    }, [favorites])

    const isInactive = useMemo(() => {
      return card.isInactive || !isActiveFloor || !isActiveBuilding
    }, [isActiveFloor, isActiveBuilding, card])

    const [isHovered, setIsHovered] = useState(isActiveCard)

    useSpring({
      show: isHovered || isActiveCard || isFavorite ? 1 : 0,
      config: config.wobbly,
      onChange: ({ value: { show } }) => {
        if (!likeWrapRef.current) {
          return
        }
        const range = 0.6 + 0.4 * show
        const likeBtn = likeWrapRef.current
        likeBtn.style.transform = `scale3d(${range},${range},${range})`
        likeBtn.style.display = show === 0 ? 'none' : 'flex'
        likeBtn.style.opacity = show
      },
    })

    const showFavorite = useSpring({
      like: isFavorite ? 1 : 0,
    })

    const onFocus = () => {
      if (hasTouch) {
        return
      }
      if (!hasTouch && !isAnimating.current && !isInactive && !isActiveCard) {
        setHoveredCard(card)
      }
      setIsHovered(true)
    }

    const onBlur = () => {
      if (hasTouch) {
        return
      }
      setHoveredCard(null)
      setIsHovered(false)
    }

    return (
      <div
        ref={(ref) => {
          cardRef(ref)
        }}
        data-id={card.id}
        className="relative hyphens-auto"
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseEnter={onFocus}
        onMouseLeave={onBlur}
      >
        <Link href={cardLink} passHref shallow>
          <a
            {...props}
            className={`px-px desktop:px-0 desktop:py-px relative z-50 h-full desktop:h-auto desktop:min-h-[140px] flex ${
              isInactive ? 'text-typo-300' : 'text-typo-100'
            } ${props.className}`}
            style={{
              width: hasTouch ? `${CARD_WIDTH}px` : `${CARD_WIDTH_DESKTOP}px`,
              height: hasTouch ? `${CARD_HEIGHT}px` : undefined,
              ...props.style,
            }}
          >
            <div
              className={`absolute top-0 desktop:top-px left-px desktop:left-0 right-px desktop:right-0 bottom-0 desktop:bottom-px bg-bg-800 cursor-pointer ${
                isComingSoon ? 'bg-bg-800' : ''
              }`}
            >
              <RippleEffect className="w-full h-full" />
            </div>
            {img && img.responsiveImage && hasTouch && (
              <div className="absolute top-0 desktop:top-px bottom-0 desktop:bottom-px left-px desktop:left-0 right-px desktop:right-0 overflow-hidden pointer-events-none">
                <figure className="w-full h-full">
                  <Image
                    data={img.responsiveImage}
                    className="w-full h-full"
                    pictureClassName="w-full h-full object-cover"
                  />
                  <figcaption className="absolute opacity-0">
                    {img.responsiveImage.title}
                  </figcaption>
                </figure>
                {!isComingSoon && (
                  <div
                    className={`absolute left-0 top-0 w-full h-full z-10 mix-blend-multiply ${
                      isInactive
                        ? 'bg-dark'
                        : 'bg-gradient-to-t from-transparent to-dark'
                    } opacity-80`}
                    style={
                      !isInactive
                        ? {
                            //add correct alpha to hex
                            background: `linear-gradient(180deg, ${
                              colors.bg[900]
                            }, ${colors.bg[900] + '9e'} 57.29%, ${
                              colors.bg[900] + '00'
                            } 100%)`,
                          }
                        : {}
                    }
                  />
                )}
              </div>
            )}
            <div className="z-10 desktop:flex w-full pointer-events-none">
              {smallBreakPoint && isActiveCard && (
                <div
                  className={`absolute w-8 h-8 top-[-14px] right-2 rounded-full cursor-pointer flex justify-center items-center`}
                  style={{
                    backgroundColor: getColorSection(card, isInactive),
                  }}
                >
                  <PlusIcon />
                </div>
              )}
              <div
                className="w-full desktop:w-1 bg-white h-1 desktop:h-full flex-none"
                style={{
                  backgroundColor: getColorSection(card, isInactive),
                }}
              />
              <div className={'flex flex-col py-3 flex-grow'}>
                <div className="px-4 pb-0 font-black text-lg leading-none">
                  {title}
                </div>
                <div className="pl-4 pr-11 desktop:px-4 pt-1 text-sm font-normal leading-none min-h-[20px]">
                  {subtitle}
                </div>
              </div>
              <div className="absolute right-3 bottom-5 desktop:right-0 desktop:bottom-0 desktop:relative desktop:h-full desktop:w-[140px] flex items-center justify-center flex-shrink-0">
                {!hasTouch && img && img.responsiveImage && (
                  <>
                    <div className="absolute top-0 left-0 w-full h-full">
                      <Image
                        data={img.responsiveImage}
                        className="z-0 w-full h-full"
                      />
                    </div>
                    {!isComingSoon && isInactive && (
                      <div className="absolute left-0 top-0 w-full h-full mix-blend-multiply bg-dark opacity-80" />
                    )}
                  </>
                )}
              </div>
            </div>
          </a>
        </Link>
        <div
          className={`absolute right-0 bottom-0 desktop:bottom-5 desktop:top-0 desktop:right-0 cursor-pointer pointer-events-none z-[60] w-12 desktop:w-[140px] h-16 flex items-center justify-center desktop:h-full ${
            isFavorite || isActiveCard ? '' : 'hidden'
          }`}
          ref={likeWrapRef}
        >
          <button
            className="relative pointer-events-auto w-full h-full desktop:w-5 desktop:h-5"
            onClick={setNewFavorites}
            onFocus={onFocus}
          >
            <div
              ref={isNotLikedRef}
              className={`absolute desktop:relative left-3 desktop:left-0 right-0 bottom-5 desktop:bottom-0`}
            >
              <LikeOutlineIcon />
            </div>
            <animated.div
              className={`absolute desktop:relative left-3 desktop:left-0 right-0 bottom-5 desktop:bottom-6`}
              style={{ opacity: showFavorite.like.to((o) => o) }}
              ref={isLikedRef}
            >
              <LikeFilledIcon />
            </animated.div>
          </button>
        </div>
      </div>
    )
  },
  (prev, next) => {
    return prev.card === next.card
  },
)
