import create from 'zustand'

export type FavoriteRoomsStore = {
  favoriteRooms: string[]
  setFavoriteRooms: (payload: string[]) => void
}
export const useFavoriteRoomsStore = create<FavoriteRoomsStore>((set) => ({
  favoriteRooms: [],
  setFavoriteRooms: (payload) => set(() => ({ favoriteRooms: payload })),
}))

export const getFavoriteRooms = (state: FavoriteRoomsStore) =>
  state.favoriteRooms
