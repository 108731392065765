import { SVGProps } from 'react'

const ArrowRightLargeIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
      {...props}
    >
      <path
        fill="#F0F0F0"
        d="M21.5607 5.14645C21.3655 4.95118 21.0489 4.95118 20.8536 5.14645L20.1465 5.85355C19.9513 6.04882 19.9513 6.3654 20.1465 6.56066L30.5858 16.9999H1.5C1.22386 16.9999 1 17.2238 1 17.4999V18.4999C1 18.7761 1.22386 18.9999 1.5 18.9999H30.5859L20.1465 29.4393C19.9513 29.6346 19.9513 29.9512 20.1465 30.1464L20.8536 30.8536C21.0489 31.0488 21.3655 31.0488 21.5607 30.8536L34.0607 18.3536C34.256 18.1583 34.256 17.8417 34.0607 17.6464L21.5607 5.14645Z"
        {...pathProps}
      />
    </svg>
  )
}

export default ArrowRightLargeIcon
