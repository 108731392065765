import { Logo } from '../icons/logo'

export const LogoAnimation = () => {
  return (
    <div className="w-full h-full absolute bg-bg-900 z-50 top-0 left-0 justify-center items-center flex flex-col">
      <div className="relative w-14 md:w-32 lg:w-52 text-l logo-fadein">
        <Logo
          className="w-full h-auto text-white"
          filterId="animationFilterId_1"
        />

        <Logo
          filterId="animationFilterId_2"
          className="text-primary-400 absolute top-0 left-0 w-full h-full logo-animation"
          preserveAspectRatio="none"
          style={{
            animationDelay: '1500ms',
          }}
        />
        <Logo
          filterId="animationFilterId_3"
          className="text-section1-400 absolute top-0 left-0 w-full h-full logo-animation"
          preserveAspectRatio="none"
          style={{
            animationDelay: '1700ms',
          }}
        />
        <Logo
          filterId="animationFilterId_4"
          className="text-section2-400 absolute top-0 left-0 w-full h-full logo-animation"
          preserveAspectRatio="none"
          style={{
            animationDelay: '1900ms',
          }}
        />
        <Logo
          filterId="animationFilterId_5"
          className="text-section3-400 absolute top-0 left-0 w-full h-full logo-animation"
          preserveAspectRatio="none"
          style={{
            animationDelay: '2100ms',
          }}
        />
        <Logo
          filterId="animationFilterId_6"
          className="text-section4-400 absolute top-0 left-0 w-full h-full logo-animation"
          preserveAspectRatio="none"
          style={{
            animationDelay: '2300ms',
          }}
        />
        <Logo
          filterId="animationFilterId_7"
          className="text-white absolute top-0 left-0 w-full h-full logo-animation"
          preserveAspectRatio="none"
          style={{
            animationDelay: '2500ms',
          }}
        />
      </div>
    </div>
  )
}
