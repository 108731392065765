import { SVGProps } from 'react'

const AudioGuideIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C8.6517 4 6 6.60833 6 9.75V13H7.5C7.77614 13 8 13.2239 8 13.5L8 19.5C8 19.7761 7.77614 20 7.5 20H5.5H5H4.5C4.22386 20 4 19.7761 4 19.5V19H3.5C3.22386 19 3 18.7761 3 18.5V14.5C3 14.2239 3.22386 14 3.5 14H4L4 9.75C4 5.43583 7.61631 2 12 2C16.3837 2 20 5.43583 20 9.75V14H20.5C20.7761 14 21 14.2239 21 14.5V18.5C21 18.7761 20.7761 19 20.5 19H20V19.5C20 19.7761 19.7761 20 19.5 20H19H18.5H16.5C16.2239 20 16 19.7761 16 19.5L16 13.5C16 13.2239 16.2239 13 16.5 13H18V9.75C18 6.60833 15.3483 4 12 4Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default AudioGuideIcon
