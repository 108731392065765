import { Card } from '../../types/types'
import create from 'zustand'
import { emptyCard } from '../../lib/empty-card'

export type MoserCardStore = {
  moserCard: Card
  setMoserCard: (payload: Card) => void
}
export const useMoserCardStore = create<MoserCardStore>((set) => ({
  moserCard: emptyCard,
  setMoserCard: (payload) => set(() => ({ moserCard: payload })),
}))

export const getMoserCard = (state: MoserCardStore) => state.moserCard
