import { SVGProps } from 'react'

const ShareIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 9.49992C20 8.6715 19.3284 7.99992 18.5 7.99992L16.5 7.99992C16.2239 7.99992 16 8.22378 16 8.49992L16 9.49992C16 9.77607 16.2239 9.99992 16.5 9.99992L18 9.99992L18 19.9999L6 19.9999L6 9.99992L7.5 9.99992C7.77614 9.99992 8 9.77607 8 9.49992L8 8.49992C8 8.22378 7.77614 7.99992 7.5 7.99992L5.5 7.99992C4.67157 7.99992 4 8.6715 4 9.49992L4 20.4999C4 21.3283 4.67157 21.9999 5.5 21.9999L18.5 21.9999C19.3284 21.9999 20 21.3284 20 20.4999L20 9.49992ZM11.6464 1.14645C11.8417 0.951184 12.1583 0.951184 12.3536 1.14645L16.3536 5.14645C16.5488 5.34171 16.5488 5.65829 16.3536 5.85355L15.6464 6.56066C15.4512 6.75592 15.1346 6.75592 14.9393 6.56066L12.9999 4.62125L12.9999 13.707C12.9999 13.9831 12.7761 14.207 12.4999 14.207L11.4999 14.207C11.2238 14.207 10.9999 13.9831 10.9999 13.707L10.9999 4.6214L9.06066 6.56066C8.8654 6.75592 8.54881 6.75592 8.35355 6.56066L7.64645 5.85355C7.45118 5.65829 7.45118 5.34171 7.64645 5.14645L11.6464 1.14645Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default ShareIcon
