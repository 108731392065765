import { SVGProps } from 'react'

const ArrowDownIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`text-typo-100 ${props.className}`}
    >
      <path
        d="M5.05676 10.9432L4.35355 11.6464C4.15829 11.8417 4.15829 12.1583 4.35355 12.3536L11.6464 19.6464C11.8417 19.8417 12.1583 19.8417 12.3536 19.6464L19.6464 12.3536C19.8417 12.1583 19.8417 11.8417 19.6464 11.6464L18.9432 10.9432C18.7481 10.7481 18.4318 10.748 18.2364 10.9429L13 16.17L13 4.5C13 4.22386 12.7761 4 12.5 4L11.5 4C11.2239 4 11 4.22386 11 4.5L11 16.17L5.76355 10.9429C5.56824 10.748 5.2519 10.7481 5.05676 10.9432Z"
        fill="currentColor"
        {...pathProps}
      />
    </svg>
  )
}

export default ArrowDownIcon
