import { FloorData } from '../../types/types'
import { HTMLAttributes, useCallback, useMemo } from 'react'
import Link from 'next/link'
import { renderIcon } from '../icons/render-icons'
import { arrayToString } from '../../lib/array-to-string'
import { getCfIndex, useCfIndexStore } from '../../hooks/store/use-cf-index'
import {
  getMoserIndex,
  useMoserIndexStore,
} from '../../hooks/store/use-moser-index'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'
import { useActiveTagListStore } from '../../hooks/store/use-active-tag-list'
import { useActiveTagStore } from '../../hooks/store/use-active-tag'
import { useFavoritesStore } from '../../hooks/store/use-favorites'
import { useTaggedCardIdsStore } from '../../hooks/store/use-tagged-card-ids'
import {
  getActiveBuilding,
  getActiveFloor,
  useActiveBuildingStore,
  useActiveFloorStore,
} from '../../hooks/store/use-active-card'
import { getFloors, useFloorsStore } from '../../hooks/store/use-floors'
import {
  AllCardsStore,
  useAllCardsStore,
} from '../../hooks/store/use-all-cards'

const useBuildingsCard = () => {
  const cfIndex = useCfIndexStore(getCfIndex)
  const moserIndex = useMoserIndexStore(getMoserIndex)
  const getFirstCard = useCallback(
    (state: AllCardsStore) => {
      return [state.allCards[moserIndex - 1], state.allCards[cfIndex + 1]]
    },
    [cfIndex, moserIndex],
  )
  return useAllCardsStore(getFirstCard)
}

const useLink = () => {
  const favoritesString = useFavoritesStore((state) =>
    arrayToString(state.favorites),
  )
  const taggedString = useTaggedCardIdsStore((state) =>
    arrayToString(state.taggedCardIds),
  )
  const orbit = useOrbitStore(getOrbit)
  const activeTagLIstId = useActiveTagListStore(
    (state) => state.activeTagList?.id || '-',
  )
  const activeTagId = useActiveTagStore((state) => state.activeTag?.id || '-')
  return useCallback(
    (id: string) =>
      `${id}/${orbit}/-/false/0/${favoritesString}/${activeTagLIstId}/${activeTagId}/${taggedString}`,
    [orbit, favoritesString, activeTagLIstId, activeTagId, taggedString],
  )
}

const useOpenSpace = (floors: FloorData[]) => {
  return useMemo(() => {
    return floors.find((floor) => floor.floor === 10)
  }, [floors])
}

export const FloorIndicatorMenu = ({
  floor,
  handleFloorClick,
  ...props
}: {
  handleFloorClick: () => void
  floor: FloorData
} & HTMLAttributes<HTMLDivElement>) => {
  const link = useLink()
  const activeBuilding = useActiveBuildingStore(getActiveBuilding)
  const activeFloor = useActiveFloorStore(getActiveFloor)
  const floors = useFloorsStore(getFloors)
  const buildingCards = useBuildingsCard()
  const moserBuildingLink = useLink()(buildingCards[0].id)
  const cfBuildingLink = useLink()(buildingCards[1].id)
  const openSpaceLinkFn = useLink()
  const openSpaceFloor = useOpenSpace(floors)

  const openSpaceLink = useMemo(() => {
    return openSpaceFloor ? openSpaceLinkFn(openSpaceFloor.start) : ''
  }, [openSpaceFloor, openSpaceFloor])

  const filteredFloors = useMemo(
    () =>
      floors
        .filter((entry) => entry.building === floor.building)
        .filter((entry) => entry.floor % 1 === 0)
        .sort((floorA, floorB) => floorB.floor - floorA.floor),
    [floor, floors],
  )

  return (
    <div
      {...props}
      className={`z-50 flex absolute h-[244px] w-[218px] ${props.className}`}
      id={'floor-indicator-menu'}
    >
      <ul className="whitespace-nowrap flex flex-col items-start w-full py-1 left-0 pl-1 desktop:pl-1 pr-1 desktop:pr-1 rounded-l-[20px] desktop:rounded-l-[20px] rounded-r-[20px] desktop:rounded-r-[20px] bg-bg-800 text-typo-100">
        {activeFloor === 10 && openSpaceFloor && (
          <>
            <li className="font-normal text-lg flex flex-row w-full leading-5.5 pb-2 relative justify-between">
              <Link href={moserBuildingLink} shallow>
                <a className="font-normal whitespace-nowrap flex items-center py-[3px] left-0 pl-3.5 desktop:pl-3.5 pr-3.5 desktop:pr-3.5 rounded-l-[20px] desktop:rounded-l-[20px] rounded-r-[20px] desktop:rounded-r-[20px] bg-bg-800 text-typo-100 hover:bg-mono-600 disabled:bg-mono-700 cursor-pointer disabled:text-typo-300">
                  {buildingCards[0].building}
                </a>
              </Link>
              <div className="flex">
                <div className="rounded-full w-8 h-8 flex justify-center items-center bg-bg-800">
                  {renderIcon({ name: 'wardrobe', withBG: false })}
                </div>
                <div className="rounded-full w-8 h-8 flex justify-center items-center bg-bg-800">
                  {renderIcon({ name: 'toilets', withBG: false })}
                </div>
              </div>
            </li>
            <li className="font-normal text-lg flex flex-row w-full leading-5.5 pb-2 relative justify-between">
              <Link href={cfBuildingLink} shallow>
                <a className="font-normal whitespace-nowrap flex items-center py-[3px] left-0 pl-3.5 desktop:pl-3.5 pr-3.5 desktop:pr-3.5 rounded-l-[20px] desktop:rounded-l-[20px] rounded-r-[20px] desktop:rounded-r-[20px] bg-bg-800 text-typo-100 hover:bg-mono-600 disabled:bg-mono-700 cursor-pointer disabled:text-typo-300">
                  {buildingCards[1].building}
                </a>
              </Link>
              <div className="flex">
                <div className="rounded-full w-8 h-8 flex justify-center items-center bg-bg-800">
                  {renderIcon({ name: 'wardrobe', withBG: false })}
                </div>
                <div className="rounded-full w-8 h-8 flex justify-center items-center bg-bg-800">
                  {renderIcon({ name: 'toilets', withBG: false })}
                </div>
              </div>
            </li>
          </>
        )}
        {activeFloor !== 10 && openSpaceFloor && (
          <li className="font-normal text-lg flex flex-row w-full leading-5.5 pb-2 relative justify-between">
            <Link href={openSpaceLink} shallow>
              <a className="font-normal whitespace-nowrap flex items-center py-[3px] left-0 pl-3.5 desktop:pl-3.5 pr-3.5 desktop:pr-3.5 rounded-l-[20px] desktop:rounded-l-[20px] rounded-r-[20px] desktop:rounded-r-[20px] bg-bg-800 text-typo-100 hover:bg-mono-600 disabled:bg-mono-700 cursor-pointer disabled:text-typo-300">
                {openSpaceFloor.name}
              </a>
            </Link>
          </li>
        )}
        {activeFloor !== 10 && (
          <li className="font-normal text-lg flex flex-row w-full leading-5.5 pb-2 relative justify-between">
            <Link
              href={
                activeBuilding === 'Moser' ? cfBuildingLink : moserBuildingLink
              }
              shallow
            >
              <a className="font-normal whitespace-nowrap flex items-center py-[3px] left-0 pl-3.5 desktop:pl-3.5 pr-3.5 desktop:pr-3.5 rounded-l-[20px] desktop:rounded-l-[20px] rounded-r-[20px] desktop:rounded-r-[20px] bg-bg-800 text-typo-100 hover:bg-mono-600 disabled:bg-mono-700 cursor-pointer disabled:text-typo-300">
                {activeBuilding === 'Moser'
                  ? buildingCards[1]?.building
                  : buildingCards[0]?.building}
              </a>
            </Link>
            <div className="flex">
              <div className="rounded-full w-8 h-8 flex justify-center items-center bg-bg-800">
                {renderIcon({ name: 'wardrobe', withBG: false })}
              </div>
              <div className="rounded-full w-8 h-8 flex justify-center items-center bg-bg-800">
                {renderIcon({ name: 'toilets', withBG: false })}
              </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 h-px -mx-1  bg-mono-600" />
          </li>
        )}
        {activeFloor !== 10 &&
          filteredFloors.map((floorEntry, index) => {
            const hasFloorInBetween = floors.find(
              (entry) =>
                entry.floor === floorEntry.floor + 0.5 &&
                floorEntry.building === entry.building,
            )
            const isActiveFloor =
              activeBuilding === floorEntry.building &&
              activeFloor === floorEntry.floor
            return (
              <li
                className="font-medium text-lg flex justify-between py-[2px] flex-row w-full flex-wrap"
                key={`floorListItem_${index}`}
              >
                {index === 0 && (
                  <p className="font-normal text-sm text-typoContrast-800 px-3 py-[2px] w-full text-primary-200">
                    {floorEntry.building}
                  </p>
                )}
                {isActiveFloor ? (
                  <div className="font-normal leading-5.5 py-1.25 whitespace-nowrap flex items-center left-0 pl-3.5 desktop:pl-3.5 pr-3.5 desktop:pr-3.5 rounded-l-[20px] desktop:rounded-l-[20px] rounded-r-[20px] desktop:rounded-r-[20px] bg-bg-800 text-typo-100 bg-primary-200 hover:bg-primary-200 text-typoContrast-900 cursor-auto">
                    {`${floorEntry.name} ${hasFloorInBetween ? '&' : ''} ${
                      hasFloorInBetween?.name
                        .replace(' ', '')
                        .replace(/\d\./g, '') || ''
                    }`}
                  </div>
                ) : (
                  <Link
                    href={
                      floorEntry.building === 'Moser'
                        ? link(floorEntry.end)
                        : link(floorEntry.start)
                    }
                    shallow
                  >
                    {hasFloorInBetween ? (
                      <a
                        onClick={() => {
                          handleFloorClick()
                        }}
                        className="font-normal whitespace-nowrap flex items-center py-[3px] left-0 pl-3.5 desktop:pl-3.5 pr-3.5 desktop:pr-3.5 rounded-l-[20px] desktop:rounded-l-[20px] rounded-r-[20px] desktop:rounded-r-[20px] bg-bg-800 text-typo-100 desktop:hover:bg-mono-600 disabled:bg-mono-700 cursor-pointer disabled:text-typo-300"
                      >
                        {`${floorEntry.name} ${hasFloorInBetween ? '&' : ''} ${
                          hasFloorInBetween?.name
                            .replace(' ', '')
                            .replace(/\d\./g, '') || ''
                        }`}
                      </a>
                    ) : (
                      <a
                        onClick={() => {
                          handleFloorClick()
                        }}
                        className="font-normal whitespace-nowrap flex items-center py-[3px] left-0 pl-3.5 desktop:pl-3.5 pr-3.5 desktop:pr-3.5 rounded-l-[20px] desktop:rounded-l-[20px] rounded-r-[20px] desktop:rounded-r-[20px] bg-bg-800 text-typo-100 desktop:hover:bg-mono-600 disabled:bg-mono-700 cursor-pointer disabled:text-typo-300"
                      >
                        {floorEntry.name}
                      </a>
                    )}
                  </Link>
                )}
                <div className="flex flex-row self-center">
                  {floorEntry.icons.length > 0 &&
                    floorEntry.icons.map((icon, iconIndex) => {
                      return (
                        icon && (
                          <div
                            className="rounded-full w-8 h-8 flex justify-center items-center bg-bg-800"
                            key={`${floorEntry.name}_${floorEntry.building}_${icon}_${iconIndex}`}
                          >
                            {renderIcon({ name: icon, withBG: false })}
                          </div>
                        )
                      )
                    })}
                </div>
              </li>
            )
          })}
      </ul>
    </div>
  )
}
