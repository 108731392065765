import {
  CARD_HEIGHT,
  CARD_WIDTH,
  CARD_WIDTH_DESKTOP,
} from '../../lib/static-values'
import { HTMLProps, useMemo } from 'react'
import StairCardIcon from '../icons/stair-card-icon'
import { Card } from '../../types/types'
import {
  getActiveBuilding,
  getActiveFloor,
  useActiveBuildingStore,
  useActiveFloorStore,
} from '../../hooks/store/use-active-card'

export const FloorCard = ({
  card,
  hasTouch,
  cardRef,
  ...props
}: HTMLProps<HTMLDivElement> & {
  card: Card
  hasTouch: boolean
  cardRef: (card: HTMLDivElement | null) => void
}) => {
  const activeFloor = useActiveFloorStore(getActiveFloor)
  const activeBuilding = useActiveBuildingStore(getActiveBuilding)
  const isInactive = useMemo(
    () =>
      card.isInactive ||
      card.floor === null ||
      activeFloor === null ||
      Math.floor(activeFloor) !== Math.floor(card.floor) ||
      activeBuilding !== card.building,
    [activeFloor, activeBuilding, card],
  )

  return (
    <div
      {...props}
      className={`px-px desktop:px-0 desktop:py-px relative z-50 h-full desktop:h-auto flex items-center justify-center ${
        isInactive ? 'text-typo-300' : 'text-typo-100'
      } ${props.className}`}
      style={{
        width: hasTouch ? `${CARD_WIDTH / 2}px` : `${CARD_WIDTH_DESKTOP}px`,
        height: `${hasTouch ? CARD_HEIGHT : 62}px`,
        ...props.style,
      }}
      data-id={card.id}
      ref={cardRef}
    >
      <div className="absolute top-0 desktop:top-px left-px desktop:left-0 right-px desktop:right-0 bottom-0 desktop:bottom-px bg-bg-800" />
      <StairCardIcon
        className="relative"
        pathProps={{ fill: 'currentColor' }}
      />
    </div>
  )
}
