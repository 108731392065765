import { Card, Floor, Room } from '../types/types'
import { INITIAL_ROTATION } from './static-values'

type ThreeStoreProps = {
  activeCard: null | Card
  lastCard: null | Card
  activeRoom: null | Room
  hoveredCard: Card | null
  cards: Card[]
  rooms: Room[]
  orbit: number
  isScrolling: boolean
  scrollTo: number
  reset: boolean
  isReady: boolean
  tags: number[]
  tagName: string
  listTitle: string
  distance: number
  isInOverview: boolean
  zoomLevel: 1 | 2 | 3 | 4
  isPinching: boolean
  isPanning: boolean
  isRotating: boolean
  isAnimating: boolean
}

const ThreeStore: ThreeStoreProps & {
  set: (state: Partial<ThreeStoreProps>) => void
} = {
  tags: [],
  tagName: '',
  listTitle: '',
  lastCard: null,
  activeCard: null,
  activeRoom: null,
  cards: [],
  rooms: [],
  hoveredCard: null,
  orbit: INITIAL_ROTATION,
  isScrolling: false,
  scrollTo: 0,
  reset: false,
  isReady: false,
  distance: 400,
  isInOverview: true,
  zoomLevel: 2,
  isPinching: false,
  isPanning: false,
  isRotating: false,
  isAnimating: false,
  set: (state) => {
    Object.entries(state).forEach(([key, value]) => {
      ;(ThreeStore as { [key: string]: any })[`${key}`] = value
    })
  },
}

export const setCurrentFloor = (activeCard: Card | null) => {
  return !activeCard || activeCard.floor === null
    ? null
    : (Math.floor(activeCard.floor) as Floor)
}

export const setCurrentBuilding = (activeCard: Card | null) => {
  return !activeCard || !activeCard.building ? null : activeCard.building
}

export const maybeOnFloor: (
  currentFloor: Floor | null,
  room: Room | undefined,
  currentBuilding: boolean,
) => boolean = (currentFloor, room, currentBuilding) => {
  if (!room || room.floor === null || !currentBuilding) {
    return false
  }

  return Math.floor(room.floor) === currentFloor && currentBuilding
}

export default ThreeStore
