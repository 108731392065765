import { SVGProps } from 'react'

const ArtIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ ...props.style }}
    >
      <circle cx="8" cy="4.5" r="3" fill="currentColor" />

      <path
        d="M12.7 3H3.3C3.13431 3 3 3.13431 3 3.3V10.7C3 10.8657 3.13431 11 3.3 11H12.7C12.8657 11 13 10.8657 13 10.7V3.3C13 3.13431 12.8657 3 12.7 3Z"
        fill="currentColor"
      />
      <path
        d="M10.2812 15.0001C10.1169 15.0001 9.97477 14.8858 9.93939 14.7254L8.90052 10.0147C8.86515 9.8543 8.72299 9.74007 8.55873 9.74007H7.44118C7.27693 9.74007 7.13477 9.85429 7.09939 10.0147L6.06052 14.7254C6.02515 14.8858 5.88299 15.0001 5.71873 15.0001H4.43727C4.21305 15.0001 4.04666 14.7922 4.09577 14.5734L5.41864 8.68008C5.45451 8.52029 5.59638 8.40674 5.76014 8.40674H10.2399C10.4036 8.40674 10.5455 8.52029 10.5814 8.68008L11.9042 14.5734C11.9533 14.7922 11.7869 15.0001 11.5627 15.0001H10.2812Z"
        fill="currentColor"
      />
      <path
        d="M8.65 1H7.35C7.1567 1 7 1.1567 7 1.35V5.65C7 5.8433 7.1567 6 7.35 6H8.65C8.8433 6 9 5.8433 9 5.65V1.35C9 1.1567 8.8433 1 8.65 1Z"
        fill="currentColor"
      />
      <rect x="6" y="12" width="4" height="1" fill="currentColor" />
    </svg>
  )
}

export default ArtIcon
