import { Card, Colors, DetailPage } from '../types/types'
import colors from '../data/colors'

export const getColorSection = (
  card: Card | DetailPage,
  isInactive?: boolean,
) => {
  const colorShade = card.secondaryColor || card.isInactive ? 300 : 400

  if (card.color && !card.isInactive && !isInactive) {
    return (colors as Colors)[`${card.color}`][colorShade]
  }
  if (!card.color && !card.isInactive && !isInactive) {
    return colors.section9[colorShade]
  }
  if (card.isInactive || isInactive) {
    return colors.section10[colorShade]
  }
  return colors.section9[400]
}
