import create from 'zustand'
import { INITIAL_ROTATION } from '../../lib/static-values'

export type CompassRotationStore = {
  compassRotation: number
  setCompassRotation: (payload: number) => void
}
export const useCompassRotationStore = create<CompassRotationStore>((set) => ({
  compassRotation: INITIAL_ROTATION,
  setCompassRotation: (payload) => set(() => ({ compassRotation: payload })),
}))
export const getCompassRotation = (state: CompassRotationStore) =>
  state.compassRotation

export const setCompassRotationStore = (state: CompassRotationStore) =>
  state.setCompassRotation
