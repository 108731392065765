import { Card, Room } from '../types/types'
import { Vector3 } from 'three'

export const collectSimilarCards = (rooms: Room[], card?: Card | null) => {
  if (!card) {
    return null
  }
  const mainRoom = rooms.find((room) => card.roomTarget === room.id)

  if (mainRoom) {
    return mainRoom
  }

  const cardRooms = rooms.filter(
    (room) =>
      room.coords && card.rooms && card.rooms.find((id) => id === room.id),
  )

  if (!cardRooms.length) {
    return null
  }

  const coord = cardRooms.reduce((acc, room) => {
    room.coords && acc.add(room.coords)
    return acc
  }, new Vector3())

  coord.divideScalar(cardRooms.length)

  return { ...cardRooms[0], coords: coord } as Room
}
