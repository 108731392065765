import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'
import {
  Box3,
  DoubleSide,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  ShapeGeometry,
} from 'three'
import { Room } from '../types/types'

export const renderSVG = (
  mesh: Mesh,
  url: string,
  scale = 1,
  room: Room | undefined,
) => {
  mesh.children.forEach((child) => {
    if (child.name.match(/SVG_shape/)) {
      child.removeFromParent()
    }
  })

  const loader = new SVGLoader()

  loader.load(url, (data) => {
    const paths = data.paths

    paths.forEach((path) => {
      const material = new MeshBasicMaterial({
        color: path.color,
        side: DoubleSide,
        depthWrite: true,
      })

      const shapes = SVGLoader.createShapes(path)

      shapes.forEach((shape) => {
        const geometry = new ShapeGeometry(shape)
        const shapeMesh = new Mesh(geometry, material)
        shapeMesh.name = `SVG_shape`
        shapeMesh.geometry.computeBoundingBox()
        const bBox = shapeMesh.geometry.boundingBox as Box3
        const center = bBox.max.sub(bBox.min).multiplyScalar(0.5).clone()
        shapeMesh.position.sub(center)
        mesh.add(shapeMesh)
        mesh.rotation.set(
          MathUtils.degToRad(90),
          0,
          room?.room === 'Chipperfield' ? MathUtils.degToRad(180) : 0,
        )
        mesh.scale.set(1, 1, 1)
        mesh.scale.multiplyScalar(scale)
      })
    })
  })
}
