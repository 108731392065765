export const setZoomLevel = (distance: number) => {
  switch (true) {
    case distance < 133:
      return 4
    case distance < 265:
      return 3
    case distance < 400:
      return 2
    default:
      return 1
  }
}
