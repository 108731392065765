import Link from 'next/link'
import { RippleEffect } from '../ripple/ripple'
import { ListViewCard } from './list-view-card'
import { Card, Floor, Tag } from '../../types/types'
import { forwardRef, useCallback, useMemo, useState } from 'react'
import ArrowBackIcon from '../icons/arrow-back-icon'
import ShareIcon from '../icons/share-icon'
import { getFloorName } from '../../lib/get-floor-name'
import CloseIcon from '../icons/close-icon'
import { Share } from '../detail-section/share'
import { INITIAL_ROTATION } from '../../lib/static-values'
import { arrayToString } from '../../lib/array-to-string'
import shallow from 'zustand/shallow'
import { filterCardsByTag } from '../../lib/filter-cards-by-tag'
import {
  TranslationsStore,
  useTranslationsStore,
} from '../../hooks/store/use-translations'
import { getHasTouch, useHasTouchStore } from '../../hooks/store/use-has-touch'
import { getNavLevel, useNavLevelStore } from '../../hooks/store/use-nav-leve'
import {
  ActiveTagStore,
  getActiveTag,
  getActiveTagString,
  useActiveTagStore,
} from '../../hooks/store/use-active-tag'
import {
  getActivePill,
  useActivePillStore,
} from '../../hooks/store/use-active-pill'
import {
  ActivecCardListStore,
  useActiveCardListStore,
} from '../../hooks/store/use-active-card-list'
import {
  getFavoritesString,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'
import {
  getTaggedString,
  useTaggedCardIdsStore,
} from '../../hooks/store/use-tagged-card-ids'
import {
  getActiveCardString,
  useActiveCardStore,
} from '../../hooks/store/use-active-card'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'
import {
  getActiveTagListString,
  useActiveTagListStore,
} from '../../hooks/store/use-active-tag-list'
import { CardsStore, useCardsStore } from '../../hooks/store/use-cards'
import { getFloors, useFloorsStore } from '../../hooks/store/use-floors'

const useShareURL = (
  favoritesString: string,
  taggedString: string,
  activeTagListId: string,
  activeTagId: string,
) => {
  return useMemo(() => {
    return `${window.location.protocol}//${window.location.host}/-/${INITIAL_ROTATION}/-/true/2/${favoritesString}/${activeTagListId}/${activeTagId}/${taggedString}`
  }, [favoritesString, activeTagListId, activeTagId, taggedString])
}

const useCloseLink = (
  activeCardId: string,
  orbit: number,
  favoritesString: string,
  taggedString: string,
  shouldCloseMenu: string,
  navLevel: number,
  activeTagListId: string,
) => {
  return useMemo(() => {
    return `${activeCardId}/${orbit}/-/${shouldCloseMenu}/${navLevel}/${favoritesString}/${
      navLevel === 0 ? '-' : activeTagListId
    }/-/${taggedString}`
  }, [
    activeCardId,
    orbit,
    favoritesString,
    taggedString,
    shouldCloseMenu,
    navLevel,
    activeTagListId,
  ])
}

const useMapULR = (favoritesString: string, orbit: number) => {
  const activePill = useActivePillStore(getActivePill)
  const getTaggedCardIds = useCallback(
    (state: CardsStore) => {
      const taggedCards = activePill
        ? filterCardsByTag(activePill, state.cards)
        : []
      return taggedCards.reduce((acc, { id }) => {
        acc.push(id)
        return acc
      }, [] as string[])
    },
    [activePill],
  )
  const taggedCardIds = useCardsStore(getTaggedCardIds)

  const shouldSetTags = useActiveTagStore(
    (state) => state.activeTag && state.activeTag.tagType.name !== 'favorites',
  )

  const activeCardList = useActiveCardListStore((state) =>
    state.activeCardList.reduce((acc, { id }) => {
      acc.push(id)
      return acc
    }, [] as string[]),
  )

  return useCallback(
    (cardId?: string, activeTag?: Tag | null) => {
      const taggedString = shouldSetTags
        ? arrayToString(shouldSetTags ? activeCardList : taggedCardIds)
        : arrayToString(taggedCardIds)
      return `${
        !cardId ? activeCardList[0] : cardId
      }/${orbit}/-/false/0/${favoritesString}/-/${
        activeTag?.id || '-'
      }/${taggedString}`
    },
    [shouldSetTags, activeCardList, taggedCardIds, orbit, favoritesString],
  )
}

export const useTranslations = () => {
  const getTranslations = useCallback((state: TranslationsStore) => {
    const noEntries =
      state.translations.find((translation) => translation.id === '58287879')
        ?.text || ''
    const backToMap =
      state.translations.find((translation) => translation.id === '53876613')
        ?.text || ''
    const showOnMap =
      state.translations.find((translation) => translation.id === '53895359')
        ?.text || ''
    const shareText =
      state.translations.find(({ id }) => id === '54208725')?.text || ''
    return { backToMap, showOnMap, shareText, noEntries }
  }, [])
  return useTranslationsStore(getTranslations, shallow)
}

const useGoBackToMap = () => {
  const goBackToMap = useCallback((state: ActiveTagStore) => {
    return state.activeTag && state.activeTag.tagType.name === 'favorites'
  }, [])
  return useActiveTagStore(goBackToMap)
}

const useSortedByFloors = () => {
  const getSortedCardList = useCallback((state: ActivecCardListStore) => {
    const reduced = state.activeCardList
      .filter((card) => card.floor !== null && card.building !== null)
      .reduce((acc, card) => {
        const foundBuilding = acc.find(
          (entry) => entry.building === card.building,
        )
        if (foundBuilding) {
          const foundFloor = foundBuilding.floors.find(
            (entry) => entry.floor === card.floor,
          )
          if (foundFloor) {
            foundFloor.activeCardList.push(card)
            return acc
          }
          card.floor !== null &&
            foundBuilding.floors.push({
              floor: card.floor,
              activeCardList: [card],
            })
          return acc
        }
        card.floor !== null &&
          acc.push({
            building: card.building as string,
            floors: [{ floor: card.floor, activeCardList: [card] }],
          })
        return acc
      }, [] as { building: string; floors: { floor: Floor; activeCardList: Card[] }[] }[])
    reduced.forEach((building) =>
      building.floors.sort((current, next) =>
        next.floor !== null && current.floor !== null
          ? current.floor - next.floor
          : 0,
      ),
    )
    reduced.sort((a) => (a.floors[0].floor === 10 ? -1 : 1))
    return reduced
  }, [])
  return useActiveCardListStore(getSortedCardList)
}

export const ListView = forwardRef<HTMLDivElement>(({}, ref) => {
  const hasTouch = useHasTouchStore(getHasTouch)
  const navLevel = useNavLevelStore(getNavLevel)
  const activeTag = useActiveTagStore(getActiveTag)

  const favoritesString = useFavoritesStore(getFavoritesString)
  const taggedString = useTaggedCardIdsStore(getTaggedString)
  const activeCardId = useActiveCardStore(getActiveCardString)
  const orbit = useOrbitStore(getOrbit)
  const { showMenu, backToMain } = useNavLevelStore(
    useCallback(
      (state) => ({
        showMenu: state.navLevel.hasCloseBtn ? 'false' : 'true',
        backToMain: state.navLevel.hasCloseBtn || state.navLevel.toMain ? 0 : 1,
      }),
      [],
    ),
    shallow,
  )
  const activeTagListId = useActiveTagListStore(getActiveTagListString)
  const activeTagId = useActiveTagStore(getActiveTagString)

  const setActivePill = useActivePillStore(
    useCallback((state) => state.setActivePill, []),
  )

  const floors = useFloorsStore(getFloors)

  const [shareIsCollapsed, setShareIsCollapsed] = useState(true)

  const { backToMap, showOnMap, shareText, noEntries } = useTranslations()

  const goBackToMap = useGoBackToMap()

  const url = useShareURL(
    favoritesString,
    taggedString,
    activeTagListId,
    activeTagId,
  )
  const closeURL = useCloseLink(
    activeCardId,
    orbit,
    favoritesString,
    taggedString,
    showMenu,
    backToMain,
    activeTagListId,
  )
  const backToMapURL = useMapULR(favoritesString, orbit)

  const sortedByFloors = useSortedByFloors()

  const handleOnShareClick = async () => {
    if (hasTouch) {
      try {
        await navigator.share({
          url,
        })
      } catch (err: any) {
        console.log('Share failed: ' + err.message)
      }
      return
    }
    setShareIsCollapsed(!shareIsCollapsed)
  }

  return (
    <div ref={ref}>
      <div className="sticky desktop:relative top-0 desktop:top-none z-[100] bg-bg-900 desktop:bg-none">
        <div className="desktop:pt-0 pt-7 flex justify-between items-center">
          <div>
            <h4 className="text-4xl font-black">
              {activeTag && activeTag.name}
            </h4>
          </div>
          <div className="flex items-end flex-col">
            {
              <Link href={closeURL} passHref shallow>
                <a>
                  <RippleEffect className="rounded-full bg-mono-700 desktop:hover:bg-mono-600 w-13 h-13 flex justify-center items-center cursor-pointer">
                    {navLevel.hasCloseBtn ? <CloseIcon /> : <ArrowBackIcon />}
                  </RippleEffect>
                </a>
              </Link>
            }
          </div>
        </div>
        <button
          className="w-13 ml-auto mr-0 flex justify-center pb-3 desktop:pb-0"
          onClick={handleOnShareClick}
        >
          <RippleEffect className="rounded-full bg-mono-700 desktop:hover:bg-mono-600 w-10 h-10 flex justify-center items-center cursor-pointer mt-3">
            <ShareIcon />
          </RippleEffect>
        </button>
        {activeTag && !hasTouch && (
          <Share
            url={url}
            body={activeTag?.name || ''}
            subject={shareText}
            isCollapsed={shareIsCollapsed}
          />
        )}
      </div>
      {sortedByFloors.length ? (
        <ul>
          {sortedByFloors.map((building) => {
            return (
              building.floors.length &&
              building.floors.map((floor) => {
                return (
                  floor.activeCardList.length &&
                  floor.activeCardList.map((card, index) => {
                    return (
                      <li
                        key={`listView_${building.building}_${floor.floor}_${index}`}
                      >
                        {index === 0 && (
                          <div className="pt-3 desktop:pt-6 pb-2">
                            <p className="text-xs">
                              {`${building.building} ${getFloorName(
                                floor.floor,
                                floors,
                              )}`}
                            </p>
                          </div>
                        )}
                        <Link href={backToMapURL(card.id, activeTag)} shallow>
                          <a
                            onClick={() => {
                              !goBackToMap && setActivePill(activeTag)
                            }}
                          >
                            <ListViewCard card={card} />
                          </a>
                        </Link>
                      </li>
                    )
                  })
                )
              })
            )
          })}
        </ul>
      ) : (
        noEntries
      )}
      <div className="sticky py-4.5 bottom-0 bg-bg-900 z-50">
        <Link
          href={backToMapURL(
            sortedByFloors[0]?.floors[0]?.activeCardList[0]?.id || undefined,
            activeTag,
          )}
          shallow
          passHref
        >
          <a
            onClick={() => {
              !goBackToMap && setActivePill(activeTag)
            }}
          >
            <RippleEffect className="w-full bg-primary-200 py-4 flex items-center justify-center text-typoContrast-900 text-l font-normal rounded cursor-pointer">
              {goBackToMap ? backToMap : showOnMap}
            </RippleEffect>
          </a>
        </Link>
      </div>
    </div>
  )
})
