import { Card } from './card'
import { Card as CardType } from '../../types/types'
import { animated, useSpring } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import {
  CARD_BUILDING_HEIGHT,
  CARD_BUILDING_WIDTH_DESKTOP,
} from '../../lib/static-values'
import { Floors } from './floors'
import { centerCard } from '../../lib/center-card'
import { MoserCard } from './moser-card'
import { CFCard } from './cf-card'
import { FloorCard } from './floor-card'
import { handleCardOffset } from '../../lib/handle-card-offset'
import { easeCubicInOut } from 'd3-ease'
import ThreeStore from '../../lib/store'
import { ActionCard } from './action-card'
import { useRouter } from 'next/router'
import { arrayToString } from '../../lib/array-to-string'
import { useFavoritesStore } from '../../hooks/store/use-favorites'
import { useTaggedCardIdsStore } from '../../hooks/store/use-tagged-card-ids'
import {
  getDetailPageString,
  useDetailPageStore,
} from '../../hooks/store/use-detail-page-id'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'
import {
  getActiveTagString,
  useActiveTagStore,
} from '../../hooks/store/use-active-tag'
import {
  getActiveTagListString,
  useActiveTagListStore,
} from '../../hooks/store/use-active-tag-list'
import { getCards, useCardsStore } from '../../hooks/store/use-cards'
import {
  getActiveCard,
  useActiveCardStore,
} from '../../hooks/store/use-active-card'
import { getIsReady, useIsReadyStore } from '../../hooks/store/use-is-ready'
import {
  getMoserCard,
  useMoserCardStore,
} from '../../hooks/store/use-moser-card'
import { getcfCard, useCfCardStore } from '../../hooks/store/use-cf-card'
import { getCfIndex, useCfIndexStore } from '../../hooks/store/use-cf-index'
import { useFirstBuildingCards } from '../../hooks/store/use-first-building-cards'
import {
  getMoserIndex,
  useMoserIndexStore,
} from '../../hooks/store/use-moser-index'
import { useHoverStore } from '../../hooks/store/use-hover'
import { useOffsetStore } from '../../hooks/store/use-offset'
import { useIsInitialStore } from '../../hooks/store/use-initial-store'
import { getHasTouch, useHasTouchStore } from '../../hooks/store/use-has-touch'
import shallow from 'zustand/shallow'
import OnBoardingIcon from '../icons/onboarding-icon'

const handlePushToRef = (
  ref: HTMLAnchorElement | HTMLDivElement | null,
  refArray: (HTMLDivElement | HTMLAnchorElement)[],
) => {
  if (!ref) {
    return
  }
  const refIndex = refArray.findIndex(
    ({ dataset }) => dataset.id === ref.dataset.id,
  )
  if (refIndex < 0) {
    refArray.push(ref)
    return
  }
  refArray[refIndex] = ref
}

const useAnimationLink = () => {
  const favoritesString = useFavoritesStore((state) =>
    arrayToString(state.favorites),
  )
  const taggedString = useTaggedCardIdsStore((state) =>
    arrayToString(state.taggedCardIds),
  )
  const detailPage = useDetailPageStore(getDetailPageString)
  const orbit = useOrbitStore(getOrbit)
  const activeTagListId = useActiveTagListStore(getActiveTagListString)
  const activeTagId = useActiveTagStore(getActiveTagString)

  return useCallback(
    (id: string | null) => {
      return `${id}/${orbit}/${detailPage}/false/0/${favoritesString}/${activeTagListId}/${activeTagId}/${taggedString}`
    },
    [
      orbit,
      detailPage,
      favoritesString,
      activeTagListId,
      activeTagId,
      taggedString,
    ],
  )
}

export const Cards = memo(
  () => {
    const router = useRouter()
    const cfIndex = useCfIndexStore(getCfIndex)
    const cards = useCardsStore(getCards)
    const activeCard = useActiveCardStore(getActiveCard, shallow)
    const isReady = useIsReadyStore(getIsReady)
    const moserCard = useMoserCardStore(getMoserCard)
    const cfCard = useCfCardStore(getcfCard)
    const moserIndex = useMoserIndexStore(getMoserIndex)

    const setCards = useCardsStore(useCallback((state) => state.setCards, []))
    const setCfCard = useCfCardStore(
      useCallback((state) => state.setCfCard, []),
    )
    const setFirstBuildingCards = useFirstBuildingCards(
      useCallback((state) => state.setFirstCard, []),
    )
    const setMoserCard = useMoserCardStore(
      useCallback((state) => state.setMoserCard, []),
    )
    const setHoveredCard = useHoverStore(
      useCallback((state) => state.setHoveredCard, []),
    )
    const setOffset = useOffsetStore(
      useCallback((state) => state.setOffset, []),
    )
    const setIsInitial = useIsInitialStore(
      useCallback((state) => state.setIsInitial, []),
    )

    const lastCardLength = useRef(cards.length)
    const offset = useRef(0)
    const isAnimating = useRef(false)
    const cardWrapper = useRef<HTMLDivElement>(null)
    const cardRefs = useRef<(HTMLDivElement | HTMLAnchorElement)[]>([])
    const closestCard = useRef<null | CardType | undefined>(undefined)
    const shouldUpdateURL = useRef(false)
    const isInitial = useRef(true)
    const animationLink = useAnimationLink()

    const [toggleFloorBackground, setToggleFloorBackground] = useState(false)

    const [showOnBoarding, setShowOnBoarding] = useState(true)

    const lastOffset = useRef<number | null>(null)

    const hasTouch = useHasTouchStore(getHasTouch)

    const [spring, setSpring] = useSpring(
      () => ({
        offset: offset.current,
        down: false,
        cancel: !isReady,
        onStart: () => {
          isAnimating.current = true
          ThreeStore.set({ isAnimating: true })
          setHoveredCard(null)
          setToggleFloorBackground(false)
        },
        onChange: ({ value: { offset: cardOffset } }) => {
          if (lastOffset.current === null) {
            lastOffset.current = cardOffset
          }
          setOffset(cardOffset)
        },
        onRest: ({ value: { offset: cardOffset, down } }) => {
          isInitial.current = false

          if (down) {
            return
          }
          lastOffset.current = cardOffset
          shouldUpdateURL.current &&
            activeCard?.id !== closestCard.current?.id &&
            router.push(
              animationLink(closestCard.current?.id || null),
              undefined,
              { shallow: true },
            )

          shouldUpdateURL.current = false

          isAnimating.current = false
          ThreeStore.set({ isAnimating: false, isScrolling: false })
          setIsInitial(false)
        },
      }),
      [hasTouch, animationLink, activeCard],
    )

    const bind = useGesture({
      onDrag: ({
        down,
        delta: [dx, dy],
        velocity,
        movement: [movementX, movementY],
      }) => {
        hasTouch &&
          handleCardOffset(
            down,
            dx,
            dy,
            velocity,
            movementX,
            movementY,
            offset,
            hasTouch,
            closestCard,
            cards,
            cfIndex,
            setSpring,
          )
      },
      onWheel: ({
        wheeling,
        delta: [dx, dy],
        movement: [movementX, movementY],
      }) => {
        {
          !hasTouch &&
            handleCardOffset(
              wheeling,
              dx,
              -dy * 0.5,
              0,
              movementX,
              movementY,
              offset,
              hasTouch,
              closestCard,
              cards,
              cfIndex,
              setSpring,
            )
        }
      },
      onDragStart: () => {
        if (!hasTouch) {
          return
        }
        ThreeStore.isScrolling = true
        shouldUpdateURL.current = true
      },
      onWheelStart: () => {
        ThreeStore.isScrolling = true
        shouldUpdateURL.current = true
      },
    })

    useEffect(() => {
      shouldUpdateURL.current = false

      if (!cards.length) {
        return
      }

      closestCard.current = activeCard || null
      offset.current = !activeCard
        ? -cards[cfIndex]?.offset ||
          -cards[Math.floor(cards.length / 2)]?.offset ||
          0
        : -(activeCard.offset + centerCard(activeCard, hasTouch))
      setSpring.start({
        offset: offset.current,
        down: false,
        immediate: isInitial.current,
        config: { duration: undefined },
      })
    }, [cards])

    useEffect(() => {
      if (!isReady) {
        return
      }

      if (activeCard) {
        setShowOnBoarding(false)
        return
      }

      if (!activeCard) {
        const delayToMoser = setTimeout(() => {
          ThreeStore.isScrolling = true
          setSpring.start({
            offset: offset.current + 20,
            down: true,
            immediate: false,
            config: { duration: 500, easing: (t) => easeCubicInOut(t) },
          })
        }, 1500)
        const delayToCF = setTimeout(() => {
          ThreeStore.isScrolling = true
          setSpring.start({
            offset: offset.current - 20,
            down: true,
            immediate: false,
            config: { duration: 1000, easing: (t) => easeCubicInOut(t) },
          })
        }, 2000)
        const delayToInit = setTimeout(() => {
          ThreeStore.isScrolling = true
          setSpring.start({
            offset: offset.current,
            down: true,
            immediate: false,
            config: { duration: 500, easing: (t) => easeCubicInOut(t) },
          })
        }, 3000)
        const delayOnBoarding = setTimeout(() => {
          setShowOnBoarding(false)
        }, 3500)

        return () => {
          clearTimeout(delayToMoser)
          clearTimeout(delayToCF)
          clearTimeout(delayToInit)
          clearTimeout(delayOnBoarding)
        }
      }
    }, [isReady])

    useEffect(() => {
      if (!activeCard && !closestCard.current) {
        return
      }
      if (closestCard.current?.id === activeCard?.id) {
        return
      }

      const foundCard = cards.find((card) => card.id === activeCard?.id)

      closestCard.current = foundCard || null

      const card =
        foundCard || cards[cfIndex] || cards[Math.floor(cards.length / 2)]

      const mayCenterCard = activeCard ? centerCard(card, hasTouch) : 0

      offset.current = card ? -(card.offset + mayCenterCard) : 0

      shouldUpdateURL.current = false

      setSpring.start({ offset: offset.current, down: false })
    }, [activeCard, cards])

    useEffect(() => {
      if (!cards.length) {
        return
      }
      if (cards[0].offset !== -1 && lastCardLength.current === cards.length) {
        return
      }
      lastCardLength.current = cards.length
      const updatedCards = cards.reduce((acc, card) => {
        const cardRef = cardRefs.current.find(
          ({ dataset }) => dataset.id === card.id,
        )
        if (cardRef) {
          card.offset = hasTouch ? cardRef.offsetLeft : cardRef.offsetTop
          card.width = cardRef.offsetWidth
          card.height = cardRef.offsetHeight
          acc.push(card)
        } else if (card.width > 0) {
          acc.push(card)
        }
        return acc
      }, [] as CardType[])

      setCards(updatedCards)
      if (updatedCards[cfIndex] && updatedCards[moserIndex]) {
        setCfCard(updatedCards[cfIndex])
        setFirstBuildingCards([
          updatedCards[moserIndex - 1],
          updatedCards[cfIndex + 1],
        ])
        setMoserCard(updatedCards[moserIndex])
      }

      return () => {
        cardRefs.current = []
      }
    }, [hasTouch, cards])

    return (
      <>
        {toggleFloorBackground && (
          <div
            className="absolute w-full h-full left-0 top-0 z-50"
            onClick={() => setToggleFloorBackground(false)}
          />
        )}
        <div
          className="z-50 absolute w-full bottom-0 desktop:bottom-auto desktop:right-0 px-4 desktop:px-0 desktop:py-4 h-full desktop:top-0 desktop:overflow-x-visible"
          style={{
            touchAction: 'none',
            height: hasTouch ? `${CARD_BUILDING_HEIGHT}px` : '100%',
            width: hasTouch ? '100%' : `${CARD_BUILDING_WIDTH_DESKTOP}px`,
          }}
          {...bind()}
          onMouseLeave={() => setHoveredCard(null)}
        >
          <div ref={cardWrapper} className="w-full h-full relative">
            {!activeCard && showOnBoarding && (
              <div
                className="w-16 h-16 bg-primary-200 rounded-full absolute left-1/2 top-1/2 flex items-center justify-center desktop:hidden z-50"
                style={{
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <div className="on-boarding absolute left-0 top-0" />
                <OnBoardingIcon className="text-typoContrast-900" />
              </div>
            )}
            <animated.div
              style={{
                transform: spring.offset.to((value) => {
                  return hasTouch
                    ? `translate3d(${value}px, 0, 0)`
                    : `translate3d(0, ${value}px, 0)`
                }),
              }}
              className="absolute left-1/2 desktop:left-auto desktop:top-1/2 flex flex-nowrap h-full items-end desktop:flex-wrap desktop:justify-end desktop:content-start desktop:h-auto"
            >
              {cards.map((card, i) => {
                if (card.type === 'floor') {
                  return (
                    <FloorCard
                      card={card}
                      hasTouch={hasTouch}
                      cardRef={(ref) => {
                        handlePushToRef(ref, cardRefs.current)
                      }}
                      key={`card_${card.id}`}
                    />
                  )
                }

                if (card.id === moserCard?.id) {
                  return (
                    <MoserCard
                      hasTouch={hasTouch}
                      cardRef={(ref) => {
                        handlePushToRef(ref, cardRefs.current)
                      }}
                      card={card}
                      key={`card_${card.id}`}
                    />
                  )
                }
                if (card.id === cfCard?.id) {
                  return (
                    <CFCard
                      hasTouch={hasTouch}
                      cardRef={(ref) => {
                        handlePushToRef(ref, cardRefs.current)
                      }}
                      card={card}
                      key={`card_${card.id}`}
                    />
                  )
                }
                if (card.type === 'action_card') {
                  return (
                    <ActionCard
                      card={card}
                      key={`card_${i}`}
                      cardRef={(ref) => {
                        handlePushToRef(ref, cardRefs.current)
                      }}
                    />
                  )
                }

                return (
                  <Card
                    card={card}
                    hasTouch={hasTouch}
                    cardRef={(ref) => {
                      handlePushToRef(ref, cardRefs.current)
                    }}
                    key={`card_${i}`}
                    isAnimating={isAnimating}
                  />
                )
              })}

              <Floors
                cardWrapper={cardWrapper}
                hasTouch={hasTouch}
                setToggleFloorBackground={(state) =>
                  setToggleFloorBackground(state)
                }
                toggleFloorBackground={toggleFloorBackground}
              />
            </animated.div>
          </div>
        </div>
      </>
    )
  },
  () => true,
)
