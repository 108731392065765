import create from 'zustand'

export type IsFontReadyStore = {
  isFontReady: boolean
  setIsFontReady: (payload: boolean) => void
}
export const useIsFontReadyStore = create<IsFontReadyStore>((set) => ({
  isFontReady: false,
  setIsFontReady: (payload) => set(() => ({ isFontReady: payload })),
}))

export const getIsFontReady = (state: IsFontReadyStore) => state.isFontReady
