import { Tag } from '../../types/types'
import create from 'zustand'

export type TagsStore = {
  tags: Tag[]
  setTags: (payload: Tag[]) => void
}
export const useTagsStore = create<TagsStore>((set) => ({
  tags: [],
  setTags: (payload) => set(() => ({ tags: payload })),
}))

export const getTags = (state: TagsStore) => state.tags
