import { SVGProps } from 'react'

const ParkingIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3C7.22386 3 7 3.22386 7 3.5V20.5C7 20.7761 7.22386 21 7.5 21H8.5C8.77614 21 9 20.7761 9 20.5V13H15C16.3252 13 17.5668 12.3475 18.4571 11.4571C19.3475 10.5668 20 9.32523 20 8C20 6.67477 19.3475 5.43324 18.4571 4.54289C17.5668 3.65255 16.3252 3 15 3H9H7.5ZM9 5V11H15C15.6748 11 16.4332 10.6525 17.0429 10.0429C17.6525 9.43324 18 8.67477 18 8C18 7.32523 17.6525 6.56676 17.0429 5.95711C16.4332 5.34745 15.6748 5 15 5H9Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default ParkingIcon
