import { Card } from '../../types/types'
import create from 'zustand'
import { emptyCard } from '../../lib/empty-card'

export type CfCardStore = {
  cfCard: Card
  setCfCard: (payload: Card) => void
}
export const useCfCardStore = create<CfCardStore>((set) => ({
  cfCard: emptyCard,
  setCfCard: (payload) => set(() => ({ cfCard: payload })),
}))

export const getcfCard = (state: CfCardStore) => state.cfCard
