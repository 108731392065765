import { Room } from '../../types/types'
import create from 'zustand'

export type ActiveRoomStore = {
  activeRoom: Room | null
  setActiveRoom: (payload: Room | null) => void
}
export const useActiveRoomStore = create<ActiveRoomStore>((set) => ({
  activeRoom: null,
  setActiveRoom: (payload) => set(() => ({ activeRoom: payload })),
}))

export const getActiveRoom = (state: ActiveRoomStore) => state.activeRoom
