import { SVGProps } from 'react'

const CafeIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4.5C6 4.22386 6.22386 4 6.5 4H16.5C16.7761 4 17 4.22386 17 4.5V6H18C19.1046 6 20 6.89543 20 8V12C20 13.1046 19.1046 14 18 14H17V15C17 16.1046 16.1046 17 15 17H8C6.89543 17 6 16.1046 6 15V4.5ZM17 12H18V8H17V12ZM4 19.5C4 19.2239 4.22386 19 4.5 19H19.5C19.7761 19 20 19.2239 20 19.5C20 20.3284 19.3284 21 18.5 21H5.5C4.67157 21 4 20.3284 4 19.5Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default CafeIcon
