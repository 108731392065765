import { SVGProps } from 'react'

const ElevatorIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#F0F0F0"
        d="M6.64645 3.93942C6.84171 3.74415 7.15829 3.74415 7.35355 3.93942L11.3536 7.93942C11.5488 8.13468 11.5488 8.45126 11.3536 8.64652L10.6464 9.35363C10.4512 9.54889 10.1346 9.54889 9.93934 9.35363L7.99992 7.41421V17.4999C7.99992 17.7761 7.77607 17.9999 7.49992 17.9999H6.49992C6.22378 17.9999 5.99992 17.7761 5.99992 17.4999V7.41436L4.06066 9.35363C3.8654 9.54889 3.54882 9.54889 3.35355 9.35363L2.64645 8.64652C2.45118 8.45126 2.45118 8.13468 2.64645 7.93942L6.64645 3.93942ZM19.9393 14.6463L18.0001 16.5856L18.0001 6.5C18.0001 6.22386 17.7762 6 17.5001 6H16.5001C16.2239 6 16.0001 6.22386 16.0001 6.5L16.0001 16.5857L14.0607 14.6463C13.8654 14.451 13.5488 14.451 13.3536 14.6463L12.6464 15.3534C12.4512 15.5487 12.4512 15.8652 12.6464 16.0605L16.6464 20.0605C16.8417 20.2558 17.1583 20.2558 17.3536 20.0605L21.3536 16.0605C21.5488 15.8652 21.5488 15.5487 21.3536 15.3534L20.6464 14.6463C20.4512 14.451 20.1346 14.451 19.9393 14.6463Z"
        {...pathProps}
      />
    </svg>
  )
}

export default ElevatorIcon
