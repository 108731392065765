import { SVGProps } from 'react'

const MailIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7994 5H3.20059L11.8438 11.9146C11.9351 11.9876 12.0649 11.9876 12.1562 11.9146L20.7994 5ZM3 6.76047V17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V6.76047L13.0932 13.0859C12.4541 13.5972 11.5459 13.5972 10.9068 13.0859L3 6.76047Z"
        fill="currentColor"
        {...pathProps}
      />
    </svg>
  )
}

export default MailIcon
