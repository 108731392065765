import { SVGProps } from 'react'

export const DisabledParkingIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9925 12.2383C16.8702 12.132 17.4976 11.3235 17.3929 10.4328C17.2882 9.54144 16.4915 8.90534 15.6138 9.01159C14.7355 9.11721 14.1087 9.92577 14.2134 10.8171C14.3175 11.7078 15.1142 12.3446 15.9925 12.2383ZM15.2698 20.9167C16.3321 20.9167 17.2737 20.3916 17.8562 19.5833L18.2469 20.7728C17.4778 21.5323 16.4276 22 15.2698 22C12.9116 22 11 20.0599 11 17.6667C11 16.0628 11.8586 14.6625 13.1349 13.9132V15.2443C12.4797 15.8394 12.0674 16.7042 12.0674 17.6667C12.0674 19.4616 13.5012 20.9167 15.2698 20.9167ZM15.2695 12.7918C14.68 12.7918 14.2021 13.2768 14.2021 13.8751L14.202 16.5834C14.202 17.6667 14.735 18.2083 15.8032 18.2083L18.4718 18.2084L19.3704 20.9444C19.4636 21.2282 19.7659 21.3816 20.0455 21.287L20.634 21.0879C20.9137 20.9933 21.0648 20.6866 20.9716 20.4028L19.6609 16.4121C19.5882 16.1909 19.3843 16.0417 19.1545 16.0417L17.4043 16.0417C17.1096 16.0417 16.8706 15.7992 16.8706 15.5001V14.9584H18.4718C18.7665 14.9584 19.0055 14.7159 19.0055 14.4168C19.0055 14.1176 18.7665 13.8751 18.4718 13.8751H17.4043C17.1096 13.8751 16.8706 13.6326 16.8706 13.3335C16.8706 13.0343 16.6317 12.7918 16.3369 12.7918H15.2695Z"
        fill="#F0F0F0"
        {...pathProps}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.38462 3C3.1722 3 3 3.16168 3 3.36111V15.6389C3 15.8383 3.1722 16 3.38462 16H4.15385C4.36626 16 4.53846 15.8383 4.53846 15.6389V10.2222H9.15385C10.1733 10.2222 11.1283 9.75094 11.8132 9.10791C12.498 8.46488 13 7.56822 13 6.61111C13 5.654 12.498 4.75734 11.8132 4.11431C11.1283 3.47128 10.1733 3 9.15385 3H4.53846H3.38462ZM4.53846 4.44444V8.77778H9.15385C9.6729 8.77778 10.2563 8.52684 10.7253 8.08653C11.1943 7.64623 11.4615 7.09844 11.4615 6.61111C11.4615 6.12378 11.1943 5.57599 10.7253 5.13569C10.2563 4.69538 9.6729 4.44444 9.15385 4.44444H4.53846Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default DisabledParkingIcon
