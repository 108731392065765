import { UsefulInfosCollapsible } from './useful-infos-collapsible'
import IconChevronDown from '../icons/chevron-down'

export const UsefulInfos = ({
  title,
  text,
  isCollapsed,
  isLast,
  onClick,
}: {
  title: string
  text: string
  isLast?: boolean
  isCollapsed: boolean
  onClick: () => void
}) => {
  return (
    <div>
      <button
        className={`relative flex justify-between item-center w-full px-3 pt-3.5 pb-3.5 bg-bg-800 desktop:hover:bg-mono-600  text-l ${
          isLast ? '' : 'mb-0.5'
        } items-center`}
        onClick={onClick}
      >
        <div className="flex-grow text-left" style={{ flexBasis: '100%' }}>
          {title}
        </div>
        <div className="flex-grow">
          <IconChevronDown
            style={{
              transform: isCollapsed ? `` : `rotate(180deg)`,
              transition: `transform 500ms`,
            }}
          />
        </div>
      </button>
      <UsefulInfosCollapsible isCollapsed={isCollapsed} text={text} />
    </div>
  )
}
