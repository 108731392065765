import { RenderPage } from '../components/render-page'
import {
  Card,
  FloorData,
  Link,
  OpeningHoursData,
  Room,
  Tag,
  UsefulInfos,
} from '../types/types'
import { useCallback, useEffect, useRef } from 'react'
import { GetServerSideProps } from 'next'
import { useRouter } from 'next/router'
import { maybeNumber } from '../lib/maybe-number'
import {
  CHIPPERFIELD_COORDS,
  INITIAL_ROTATION,
  MOSER_COORDS,
} from '../lib/static-values'
import { stringToArray } from '../lib/string-to-array'
import { updateFavoritesTags } from '../lib/update-favorites'
import ThreeStore from '../lib/store'
import { collectSimilarCards } from '../lib/collect-similar-cards'
import { calcDistance } from '../lib/calc-distance'
import { setZoomLevel } from '../lib/set-zoom-level'
import { getLanguage } from '../lib/get-language'
import { filterCardsByTag } from '../lib/filter-cards-by-tag'
import { arrayIsSame } from '../lib/array-is-same'
import { useWindowSize } from '../hooks/use-window-size'
import { useClient } from '../hooks/use-client'
import { useBreakPoints } from '../hooks/use-breakpoints'
import { getRooms, useRoomsStore } from '../hooks/store/use-rooms'
import { useCardsStore } from '../hooks/store/use-cards'
import { getAllCards, useAllCardsStore } from '../hooks/store/use-all-cards'
import {
  getActiveCard,
  useActiveBuildingStore,
  useActiveCardStore,
  useActiveFloorStore,
} from '../hooks/store/use-active-card'
import { getNavLevel, useNavLevelStore } from '../hooks/store/use-nav-leve'
import { getShowMenu, useShowMenuStore } from '../hooks/store/use-show-menu'
import {
  getDetailPageId,
  useDetailPageStore,
} from '../hooks/store/use-detail-page-id'
import { getFavorites, useFavoritesStore } from '../hooks/store/use-favorites'
import {
  getActivePill,
  useActivePillStore,
} from '../hooks/store/use-active-pill'
import { useOpeningHoursStore } from '../hooks/store/use-opening-hours'
import { useFloorsStore } from '../hooks/store/use-floors'
import { useMoserCardStore } from '../hooks/store/use-moser-card'
import { useFirstBuildingCards } from '../hooks/store/use-first-building-cards'
import { useCfCardStore } from '../hooks/store/use-cf-card'
import { useCfIndexStore } from '../hooks/store/use-cf-index'
import { useMoserIndexStore } from '../hooks/store/use-moser-index'
import { useActiveRoomStore } from '../hooks/store/use-active-room'
import { useActiveCardListStore } from '../hooks/store/use-active-card-list'
import { useTagsStore } from '../hooks/store/use-tags'
import { useTaggedCardIdsStore } from '../hooks/store/use-tagged-card-ids'
import { useActiveTagStore } from '../hooks/store/use-active-tag'
import { useActiveTagListStore } from '../hooks/store/use-active-tag-list'
import { useFavoriteRoomsStore } from '../hooks/store/use-favorite-rooms'
import { useTaggedRoomsStore } from '../hooks/store/use-tagged-rooms'
import { useTranslationsStore } from '../hooks/store/use-translations'
import { useVisitorInfosStore } from '../hooks/store/use-visitor-infos'
import { useQuicklinksStore } from '../hooks/store/use-quicklinks'
import { useDistanceStore } from '../hooks/store/use-distance'
import { useUsefulInfos } from '../hooks/store/use-useful-infos'
import { fetchCacheData } from '../lib/api/fetch-cache-data'
import { ironSession } from '../lib/auth/iron-session'
import { withIronSessionSsr } from 'iron-session/next'

export default function Home({
  floors,
  cards,
  rooms,
  moserCard,
  cfCard,
  cfIndex,
  moserIndex,
  tags,
  translations,
  visitorInfos,
  quicklinks,
  openingHours,
  usefulInfos,
}: {
  floors: FloorData[]
  cards: Card[]
  rooms: Room[]
  moserCard: Card
  cfCard: Card
  cfIndex: number
  moserIndex: number
  tags: Tag[]
  translations: { id: string; text: string }[]
  visitorInfos: Link[]
  quicklinks: Link[]
  openingHours: OpeningHoursData
  usefulInfos: UsefulInfos[]
}) {
  const router = useRouter()

  const { all } = router.query

  const [
    cardId,
    orbitURL,
    detailPageId,
    showMenuURL,
    navLevel,
    favorites,
    tagList,
    tag,
    tagIds,
  ] = all && all.length ? all : []

  const isOpenSpace = useRef(false)
  const lastLang = useRef(router.locale)

  const roomsStore = useRoomsStore(getRooms)
  const allCardsStore = useAllCardsStore(getAllCards)
  const activeCard = useActiveCardStore(getActiveCard)
  const navLevelStore = useNavLevelStore(getNavLevel)
  const showMenu = useShowMenuStore(getShowMenu)
  const detailPageIdStore = useDetailPageStore(getDetailPageId)
  const favoritesStore = useFavoritesStore(getFavorites)
  const activePill = useActivePillStore(getActivePill)

  const setAllCards = useAllCardsStore(
    useCallback((state) => state.setAllCards, []),
  )
  const setUsefulInfos = useUsefulInfos(
    useCallback((state) => state.setUsefulInfos, []),
  )

  const setActivePill = useActivePillStore(
    useCallback((state) => state.setActivePill, []),
  )
  const setOpeningHours = useOpeningHoursStore(
    useCallback((state) => state.setOpeningHours, []),
  )
  const setCards = useCardsStore(useCallback((state) => state.setCards, []))
  const setFloors = useFloorsStore(useCallback((state) => state.setFloors, []))
  const setRooms = useRoomsStore(useCallback((state) => state.setRooms, []))
  const setMoserCard = useMoserCardStore(
    useCallback((state) => state.setMoserCard, []),
  )
  const setFirstBuildingCards = useFirstBuildingCards(
    useCallback((state) => state.setFirstCard, []),
  )
  const setCfCard = useCfCardStore(useCallback((state) => state.setCfCard, []))
  const setCfIndex = useCfIndexStore(
    useCallback((state) => state.setCfIndex, []),
  )
  const setMoserIndex = useMoserIndexStore(
    useCallback((state) => state.setMoserIndex, []),
  )
  const setActiveCard = useActiveCardStore(
    useCallback((state) => state.setActiveCard, []),
  )
  const setActiveRoom = useActiveRoomStore(
    useCallback((state) => state.setActiveRoom, []),
  )
  const setActiveCardList = useActiveCardListStore(
    useCallback((state) => state.setActiveCardList, []),
  )
  const setActiveFloor = useActiveFloorStore(
    useCallback((state) => state.setActiveFloor, []),
  )
  const setActiveBuilding = useActiveBuildingStore(
    useCallback((state) => state.setActiveBuilding, []),
  )
  const setFavorites = useFavoritesStore(
    useCallback((state) => state.setFavorites, []),
  )
  const setTags = useTagsStore(useCallback((state) => state.setTags, []))
  const tagsStore = useTagsStore(useCallback((state) => state.tags, []))
  const setTaggedCardIds = useTaggedCardIdsStore(
    useCallback((state) => state.setTaggedCardIds, []),
  )
  const setActiveTag = useActiveTagStore(
    useCallback((state) => state.setActiveTag, []),
  )
  const setActiveTagList = useActiveTagListStore(
    useCallback((state) => state.setActiveTagList, []),
  )
  const setShowMenu = useShowMenuStore(
    useCallback((state) => state.setShowMenu, []),
  )
  const setFavoriteRooms = useFavoriteRoomsStore(
    useCallback((state) => state.setFavoriteRooms, []),
  )
  const setTaggedRooms = useTaggedRoomsStore(
    useCallback((state) => state.setTaggedRooms, []),
  )
  const setNavLevel = useNavLevelStore(
    useCallback((state) => state.setNavLevel, []),
  )
  const setDetailPageId = useDetailPageStore(
    useCallback((state) => state.setDetailPageId, []),
  )
  const setTranslations = useTranslationsStore(
    useCallback((state) => state.setTranslations, []),
  )
  const setVisitorInfos = useVisitorInfosStore(
    useCallback((state) => state.setVisitorInfos, []),
  )
  const setQuicklinks = useQuicklinksStore(
    useCallback((state) => state.setQuicklinks, []),
  )
  const setDistance = useDistanceStore(
    useCallback((state) => state.setDistance, []),
  )

  useWindowSize()
  useClient()
  useBreakPoints()

  useEffect(() => {
    const foundActiveTag = tags.find((entry) => entry.id === tag) || null
    setActivePill(
      foundActiveTag && foundActiveTag.tagType.name !== 'favorites'
        ? foundActiveTag
        : null,
    )

    const foundActiveCard = cards.find((card) => card.id === cardId)

    const moserRoom = rooms.find((room) => moserCard?.roomTarget === room.id)

    if (moserRoom) {
      moserRoom.coords = MOSER_COORDS
    }

    const cfRoom = rooms.find((room) => cfCard?.roomTarget === room.id)

    if (cfRoom) {
      cfRoom.coords = CHIPPERFIELD_COORDS
    }

    const reducedRooms = rooms.reduce((acc, room) => {
      acc.push({ ...room })
      return acc
    }, [] as Room[])

    setFloors(floors)
    setRooms(reducedRooms)
    setCfCard(cfCard)
    setCfIndex(cfIndex)
    setMoserCard(moserCard)
    setMoserIndex(moserIndex)
    setFirstBuildingCards([cards[moserIndex - 1], cards[cfIndex + 1]])

    setTaggedCardIds([...stringToArray(tagIds)])

    setDistance(calcDistance(foundActiveCard || null))

    foundActiveCard && setActiveFloor(foundActiveCard.floor)
    foundActiveCard && setActiveBuilding(foundActiveCard.building)
  }, [])

  useEffect(() => {
    const foundCardById = cards.find((card) => card.id === cardId)
    const sortedCards = cards.filter((card) =>
      foundCardById?.floor === 10 ? card.floor === 10 : card.floor !== 10,
    )
    isOpenSpace.current = foundCardById?.floor === 10
    setAllCards(cards)
    setCards(sortedCards)
    setTranslations(translations)
    setTags(tags)
    setQuicklinks(quicklinks)
    setVisitorInfos(visitorInfos)
    setOpeningHours(openingHours)
    setUsefulInfos(usefulInfos)
  }, [])

  useEffect(() => {
    const foundCardById = allCardsStore.find((card) => card.id === cardId)
    setActiveCard(foundCardById || null)

    if (
      (foundCardById?.floor === 10) !== isOpenSpace.current ||
      lastLang.current !== router.locale
    ) {
      isOpenSpace.current = foundCardById?.floor === 10
      lastLang.current = router.locale

      const sortedCards = allCardsStore.filter((card) =>
        foundCardById?.floor === 10 ? card.floor === 10 : card.floor !== 10,
      )
      setCards(sortedCards)
    }
  }, [cardId, allCardsStore])

  useEffect(() => {
    const allTags = tagsStore.length ? tagsStore : tags
    const foundActiveTag = allTags.find((entry) => entry.id === tag) || null
    setActiveTag(foundActiveTag)

    if (foundActiveTag && foundActiveTag.tagType.name !== 'favorites') {
      setActiveCardList(filterCardsByTag(foundActiveTag, allCardsStore))
      return
    }

    const favoriteArray = stringToArray(favorites)

    const filteredCards = allCardsStore.filter((card) =>
      favoriteArray.find((id) => id === card.id),
    )

    setActiveCardList([...filteredCards])
  }, [tag, favoritesStore, allCardsStore])

  useEffect(() => {
    const allTags = tagsStore.length ? tagsStore : tags
    const foundActiveTagList =
      allTags.find((entry) => entry.id === tagList) || null
    setActiveTagList(foundActiveTagList)
  }, [tagList])

  useEffect(() => {
    const favoritesArray = stringToArray(favorites)
    const shouldUpdate = !arrayIsSame(favoritesStore, favoritesArray)

    shouldUpdate && setFavorites(favoritesArray)
  }, [favorites])

  useEffect(() => {
    setFavoriteRooms(
      updateFavoritesTags(roomsStore, allCardsStore, favoritesStore),
    )
  }, [favoritesStore, allCardsStore])

  useEffect(() => {
    const taggedCards = activePill
      ? filterCardsByTag(activePill, allCardsStore)
      : []
    const taggedCardIds = taggedCards.reduce((acc, { id }) => {
      acc.push(id)
      return acc
    }, [] as string[])
    setTaggedCardIds([...taggedCardIds])
    setTaggedRooms(
      updateFavoritesTags(roomsStore, allCardsStore, taggedCardIds),
    )
  }, [activePill, allCardsStore])

  useEffect(() => {
    const foundCardById =
      allCardsStore.find((card) => card.id === cardId) || null
    if (foundCardById?.id !== activeCard?.id) {
      setActiveCard(foundCardById)
      setActiveFloor(
        foundCardById && foundCardById?.floor !== null
          ? foundCardById.floor
          : null,
      )
      setActiveBuilding(foundCardById?.building || null)

      ThreeStore.set({
        activeCard: foundCardById || null,
        lastCard: foundCardById || null,
        isScrolling: false,
        orbit: !foundCardById ? INITIAL_ROTATION : ThreeStore.orbit,
        reset: false,
      })
    }

    const shouldSetRoom = !foundCardById || foundCardById.type !== 'action_card'

    shouldSetRoom &&
      setActiveRoom(
        foundCardById !== null
          ? collectSimilarCards(roomsStore, foundCardById)
          : null,
      )
  }, [cardId, roomsStore, allCardsStore])

  useEffect(() => {
    const level = maybeNumber(navLevel) || 0
    const backToMain = navLevelStore.level === 0
    const shouldShowMenu = showMenuURL === 'true'
    setNavLevel({
      ...navLevelStore,
      toMain: backToMain,
      level,
      hasCloseBtn: !showMenu,
    })
    setShowMenu(shouldShowMenu)
  }, [showMenuURL, navLevel])

  useEffect(() => {
    const id = detailPageId === '-' ? null : detailPageId
    if (id === detailPageIdStore) {
      return
    }
    setDetailPageId(id)
  }, [detailPageId])

  useEffect(() => {
    const card = allCardsStore.find((cardEntry) => cardEntry.id === cardId)
    ThreeStore.set({
      rooms: rooms,
      cards: cards,
      activeCard: card,
      lastCard: card,
      isScrolling: false,
      scrollTo: undefined,
      isPinching: false,
      isPanning: false,
      isRotating: false,
      hoveredCard: null,
      orbit: maybeNumber(orbitURL) || INITIAL_ROTATION,
      isInOverview: !card || card.type === 'building',
      distance: calcDistance(card || null),
      zoomLevel: setZoomLevel(calcDistance(card || null)),
    })
  }, [rooms, allCardsStore])

  return <RenderPage />
}

export const getServerSideProps: GetServerSideProps = withIronSessionSsr(
  async (context) => {
    const locale = getLanguage(context.locale)
    const { req } = context

    const { user } = req.session

    if (req.headers['x-forwarded-proto'] === 'http') {
      return {
        redirect: {
          destination: 'https://' + req.headers.host + req.url,
          permanent: true,
        },
      }
    }

    const {
      cards,
      rooms,
      floors,
      cfCard,
      moserCard,
      cfCardIndex,
      moserCardIndex,
      tags,
      translations,
      visitorInfos,
      quicklinks,
      openingHours,
      usefulInfos,
    } = await fetchCacheData(locale, !!user?.preview)

    return {
      props: {
        query: context.query,
        cards,
        rooms,
        floors,
        cfCard,
        moserCard,
        tags,
        cfIndex: cfCardIndex,
        moserIndex: moserCardIndex,
        translations,
        visitorInfos,
        quicklinks,
        openingHours,
        usefulInfos,
      },
    }
  },
  ironSession,
)
