import { Card } from '../../types/types'
import create from 'zustand'

export type AllCardsStore = {
  allCards: Card[]
  setAllCards: (payload: Card[]) => void
}
export const useAllCardsStore = create<AllCardsStore>((set) => ({
  allCards: [],
  setAllCards: (payload) => set(() => ({ allCards: payload })),
}))

export const getAllCards = (state: AllCardsStore) => state.allCards
