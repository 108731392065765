import create from 'zustand'

export type TaggedRoomsStore = {
  taggedRooms: string[]
  setTaggedRooms: (payload: string[]) => void
}
export const useTaggedRoomsStore = create<TaggedRoomsStore>((set) => ({
  taggedRooms: [],
  setTaggedRooms: (payload) => set(() => ({ taggedRooms: payload })),
}))

export const getTaggedRooms = (state: TaggedRoomsStore) => state.taggedRooms
