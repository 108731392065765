import { OrbitControls as OrbitControlsImpl } from 'three-stdlib/controls/OrbitControls'
import { MutableRefObject } from 'react'
import { Box3, PerspectiveCamera, Vector3 } from 'three'
import { useFrame } from '@react-three/fiber'
import ThreeStore from '../lib/store'
import { getWindowSize, useWindowSizeStore } from './store/use-window-size'

const isBlocked = {
  left: false,
  top: false,
  bottom: false,
  right: false,
}
const fromBorder = 0.5

export const usePan = (
  controller: MutableRefObject<OrbitControlsImpl | null>,
  panMovement: MutableRefObject<[number, number]>,
  boundingBox: MutableRefObject<Box3 | null>,
) => {
  const { width, height } = useWindowSizeStore(getWindowSize)

  useFrame(({ camera }) => {
    if (!controller.current) {
      return
    }
    const { isPanning } = ThreeStore
    if (!isPanning || !boundingBox.current) {
      return
    }

    const min = boundingBox.current.min.clone()
    const max = boundingBox.current.max.clone()
    const cornerTL = min.clone()
    const cornerTR = min.clone().setX(max.x)
    const cornerBL = min.clone().setZ(max.z)
    const cornerBR = max.clone().setY(min.y)

    const cornerTLToCam = cornerTL.clone()
    const cornerTRToCam = cornerTR.clone()
    const cornerBLToCam = cornerBL.clone()
    const cornerBRToCam = cornerBR.clone()

    camera.worldToLocal(cornerTLToCam)
    camera.worldToLocal(cornerTRToCam)
    camera.worldToLocal(cornerBLToCam)
    camera.worldToLocal(cornerBRToCam)

    cornerTL.project(camera)
    cornerTR.project(camera)
    cornerBL.project(camera)
    cornerBR.project(camera)

    const corners = [
      { corner: cornerTR, isBehindCam: cornerTRToCam.z > 0 },
      { corner: cornerTL, isBehindCam: cornerTLToCam.z > 0 },
      { corner: cornerBR, isBehindCam: cornerBRToCam.z > 0 },
      { corner: cornerBL, isBehindCam: cornerBLToCam.z > 0 },
    ]

    const cornersFrontOfCam = corners.filter(({ isBehindCam }) => !isBehindCam)
    const cornersBackOfCam = corners.reduce((acc, curr) => {
      if (curr.isBehindCam) {
        curr.corner.setX(-curr.corner.x)
        acc.push(curr)
      }
      return acc
    }, [] as typeof corners)

    const sortedCornersYBack = cornersBackOfCam.sort(
      ({ corner }, last) => corner.y - last.corner.y,
    )
    const sortedCornersYFront = cornersFrontOfCam.sort(
      ({ corner }, last) => corner.y - last.corner.y,
    )

    const cornersY = [...sortedCornersYBack, ...sortedCornersYFront]
    const cornersX = [...cornersFrontOfCam, ...cornersBackOfCam].sort(
      ({ corner }, last) => corner.x - last.corner.x,
    )

    const left = cornersX[0]
    const right = [...cornersX].reverse()[0]
    const bottom = cornersY[0]
    const top = [...sortedCornersYFront].reverse()[0]

    isBlocked.top = top.corner.y < fromBorder
    isBlocked.bottom = !bottom.isBehindCam && bottom.corner.y > -fromBorder
    isBlocked.left = left.corner.x < -fromBorder
    isBlocked.right = right.corner.x > fromBorder

    const offsetX = controller.current.target.clone()
    const offsetY = controller.current.target.clone()

    const position = controller.current.object.position
    const offset = new Vector3().copy(position).sub(controller.current.target)
    let targetDistance = offset.length()

    targetDistance *=
      (((camera as PerspectiveCamera).fov / 2) * Math.PI) / 180.0

    offsetX.setFromMatrixColumn(camera.matrix, 0)
    offsetY.setFromMatrixColumn(camera.matrix, 0)

    offsetX.multiplyScalar(
      -((2 * panMovement.current[0] * targetDistance) / height),
    )
    offsetY.crossVectors(camera.up, offsetY)
    offsetY.multiplyScalar(
      (2 * panMovement.current[1] * targetDistance) / height,
    )

    if (
      (isBlocked.left && panMovement.current[0] > 0) ||
      (isBlocked.right && panMovement.current[0] < 0) ||
      (!isBlocked.left && !isBlocked.right)
    ) {
      controller.current.target.add(offsetX)
    }

    if (
      (isBlocked.bottom && panMovement.current[1] > 0) ||
      (isBlocked.top && panMovement.current[1] < 0) ||
      (!isBlocked.bottom && !isBlocked.top)
    ) {
      controller.current.target.add(offsetY)
    }
  })
}
