import CloseIconSmall from '../icons/close-icon-small'
import StarIconSmall from '../icons/star-icon-small'
import { useCallback, useMemo } from 'react'
import Link from 'next/link'
import { arrayToString } from '../../lib/array-to-string'
import { filterCardsByTag } from '../../lib/filter-cards-by-tag'
import {
  getFavoritesString,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'
import { useCardsStore } from '../../hooks/store/use-cards'
import {
  getActivePill,
  setActivePillStore,
  useActivePillStore,
} from '../../hooks/store/use-active-pill'
import {
  getActiveCardString,
  useActiveCardStore,
} from '../../hooks/store/use-active-card'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'
import { Tag } from '../../types/types'

const usePillURL = (
  activePill: Tag | null,
  activeCardId: string,
  orbit: number,
  favoritesString: string,
) => {
  const getTagString = useCallback(
    (state) => {
      const filteredCardIds = activePill
        ? filterCardsByTag(activePill, state.cards)
        : []
      const taggedCardIds = filteredCardIds.reduce((acc, { id }) => {
        acc.push(id)
        return acc
      }, [] as string[])
      return arrayToString(taggedCardIds)
    },
    [activePill],
  )
  const taggedString = useCardsStore(getTagString)

  return useMemo(() => {
    return `${activeCardId}/${orbit}/-/true/2/${favoritesString}/-/${
      activePill?.id || '-'
    }/${taggedString}`
  }, [activeCardId, orbit, favoritesString, taggedString])
}

const useClearPillURL = (
  activeCardId: string,
  orbit: number,
  favoritesString: string,
) => {
  return useMemo(() => {
    return `${activeCardId}/${orbit}/-/false/0/${favoritesString}/-/-/-`
  }, [activeCardId, orbit, favoritesString])
}

export const Pill = () => {
  const activePill = useActivePillStore(getActivePill)
  const setActivePill = useActivePillStore(setActivePillStore)

  const activeCardId = useActiveCardStore(getActiveCardString)
  const orbit = useOrbitStore(getOrbit)
  const favoritesString = useFavoritesStore(getFavoritesString)

  const pillURL = usePillURL(activePill, activeCardId, orbit, favoritesString)
  const clearPillURL = useClearPillURL(activeCardId, orbit, favoritesString)

  const renderIcon = useMemo(() => {
    if (!activePill) {
      return null
    }
    if (activePill.tagType.name === 'tip') {
      return (
        <StarIconSmall className="w-4 h-4 text-typoContrast-900 flex-shrink-0" />
      )
    }
  }, [activePill])

  const pillName = useMemo(() => {
    if (activePill?.tagType.name === 'artists') {
      const surName = activePill.name.split(' ')
      return surName[surName.length - 1]
    }

    return activePill?.name
  }, [activePill])

  return activePill ? (
    <div
      className="mr-3 bg-primary-200 px-2 max-w-32 rounded-8 flex items-center pointer-events-auto cursor-pointer relative border-2 border-primary-400 overflow-x-hidden"
      style={{ lineHeight: '1' }}
    >
      <Link href={pillURL} passHref shallow>
        <a className="flex items-center overflow-hidden">
          {renderIcon}
          <div className="px-1 whitespace-nowrap overflow-ellipsis overflow-hidden py-2 flex-shrink">
            {pillName}
          </div>
        </a>
      </Link>
      <Link href={clearPillURL} passHref shallow>
        <a
          className="relative"
          onClick={() => {
            setActivePill(null)
          }}
        >
          <CloseIconSmall className="w-4 h-4 text-typoContrast-900" />
        </a>
      </Link>
    </div>
  ) : null
}
