import create from 'zustand'

export type OffsetStore = {
  offset: number
  setOffset: (payload: number) => void
}
export const useOffsetStore = create<OffsetStore>((set) => ({
  offset: 0,
  setOffset: (payload) => set(() => ({ offset: payload })),
}))

export const getOffset = (state: OffsetStore) => state.offset
