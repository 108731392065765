import create from 'zustand'

export type IsReadyStore = {
  isReady: boolean
  setIsReady: (payload: boolean) => void
}
export const useIsReadyStore = create<IsReadyStore>((set) => ({
  isReady: false,
  setIsReady: (payload) => set(() => ({ isReady: payload })),
}))

export const getIsReady = (state: IsReadyStore) => state.isReady
