import { Room } from '../../types/types'
import create from 'zustand'

export type RoomsStore = {
  rooms: Room[]
  setRooms: (payload: Room[]) => void
}
export const useRoomsStore = create<RoomsStore>((set) => ({
  rooms: [],
  setRooms: (payload) => set(() => ({ rooms: payload })),
}))

export const getRooms = (state: RoomsStore) => state.rooms
