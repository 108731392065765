import { SVGProps } from 'react'

export const IconChevronRight = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4519 7.75925C9.24911 7.56278 9.24911 7.23748 9.4519 7.04102L10.1784 6.33717C10.3723 6.14933 10.6803 6.14933 10.8742 6.33717L16.349 11.641C16.5518 11.8375 16.5518 12.1628 16.349 12.3592L10.8742 17.6631C10.6803 17.8509 10.3723 17.8509 10.1784 17.6631L9.4519 16.9592C9.24911 16.7628 9.24911 16.4375 9.4519 16.241L13.4588 12.3592C13.6616 12.1628 13.6616 11.8375 13.4588 11.641L9.4519 7.75925Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}
export default IconChevronRight
