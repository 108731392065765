import { SVGProps } from 'react'

const ArrowForwardIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`text-typo-100 ${props.className}`}
    >
      <path
        d="M10.9432 5.05669L11.6464 4.35348C11.8417 4.15822 12.1583 4.15822 12.3536 4.35348L19.6464 11.6464C19.8417 11.8416 19.8417 12.1582 19.6464 12.3535L12.3536 19.6464C12.1583 19.8416 11.8417 19.8416 11.6464 19.6464L10.9432 18.9432C10.7481 18.748 10.748 18.4317 10.9429 18.2364L16.17 12.9999H4.5C4.22386 12.9999 4 12.7761 4 12.4999V11.4999C4 11.2238 4.22386 10.9999 4.5 10.9999H16.17L10.9429 5.76348C10.748 5.56816 10.7481 5.25183 10.9432 5.05669Z"
        fill="currentColor"
        {...pathProps}
      />
    </svg>
  )
}

export default ArrowForwardIcon
