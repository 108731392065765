import { SVGProps } from 'react'

const ArrowUpLargeIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
      {...props}
    >
      <path
        fill="#F0F0F0"
        d="M4.74996 13.6464C4.5547 13.8417 4.5547 14.1583 4.74996 14.3536L5.45707 15.0607C5.65233 15.2559 5.96891 15.2559 6.16418 15.0607L16.6034 4.62139L16.6034 33.7072C16.6034 33.9833 16.8273 34.2072 17.1034 34.2072H18.1034C18.3796 34.2072 18.6034 33.9833 18.6034 33.7072L18.6034 4.62124L29.0429 15.0607C29.2381 15.2559 29.5547 15.2559 29.75 15.0607L30.4571 14.3536C30.6523 14.1583 30.6523 13.8417 30.4571 13.6464L17.9571 1.14645C17.7618 0.951184 17.4452 0.951184 17.25 1.14645L4.74996 13.6464Z"
        {...pathProps}
      />
    </svg>
  )
}

export default ArrowUpLargeIcon
