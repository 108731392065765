import create from 'zustand'

export type NavLevelStore = {
  navLevel: { level: number; toMain?: boolean; hasCloseBtn?: boolean }
  setNavLevel: (payload: {
    level: number
    toMain?: boolean
    hasCloseBtn?: boolean
  }) => void
}
export const useNavLevelStore = create<NavLevelStore>((set) => ({
  navLevel: { level: -1, toMain: true },
  setNavLevel: (payload) => set(() => ({ navLevel: payload })),
}))

export const getNavLevel = (state: NavLevelStore) => state.navLevel
