import { SVGProps } from 'react'

const RampIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6125 5.58712C11.5991 5.46937 12.3044 4.57372 12.1867 3.58712C12.069 2.59975 11.1733 1.89515 10.1867 2.01284C9.19936 2.12983 8.49476 3.02548 8.61245 4.01284C8.72944 4.99945 9.62509 5.70481 10.6125 5.58712ZM9.79993 15.2001C10.9942 15.2001 12.0527 14.6186 12.7076 13.7232L13.1468 15.0408C12.2822 15.882 11.1015 16.4001 9.79993 16.4001C7.149 16.4001 5 14.2511 5 11.6002C5 9.82352 5.96526 8.27231 7.39997 7.44238V8.91688C6.66348 9.57606 6.19998 10.534 6.19998 11.6002C6.19998 13.5884 7.81174 15.2001 9.79993 15.2001ZM9.79964 6.2002C9.13692 6.2002 8.59967 6.73743 8.59966 7.40016L8.59961 10.4001C8.59961 11.6001 9.19879 12.2001 10.3996 12.2001L13.3995 12.2001L14.4098 15.2309C14.5146 15.5452 14.8544 15.7151 15.1687 15.6103L15.8303 15.3898C16.1447 15.285 16.3146 14.9452 16.2098 14.6309L14.7363 10.2104C14.6546 9.9654 14.4253 9.80014 14.1671 9.80014L12.1996 9.80012C11.8682 9.80012 11.5996 9.5315 11.5996 9.20013V8.60016H13.3995C13.7309 8.60016 13.9995 8.33154 13.9995 8.00017C13.9995 7.6688 13.7309 7.40018 13.3995 7.40018H12.1996C11.8682 7.40018 11.5996 7.13155 11.5996 6.80019C11.5996 6.46882 11.3309 6.2002 10.9996 6.2002H9.79964ZM4.9576 21C4.79231 21 4.75727 20.767 4.91525 20.7184L18.8233 16.439C19.2091 16.3203 19.5997 16.6088 19.5997 17.0124V20.4C19.5997 20.7314 19.3311 21 18.9998 21H4.9576Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default RampIcon
