import { Card } from '../../types/types'
import ArrowForwardIcon from '../icons/arrow-forward-icon'
import ArrowBackIcon from '../icons/arrow-back-icon'
import {
  CARD_HEIGHT,
  CARD_WIDTH,
  CARD_WIDTH_DESKTOP,
} from '../../lib/static-values'
import { RippleEffect } from '../ripple/ripple'
import ArrowDownIcon from '../icons/arrow-down-icon'
import ArrowUpIcon from '../icons/arrow-up-icon'
import { memo, useCallback, useMemo } from 'react'
import Link from 'next/link'
import { CardsStore, useCardsStore } from '../../hooks/store/use-cards'
import {
  ActiveCardStore,
  ActiveFloorStore,
  getActiveBuilding,
  useActiveBuildingStore,
  useActiveCardStore,
  useActiveFloorStore,
} from '../../hooks/store/use-active-card'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'
import {
  getFavoritesString,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'
import {
  getTaggedString,
  useTaggedCardIdsStore,
} from '../../hooks/store/use-tagged-card-ids'
import {
  getActiveTagListString,
  useActiveTagListStore,
} from '../../hooks/store/use-active-tag-list'
import {
  getActiveTagString,
  useActiveTagStore,
} from '../../hooks/store/use-active-tag'
import { getHasTouch, useHasTouchStore } from '../../hooks/store/use-has-touch'

const useJumpToCard = (cardId: string | null) => {
  const getActiveCard = useCallback((state: CardsStore) => {
    if (cardId === null) {
      return undefined
    }
    return state.cards.find((entry) => entry.id === cardId)
  }, [])
  return useCardsStore(getActiveCard)
}

const useIsActive = (card: Card) => {
  const activeBuilding = useActiveBuildingStore(getActiveBuilding)
  const getActiveCard = useCallback(
    (state: ActiveFloorStore) => {
      if (
        card.isInactive ||
        state.activeFloor === null ||
        card.floor === null
      ) {
        return false
      }
      return (
        Math.floor(state.activeFloor) === Math.floor(card.floor) &&
        activeBuilding === card.building
      )
    },
    [activeBuilding],
  )
  return useActiveFloorStore(getActiveCard)
}

const useCardLink = (cardId: string, jumpId: string | null) => {
  const favoritesString = useFavoritesStore(getFavoritesString)
  const taggedString = useTaggedCardIdsStore(getTaggedString)

  const getNextCardId = useCallback(
    (state: ActiveCardStore) =>
      state.activeCard?.id === cardId ? jumpId || '-' : cardId,
    [cardId, jumpId],
  )
  const nextCardId = useActiveCardStore(getNextCardId)
  const orbit = useOrbitStore(getOrbit)
  const activeTagListId = useActiveTagListStore(getActiveTagListString)
  const activeTagId = useActiveTagStore(getActiveTagString)

  return useMemo(() => {
    return `${nextCardId}/${orbit}/-/false/0/${favoritesString}/${activeTagListId}/${activeTagId}/${taggedString}`
  }, [
    nextCardId,
    orbit,
    favoritesString,
    activeTagListId,
    activeTagId,
    taggedString,
  ])
}

export const ActionCard = memo(
  ({
    card,
    cardRef,
  }: {
    card: Card
    cardRef: (card: HTMLAnchorElement | null) => void
  }) => {
    const jumpToCard = useJumpToCard(card.jumpToIndex)
    const cardLink = useCardLink(card.id, card.jumpToIndex)
    const isActive = useIsActive(card)

    const hasTouch = useHasTouchStore(getHasTouch)
    const { title } = card

    return (
      <Link href={cardLink} passHref shallow>
        <a
          ref={cardRef}
          data-id={card.id}
          className={`px-px desktop:px-0 desktop:py-px relative z-50 h-full desktop:h-auto desktop:min-h-[140px] flex ${
            !isActive ? 'text-typo-300' : 'text-typo-100'
          }`}
          style={{
            width: hasTouch ? `${CARD_WIDTH}px` : `${CARD_WIDTH_DESKTOP}px`,
            height: hasTouch ? `${CARD_HEIGHT}px` : undefined,
          }}
        >
          <div
            className={`absolute top-0 desktop:top-px left-px desktop:left-0 right-px desktop:right-0 bottom-0 desktop:bottom-px ${
              !isActive ? 'bg-bg-800' : 'bg-primary-200'
            } cursor-pointer`}
          >
            <RippleEffect className="w-full h-full" />
          </div>
          <div className="relative flex flex-col desktop:flex-row pt-3 pb-5 desktop:pb-3 px-4 flex-grow pointer-events-none">
            <div
              className={`font-black text-lg leading-none ${
                !isActive ? 'text-typoContrast-700' : 'text-typoContrast-900'
              }`}
            >
              {title}
            </div>
            <div className="relative w-full flex justify-end items-end desktop:justify-center desktop:items-center flex-grow desktop:w-[140px]">
              <div className="pointer-events-none">
                {jumpToCard &&
                  jumpToCard.index !== undefined &&
                  card.index !== undefined &&
                  (card.index < jumpToCard.index ? (
                    <>
                      <ArrowForwardIcon
                        className={`${
                          !isActive
                            ? 'text-typoContrast-700'
                            : 'text-typoContrast-900'
                        } desktop:hidden`}
                      />
                      <ArrowDownIcon
                        className={`${
                          !isActive
                            ? 'text-typoContrast-700'
                            : 'text-typoContrast-900'
                        } hidden desktop:block`}
                      />
                    </>
                  ) : (
                    <>
                      <ArrowBackIcon
                        className={`${
                          !isActive
                            ? 'text-typoContrast-700'
                            : 'text-typoContrast-900'
                        } desktop:hidden`}
                      />
                      <ArrowUpIcon
                        className={`${
                          !isActive
                            ? 'text-typoContrast-700'
                            : 'text-typoContrast-900'
                        } hidden desktop:block`}
                      />
                    </>
                  ))}
              </div>
            </div>
          </div>
        </a>
      </Link>
    )
  },
  (prev, next) => prev.card === next.card,
)
