import { HTMLProps } from 'react'
import colors from '../../data/colors'
import { Card, Colors } from '../../types/types'
import { RippleEffect } from '../ripple/ripple'
import { Image } from 'react-datocms'

export const ListViewCard = ({
  card,
  ...props
}: HTMLProps<HTMLDivElement> & {
  card: Card
}) => {
  const { title, subtitle, secondaryColor, img, color, isInactive } = card
  const isComingSoon = title === 'Coming soon'

  return (
    <div
      {...props}
      className={`px-0 py-px relative z-50 h-[92px] flex ${
        isInactive ? 'text-typo-300' : 'text-typo-100'
      } ${props.className}`}
      onClick={() => null}
    >
      <div
        className={`absolute top-px left-0 right-0 bottom-px bg-bg-800 cursor-pointer ${
          isComingSoon ? 'bg-bg-800' : ''
        }`}
      >
        <RippleEffect className="w-full h-full" onClick={props.onClick} />
      </div>
      <div className="z-10 flex w-full pointer-events-none">
        <div
          className="w-full w-1 bg-white h-full flex-none"
          style={{
            backgroundColor:
              isInactive || isComingSoon
                ? colors.bg[800]
                : (colors as Colors)[`${color || 'section9'}`][
                    `${secondaryColor ? 200 : 400}`
                  ],
          }}
        />
        <div className={'flex flex-col py-3 flex-grow'}>
          <div className="px-4 pb-0 font-black text-lg leading-none">
            {title}
          </div>
          <div className="px-4 pt-1 text-sm font-normal leading-none min-h-[20px]">
            {subtitle}
          </div>
        </div>

        <div className="right-0 bottom-0 relative h-full w-[92px] flex items-center justify-center flex-shrink-0 overflow-hidden">
          {img && (
            <>
              <Image data={img.responsiveImage} className="z-0" />
              {!isComingSoon && isInactive && (
                <div className="absolute left-0 top-0 w-full h-full mix-blend-multiply bg-dark opacity-80" />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
