import { RefObject, SVGProps } from 'react'

const CompassIcon = ({
  svgRef,
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
  svgRef: RefObject<SVGSVGElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      ref={svgRef}
    >
      <path
        fill="#F0F0F0"
        d="M11.6422 4.23568L8 12.0001H16L12.3578 4.23568C12.2104 3.92144 11.7896 3.92144 11.6422 4.23568Z"
        {...pathProps}
      />
      <path
        fill="#FE6F6F"
        d="M12.3578 19.7643L16 11.9999L8 11.9999L11.6422 19.7643C11.7896 20.0786 12.2104 20.0786 12.3578 19.7643Z"
        {...pathProps}
      />
    </svg>
  )
}

export default CompassIcon
