import { useCallback, useEffect } from 'react'
import { useIsClientStore } from './store/use-is-client'

export const useClient = () => {
  const setIsClient = useIsClientStore(
    useCallback((state) => state.setIsClient, []),
  )

  useEffect(() => {
    setIsClient(window !== undefined)
  }, [])
}
