import { OrbitControls as OrbitControlsImpl } from 'three-stdlib/controls/OrbitControls'
import { useFrame } from '@react-three/fiber'
import ThreeStore from '../lib/store'
import { MutableRefObject, useCallback } from 'react'
import { MathUtils } from 'three'
import { useCompassRotationStore } from './store/use-compass-rotation'

export const useRotate = (
  controller: MutableRefObject<OrbitControlsImpl | null>,
) => {
  const setCompassRotation = useCompassRotationStore(
    useCallback((state) => state.setCompassRotation, []),
  )
  useFrame(() => {
    const { isRotating, orbit } = ThreeStore
    if (!controller.current) {
      return
    }
    if (!isRotating) {
      return
    }
    controller.current.maxAzimuthAngle = MathUtils.degToRad(orbit)
    controller.current.minAzimuthAngle = MathUtils.degToRad(orbit)
    setCompassRotation(orbit)
  })
}
