import { Canvas } from '@react-three/fiber'
import { Box3, Color } from 'three'
import { memo, Suspense, useCallback, useRef } from 'react'
import { Controller } from './controller'
import { Loader } from '../loader/loader'
import { MAX_DISTANCE, MIN_DISTANCE } from '../../lib/static-values'
import { ScreenPosition } from '../screen-pos'
import { useGesture } from 'react-use-gesture'
import { useSpring } from '@react-spring/three'
import { RenderMap } from '../model/render-map'
import { RenderBuildings } from '../model/render-building'
import colors from '../../data/colors'
import { SetBoundingBox } from './set-bounding-box'
import ThreeStore from '../../lib/store'
import { setZoomLevel as calcZoomLevel } from '../../lib/set-zoom-level'
import { useRouter } from 'next/router'
import {
  getActiveTagString,
  useActiveTagStore,
} from '../../hooks/store/use-active-tag'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'
import {
  getActiveTagListString,
  useActiveTagListStore,
} from '../../hooks/store/use-active-tag-list'
import {
  getFavoritesString,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'
import {
  getTaggedString,
  useTaggedCardIdsStore,
} from '../../hooks/store/use-tagged-card-ids'
import {
  getMoserCard as getMoserCardStore,
  useMoserCardStore,
} from '../../hooks/store/use-moser-card'
import { RoomsStore, useRoomsStore } from '../../hooks/store/use-rooms'
import {
  getcfCard as getCfCardStore,
  useCfCardStore,
} from '../../hooks/store/use-cf-card'
import {
  ActiveCardStore,
  useActiveCardStore,
} from '../../hooks/store/use-active-card'
import { getIsClient, useIsClientStore } from '../../hooks/store/use-is-client'
import { getHasTouch, useHasTouchStore } from '../../hooks/store/use-has-touch'

const SHADOW_FRUSTUM = 250

type Props = {
  canvasProps?: typeof Canvas
}

const useRoomPath = () => {
  const favoritesString = useFavoritesStore(getFavoritesString)
  const taggedString = useTaggedCardIdsStore(getTaggedString)
  const activeTagListId = useActiveTagListStore(getActiveTagListString)
  const orbit = useOrbitStore(getOrbit)
  const activeTagId = useActiveTagStore(getActiveTagString)
  const router = useRouter()

  return useCallback(
    (id: string) => {
      if (
        ThreeStore.isPinching ||
        ThreeStore.isPanning ||
        ThreeStore.isRotating
      ) {
        return
      }
      router.push(
        `${id}/${orbit}/-/false/0/${favoritesString}/${activeTagListId}/${activeTagId}/${taggedString}`,
        undefined,
        { shallow: true },
      )
    },
    [favoritesString, orbit, activeTagListId, activeTagId, taggedString],
  )
}

const useMoserRoom = () => {
  const moserCard = useMoserCardStore(getMoserCardStore)
  const getMoserCard = useCallback(
    (state: RoomsStore) => {
      return state.rooms.find((room) => moserCard?.roomTarget === room.id)
    },
    [moserCard],
  )
  return useRoomsStore(getMoserCard)
}
const useCfRoom = () => {
  const cfCard = useCfCardStore(getCfCardStore)
  const getCfCard = useCallback(
    (state: RoomsStore) => {
      return state.rooms.find((room) => cfCard?.roomTarget === room.id)
    },
    [cfCard],
  )
  return useRoomsStore(getCfCard)
}
const useInOverview = () => {
  const isInOverview = useCallback((state: ActiveCardStore) => {
    return !state.activeCard || state.activeCard.type === 'building'
  }, [])
  return useActiveCardStore(isInOverview)
}

export const Container = memo(
  ({ canvasProps }: Props) => {
    const setOrbit = useOrbitStore(useCallback((state) => state.setOrbit, []))
    const isClient = useIsClientStore(getIsClient)
    const cfRoom = useCfRoom()
    const moserRoom = useMoserRoom()
    const isInOverview = useInOverview()

    const isAnimating = useRef(false)
    const moserBoundingBox = useRef<Box3 | null>(null)
    const cfBoundingBox = useRef<Box3 | null>(null)
    const exteriorBoundingBox = useRef<Box3 | null>(null)
    const boundingBox = useRef<Box3 | null>(null)
    const panOffset = useRef<[number, number]>([0, 0])
    const currentDistance = useRef(MAX_DISTANCE)
    const hasTouch = useHasTouchStore(getHasTouch)

    const handleRoomClick = useRoomPath()

    const isImmediate = useRef(true)

    const currentMX = useRef(ThreeStore.orbit)

    const onRotate = (a: number) => {
      const setAngle = currentMX.current + a

      if (Math.abs(setAngle) > 360) {
        const fullRotations = Math.abs(Math.floor(setAngle / 360))
        const removeFullRotation = Math.abs(setAngle) - 360 * fullRotations
        ThreeStore.set({
          orbit: setAngle >= 0 ? removeFullRotation : -removeFullRotation,
        })
      } else {
        ThreeStore.set({
          orbit: setAngle,
        })
      }
    }

    const [, setAnimatedProps] = useSpring(() => ({
      active: false,
      distance: 0,
      pan: [0, 0] as [number, number],
      immediate: isImmediate.current,
      onStart: () => {
        isAnimating.current = true
      },
      onChange: ({ value }) => {
        const zoomLevel = calcZoomLevel(value.distance)

        ThreeStore.set({
          distance:
            value.distance < MIN_DISTANCE ? MIN_DISTANCE : value.distance,
          zoomLevel,
        })
      },
      onRest: ({ value }) => {
        isAnimating.current = false
        isImmediate.current = false
        if (value.active) {
          return
        }
        if (ThreeStore.distance > MAX_DISTANCE) {
          setAnimatedProps({ distance: MAX_DISTANCE })
          ThreeStore.set({ isPinching: true })
        } else {
          setTimeout(() => {
            ThreeStore.set({ isPinching: false, isRotating: false })
          }, 500)
        }
      },
    }))

    const bind = useGesture({
      onPinchStart: () => {
        currentMX.current = ThreeStore.orbit
        currentDistance.current = ThreeStore.distance
      },
      onPinch: ({ movement: [distance, a], active }) => {
        if (!hasTouch) {
          return
        }

        ThreeStore.set({ isRotating: true })

        onRotate(a)

        if (isInOverview) {
          return
        }
        ThreeStore.set({ isPinching: true })

        const nextDistance = currentDistance.current - distance

        setAnimatedProps({
          distance: nextDistance,
          immediate: true,
          active: active,
        })
      },
      onDragStart: ({ buttons }) => {
        if (buttons !== 2 || ThreeStore.isPinching || ThreeStore.isRotating) {
          return
        }
        currentMX.current = ThreeStore.orbit
        ThreeStore.set({
          isPanning: true,
          isPinching: false,
          isRotating: false,
        })
      },
      onDrag: ({ movement: [x, y], active, delta: [dx, dy], buttons }) => {
        if (buttons === 2) {
          if (!hasTouch) {
            onRotate(-x)
            ThreeStore.set({ isRotating: true, isPanning: false })
          }
          return
        }

        if (ThreeStore.isRotating || ThreeStore.isPinching) {
          ThreeStore.set({ isPanning: false })
          return
        }

        if (x > 2 || x < -2 || y > 2 || y < -2) {
          ThreeStore.set({ isPanning: true })
        }
        if (active) {
          panOffset.current = [Math.round(dx), Math.round(dy)]
        } else {
          panOffset.current = [0, 0]
        }
      },
      onDragEnd: () => {
        setTimeout(() => {
          setOrbit(ThreeStore.orbit)
          ThreeStore.set({
            isPanning: false,
            isRotating: false,
          })
        }, 50)
      },
    })

    return (
      <div {...bind()} className="w-full h-full">
        <Canvas
          linear
          shadows={{ enabled: true }}
          {...canvasProps}
          gl={{ alpha: false, antialias: true }}
          camera={{ position: [100, 100, 0], fov: 40, near: 20, far: 2000 }}
          onCreated={({ gl, camera }) => {
            gl.setClearColor(new Color(colors.bg['900']))
            camera.layers.enableAll()
          }}
          dpr={Math.max(isClient ? window.devicePixelRatio : 2, 2)}
        >
          {/*<Stats />*/}
          <ambientLight intensity={0.6} color={new Color(1, 1, 1)} />
          <directionalLight
            position={[50, 100, -25]}
            intensity={0.325}
            color={new Color(1, 1, 1)}
            castShadow
            shadowCameraFar={SHADOW_FRUSTUM}
            shadowCameraRight={SHADOW_FRUSTUM}
            shadowCameraLeft={-SHADOW_FRUSTUM}
            shadowCameraTop={SHADOW_FRUSTUM}
            shadowCameraBottom={-SHADOW_FRUSTUM}
            shadowMapWidth={2048}
            shadowMapHeight={2048}
            shadowBias={-0.002}
          />
          <directionalLight
            position={[-50, 100, -25]}
            intensity={0.1}
            color={new Color(0.99, 0.89, 0.72)}
          />
          <Suspense fallback={<Loader />}>
            <RenderBuildings
              handleBuildingClick={handleRoomClick}
              url="/models/exterior.glb"
              cfRoom={cfRoom}
              moserRoom={moserRoom}
              boundingBox={exteriorBoundingBox}
            />
            <RenderMap
              url="/models/moser.glb"
              handleRoomClick={handleRoomClick}
              boundingBox={moserBoundingBox}
            />
            <RenderMap
              url="/models/chipperfield.glb"
              handleRoomClick={handleRoomClick}
              boundingBox={cfBoundingBox}
            />
          </Suspense>
          <ScreenPosition />

          <Controller boundingBox={boundingBox} panMovement={panOffset} />
          <SetBoundingBox
            boundingBox={boundingBox}
            exteriorBoundingBox={exteriorBoundingBox}
            moserBoundingBox={moserBoundingBox}
            cfBoundingBox={cfBoundingBox}
          />
        </Canvas>
      </div>
    )
  },
  () => true,
)
