import { Tag } from '../../types/types'
import create from 'zustand'

export type ActiveTagStore = {
  activeTag: Tag | null
  setActiveTag: (payload: Tag | null) => void
}
export const useActiveTagStore = create<ActiveTagStore>((set) => ({
  activeTag: null,
  setActiveTag: (payload) => set(() => ({ activeTag: payload })),
}))

export const getActiveTag = (state: ActiveTagStore) => state.activeTag

export const getActiveTagString = (state: ActiveTagStore) =>
  state.activeTag?.id || '-'
