import colors from '../../data/colors'
import { Colors, DetailPage } from '../../types/types'
import LikeFilledIcon from '../icons/like-filled-icon'
import LikeOutlineIcon from '../icons/like-outline-icon'
import { RefObject, useMemo } from 'react'
import {
  getFavorites,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'

export const CardDetailLikeBtn = ({
  card,
  onClick,
  isLikedRef,
  className,
  likeWrapperRef,
}: {
  card: DetailPage
  onClick?: () => void
  isLikedRef: RefObject<HTMLDivElement>
  likeWrapperRef?: RefObject<HTMLButtonElement>
  className?: string
}) => {
  const favorites = useFavoritesStore(getFavorites)
  const isFavorite = useMemo(() => {
    return !!favorites.find((id) => id === card.id)
  }, [card, favorites])

  return (
    <button
      className={`w-13 h-13 justify-center items-center rounded-full cursor-pointer ${className}`}
      style={{
        backgroundColor: (colors as Colors)[`${card.color || 'section9'}`][
          `${card.secondaryColor ? 200 : 400}`
        ],
      }}
      ref={likeWrapperRef}
      onClick={onClick}
    >
      <div className="relative">
        <div
          ref={isLikedRef}
          className={`${isFavorite ? '' : 'opacity-0'} absolute top-0 left-0`}
        >
          <LikeFilledIcon
            className="text-bg-800"
            pathProps={{ fill: 'currentColor' }}
          />
        </div>
        <LikeOutlineIcon
          className="text-bg-800"
          pathProps={{ fill: 'currentColor' }}
        />
      </div>
    </button>
  )
}
