import ExitIcon from './exit-icon'
import ElevatorIcon from './elevator-icon'
import CafeIcon from './cafe-icon'
import ToiletsIcon from './toilets-icon'
import EntranceIcon from './entrance-icon'
import TicketsIcon from './tickets-icon'
import InfoIcon from './info-icon'
import RestaurantIcon from './restaurant-icon'
import GiftShopIcon from './giftshop-icon'
import BarIcon from './bar-icon'
import WardrobeIcon from './wardrobe-icon'
import { SVGProps } from 'react'
import RampIcon from './ramp-icon'
import AudioGuideIcon from './audioguide-icon'
import MeetingPointIcon from './meetingpoint-icon'
import ParkingIcon from './parking-icon'
import LinkIcon from './link-icon'
import DisabledParkingIcon from './disabled-parking-icon'

export const hasIcon = (name: string) => {
  return (
    !!name.match(/exit/) ||
    !!name.match(/elevator/) ||
    !!name.match(/cafe/) ||
    !!name.match(/toilets/) ||
    !!name.match(/entrance/) ||
    !!name.match(/ramp/) ||
    !!name.match(/mainentrance/) ||
    !!name.match(/tickets/) ||
    !!name.match(/info/) ||
    !!name.match(/restaurant/) ||
    !!name.match(/giftshop/) ||
    !!name.match(/bar/) ||
    !!name.match(/wardrobe/) ||
    !!name.match(/audioguide/) ||
    !!name.match(/meetingpoint/) ||
    !!name.match(/carpark/) ||
    !!name.match(/carpark-disabled/)
  )
}

export const renderIcon = ({
  name,
  key,
  props,
  pathProps,
  withBG = true,
  backgroundColor = 'default',
}: {
  name: string
  key?: string
  props?: SVGProps<SVGSVGElement>
  pathProps?: SVGProps<SVGPathElement>
  withBG?: boolean
  backgroundColor?: 'default' | 'currentColor'
}) => {
  const icon = () => {
    switch (true) {
      case !!name.match(/exit/):
        return <ExitIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
      case !!name.match(/elevator/):
        return (
          <ElevatorIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
        )
      case !!name.match(/cafe/):
        return <CafeIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
      case !!name.match(/toilets/):
        return (
          <ToiletsIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
        )
      case !!name.match(/entrance/) || !!name.match(/mainentrance/):
        return (
          <EntranceIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
        )
      case !!name.match(/tickets/):
        return (
          <TicketsIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
        )
      case !!name.match(/info/):
        return <InfoIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
      case !!name.match(/restaurant/):
        return (
          <RestaurantIcon
            key={`icon_${key}`}
            {...props}
            pathProps={pathProps}
          />
        )
      case !!name.match(/giftshop/):
        return (
          <GiftShopIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
        )
      case !!name.match(/bar/):
        return <BarIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
      case !!name.match(/wardrobe/):
        return (
          <WardrobeIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
        )
      case !!name.match(/ramp/):
        return <RampIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
      case !!name.match(/meetingpoint/):
        return (
          <MeetingPointIcon
            key={`icon_${key}`}
            {...props}
            pathProps={pathProps}
          />
        )
      case !!name.match(/carpark([-]*\d*)$/):
        return (
          <ParkingIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
        )
      case !!name.match(/carpark-disabled/):
        return (
          <DisabledParkingIcon
            key={`icon_${key}`}
            {...props}
            pathProps={pathProps}
          />
        )
      case !!name.match(/link/):
        return <LinkIcon key={`icon_${key}`} {...props} pathProps={pathProps} />
      case !!name.match(/audioguide/):
        return (
          <AudioGuideIcon
            key={`icon_${key}`}
            {...props}
            pathProps={pathProps}
          />
        )
      default:
        return null
    }
  }
  return withBG ? (
    <div
      key={key}
      className={`rounded-full w-8 h-8 flex justify-center items-center ${
        backgroundColor === 'default' ? 'bg-bg-800' : 'bg-current'
      }`}
    >
      {icon()}
    </div>
  ) : (
    icon()
  )
}
