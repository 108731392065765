import { MenuNav } from './menu-nav'
import { useEffect, useRef, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import { AlphabeticListView } from '../alphabetic-list-view/alphabetic-list-view'
import { ListView } from '../list-view/list-view'
import { getTags, useTagsStore } from '../../hooks/store/use-tags'
import { getNavLevel, useNavLevelStore } from '../../hooks/store/use-nav-leve'
import { getShowMenu, useShowMenuStore } from '../../hooks/store/use-show-menu'
import {
  getIsLoading,
  useIsLoadingStore,
} from '../../hooks/store/use-is-loading'

export const Menu = () => {
  const tags = useTagsStore(getTags)
  const navLevel = useNavLevelStore(getNavLevel)
  const showMenu = useShowMenuStore(getShowMenu)
  const isInitial = useRef(true)
  const isLoading = useIsLoadingStore(getIsLoading)

  const [isAnimating, setIsAnimating] = useState(false)
  const [currentNavLevel, setCurrentNavLevel] = useState(tags.length ? 2 : 0)

  const navElements = useRef(0)
  const wrapperElement = useRef<HTMLDivElement | null>(null)

  const { x } = useSpring({
    from: {
      x: 0,
    },
    to: {
      x: 100,
    },
    onStart: () => {
      setIsAnimating(true)
      if (!wrapperElement.current) {
        return
      }
      wrapperElement.current.style.height = `${navElements.current}px`
    },
    onRest: () => {
      isInitial.current = false
      setIsAnimating(false)
      setCurrentNavLevel(navLevel.level)
      if (!wrapperElement.current) {
        return
      }
      wrapperElement.current.style.height = 'auto'
    },
  })

  useEffect(() => {
    x.start({
      reset: true,
      immediate: navLevel.hasCloseBtn,
      config: { duration: navLevel.hasCloseBtn ? undefined : 250 },
    })
  }, [navLevel])

  const moveDirection = (move: number, level: number) => {
    switch (true) {
      case currentNavLevel === level && navLevel.level > level && isAnimating:
        return -move
      case currentNavLevel === level && navLevel.level < level && isAnimating:
        return move
      case currentNavLevel > level && isAnimating:
        return -100 + move
      case currentNavLevel < level && isAnimating:
        return 100 - move
      default:
        return 0
    }
  }
  const shouldShowNavLevel = (level: number) => {
    switch (true) {
      case currentNavLevel === level:
        return true
      case currentNavLevel > navLevel.level && navLevel.level == level:
        return true
      case currentNavLevel < navLevel.level && navLevel.level == level:
        return true
      default:
        return false
    }
  }
  const shouldSetAbsolute = (level: number) => {
    switch (true) {
      case level < currentNavLevel && isAnimating:
        return true
      case level === currentNavLevel && navLevel.level > level && isAnimating:
        return true
      default:
        return false
    }
  }

  return (
    <nav
      className={`absolute top-0 left-0 pointer-events-auto z-[60] w-full h-full overflow-y-auto text-white px-5 pointer-event-auto bg-bg-900 md:pt-20 ${
        showMenu && !isLoading ? 'block' : 'hidden'
      }`}
    >
      <div
        ref={wrapperElement}
        className={`relative md:w-[502px] lg:w-[672px] md:mx-auto`}
        style={{
          height: 'auto',
          overflowX: isAnimating ? 'hidden' : 'visible',
        }}
      >
        {shouldShowNavLevel(0) && (
          <animated.div
            style={{
              position: shouldSetAbsolute(0) ? 'absolute' : 'relative',
              width: '100%',
              height: '100%',
              transform: x.to((move) => {
                const direction = moveDirection(move, 0)
                return direction !== 0 ? `translate3d(${direction}%,0,0)` : ''
              }),
            }}
          >
            <MenuNav
              ref={(ref) => {
                if (!ref) {
                  return
                }
                if (ref.offsetHeight < navElements.current) {
                  return
                }
                navElements.current = ref.offsetHeight
              }}
            />
          </animated.div>
        )}
        {shouldShowNavLevel(1) && (
          <animated.div
            style={{
              position: shouldSetAbsolute(1) ? 'absolute' : 'relative',
              width: '100%',
              height: '100%',
              transform: x.to((move) => {
                const direction = moveDirection(move, 1)
                return direction !== 0 ? `translate3d(${direction}%,0,0)` : ''
              }),
            }}
          >
            <AlphabeticListView />
          </animated.div>
        )}
        {shouldShowNavLevel(2) && (
          <animated.div
            style={{
              position: shouldSetAbsolute(2) ? 'absolute' : 'relative',
              width: '100%',
              height: '100%',
              transform: x.to((move) => {
                const direction = moveDirection(move, 2)
                return direction !== 0 ? `translate3d(${direction}%,0,0)` : ''
              }),
            }}
          >
            <ListView
              ref={(ref) => {
                if (!ref) {
                  return
                }
                if (ref.offsetHeight < navElements.current) {
                  return
                }
                navElements.current = ref.offsetHeight
              }}
            />
          </animated.div>
        )}
      </div>
    </nav>
  )
}
