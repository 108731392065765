import { SVGProps } from 'react'

const MeetingPointIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.14645 2.85355C1.95118 3.04882 1.95118 3.3654 2.14645 3.56066L5.58579 7H3.5C3.22386 7 3 7.22386 3 7.5L3 8.5C3 8.77614 3.22386 9 3.5 9H8H8.5C8.77614 9 9 8.77614 9 8.5V8V3.5C9 3.22386 8.77614 3 8.5 3H7.5C7.22386 3 7 3.22386 7 3.5V5.58579L3.56066 2.14645C3.3654 1.95118 3.04882 1.95118 2.85355 2.14645L2.14645 2.85355ZM17 5.58579L20.4393 2.14645C20.6346 1.95118 20.9512 1.95118 21.1464 2.14645L21.8536 2.85355C22.0488 3.04882 22.0488 3.3654 21.8536 3.56066L18.4142 7L20.5 7C20.7761 7 21 7.22386 21 7.5V8.5C21 8.77614 20.7761 9 20.5 9L16 9H15.5C15.2239 9 15 8.77614 15 8.5V8V3.5C15 3.22386 15.2239 3 15.5 3L16.5 3C16.7761 3 17 3.22386 17 3.5V5.58579ZM16.5 21C16.7761 21 17 20.7761 17 20.5V18.4142L20.4393 21.8536C20.6346 22.0488 20.9512 22.0488 21.1464 21.8536L21.8536 21.1464C22.0488 20.9512 22.0488 20.6346 21.8536 20.4393L18.4142 17H20.5C20.7761 17 21 16.7761 21 16.5V15.5C21 15.2239 20.7761 15 20.5 15H16H15.5C15.2239 15 15 15.2239 15 15.5V16V20.5C15 20.7761 15.2239 21 15.5 21H16.5ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM5.58579 17H3.5C3.22386 17 3 16.7761 3 16.5V15.5C3 15.2239 3.22386 15 3.5 15H8H8.5C8.77614 15 9 15.2239 9 15.5V16V20.5C9 20.7761 8.77614 21 8.5 21H7.5C7.22386 21 7 20.7761 7 20.5V18.4142L3.56066 21.8536C3.3654 22.0488 3.04882 22.0488 2.85355 21.8536L2.14645 21.1464C1.95118 20.9512 1.95118 20.6346 2.14645 20.4393L5.58579 17Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default MeetingPointIcon
