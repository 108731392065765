import { Color, Mesh, MeshPhongMaterial } from 'three'
import { useCallback, useEffect, useRef } from 'react'
import { useSpring } from '@react-spring/three'
import { Mutation, Room } from '../../types/types'
import { disabledColor } from './render-map'
import { ANIMATION_DELAY } from '../../lib/static-values'
import colors from '../../data/colors'
import {
  ActiveBuildingStore,
  ActiveFloorStore,
  useActiveBuildingStore,
  useActiveFloorStore,
} from '../../hooks/store/use-active-card'
import { getIsReady, useIsReadyStore } from '../../hooks/store/use-is-ready'

const stairColor = new Color(colors.section9[100])

export const calcDelayTime = (
  hasUpperStairs: boolean | undefined,
  isUpperStair: boolean | undefined,
  room: Room | undefined,
  isLowerFloor: boolean,
  currentBuilding: boolean,
  lastBuilding: boolean,
  maxStairs: number,
  index: number | undefined,
) => {
  const upperStairsDelay = hasUpperStairs && !isUpperStair ? ANIMATION_DELAY : 0
  if (
    !room ||
    index === undefined ||
    room.floor === null ||
    !lastBuilding ||
    !currentBuilding
  ) {
    return 0
  }
  if (!isLowerFloor) {
    return (
      upperStairsDelay + (ANIMATION_DELAY / maxStairs) * (maxStairs - index)
    )
  }
  const withUpperStair = isUpperStair ? ANIMATION_DELAY : 0
  return withUpperStair + (ANIMATION_DELAY / maxStairs) * index
}

const useActiveBuilding = (room: Room | undefined) => {
  const getCurrentBuilding = useCallback((state: ActiveBuildingStore) => {
    if (!room) {
      return false
    }
    return state.activeBuilding === room.building
  }, [])
  return useActiveBuildingStore(getCurrentBuilding)
}
const useLowerFloor = (room: Room | undefined) => {
  const getActiveFloor = useCallback((state: ActiveFloorStore) => {
    if (!room || room.floor === null || state.activeFloor === null) {
      return false
    }
    return Math.floor(state.activeFloor) > Math.floor(room.floor)
  }, [])
  return useActiveFloorStore(getActiveFloor)
}
const useIsOnFloor = (room: Room | undefined) => {
  const getActiveFloor = useCallback((state: ActiveFloorStore) => {
    if (!room || room.floor === null || state.activeFloor === null) {
      return false
    }
    return Math.floor(state.activeFloor) === Math.floor(room.floor)
  }, [])
  return useActiveFloorStore(getActiveFloor)
}

export const RenderStair = ({
  material,
  mesh,
  room,
  hasUpperStairs,
  isUpperStair,
  maxStairs,
  index,
}: {
  mesh: Mesh
  material: MeshPhongMaterial
  room?: Room
  isUpperStair?: boolean
  index?: number
  maxStairs: number
  hasUpperStairs?: boolean
}) => {
  const isReady = useIsReadyStore(getIsReady)
  const activeBuilding = useActiveBuilding(room)
  const isLowerFloor = useLowerFloor(room)
  const isOnFloor = useIsOnFloor(room)

  const ref = useRef<Mesh>()
  const matRef = useRef(material)
  const isInitial = useRef(true)

  const mutation = useRef<
    Omit<
      Mutation,
      | 'isFocused'
      | 'zoomLevel'
      | 'isInOverview'
      | 'currentCard'
      | 'currentFloor'
      | 'lastFloor'
    >
  >({
    delayTime: 0,
    isInBuilding: activeBuilding,
    wasInBuilding: activeBuilding,
    set: (state) => {
      mutation.current = { ...mutation.current, ...state }
    },
  })

  const springValues = useCallback(() => {
    const { wasInBuilding, isInBuilding, delayTime } = mutation.current

    return {
      color:
        isLowerFloor && isInBuilding
          ? disabledColor.toArray()
          : [stairColor.r, stairColor.g, stairColor.b],
      visible: (isLowerFloor || isOnFloor) && isInBuilding,
      isReady,
      immediate:
        isInitial.current || wasInBuilding !== isInBuilding || !isLowerFloor,
      delay:
        (isLowerFloor && isInBuilding) || isInitial.current
          ? undefined
          : Math.floor(delayTime),
    }
  }, [isLowerFloor, isReady, isOnFloor])

  const [, setStairSpring] = useSpring(() => {
    return {
      ...springValues(),
      firstCall: true,
      onChange: ({ value }) => {
        if (!ref.current) {
          return
        }
        matRef.current.color.set(
          new Color(value.color[0], value.color[1], value.color[2]),
        )
        ref.current.visible = value.visible
      },
    }
  })

  useEffect(() => {
    if (!isReady) {
      return
    }

    const { wasInBuilding } = mutation.current

    mutation.current.set({
      isInBuilding: activeBuilding,
      delayTime: calcDelayTime(
        hasUpperStairs,
        isUpperStair,
        room,
        isLowerFloor,
        activeBuilding,
        wasInBuilding,
        maxStairs,
        index,
      ),
    })

    const { isInBuilding } = mutation.current

    setStairSpring.start({
      ...springValues(),
      firstCall: false,
      reset: true,
    })
    mutation.current.set({
      wasInBuilding: isInBuilding,
    })

    isInitial.current = false
  }, [isOnFloor, isLowerFloor, isReady, activeBuilding])

  return (
    <mesh
      ref={ref}
      {...mesh}
      castShadow
      receiveShadow
      geometry={mesh.geometry}
      material={matRef.current}
      renderOrder={4}
      layers={2}
    />
  )
}
