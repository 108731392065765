import create from 'zustand'

export type EndPointsStore = {
  endPoints: { x: number; y: number }
  setEndPoints: (payload: { x: number; y: number }) => void
}
export const useEndPointsStore = create<EndPointsStore>((set) => ({
  endPoints: { x: 0, y: 0 },
  setEndPoints: (payload) => set(() => ({ endPoints: payload })),
}))

export const getEndPoints = (state: EndPointsStore) => state.endPoints
