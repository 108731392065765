import { RippleEffect } from '../ripple/ripple'

export const NavListItem = ({
  icon,
  startIcon,
  isLast,
  title,
  onClick,
}: {
  icon?: JSX.Element
  startIcon?: JSX.Element
  isLast?: boolean
  title: string
  onClick?: (isOn?: boolean) => void
}) => {
  const handleClick = () => {
    onClick && onClick()
  }
  return (
    <RippleEffect
      className={`flex justify-between w-full px-3 pt-3.5 pb-3.5 bg-bg-800 cursor-pointer desktop:hover:bg-mono-600 text-l ${
        isLast ? '' : 'mb-0.5'
      } items-center`}
      onClick={handleClick}
    >
      <span
        className="flex items-center flex-grow"
        style={{ flexBasis: '100%' }}
      >
        {startIcon && <div className="mr-3">{startIcon}</div>}
        {title}
      </span>
      <span className="flex-grow">{icon && icon}</span>
    </RippleEffect>
  )
}
