import { SVGProps } from 'react'

const ArtistIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ ...props.style }}
    >
      <circle cx="8" cy="4.5" r="3" fill="currentColor" />

      <path
        d="M3.44293 11.3424C3.76439 9.41365 5.43315 8 7.38851 8H8.61149C10.5668 8 12.2356 9.41365 12.5571 11.3424L12.9321 13.5925C12.9676 13.8058 12.8031 14 12.5868 14H3.41316C3.19688 14 3.03237 13.8058 3.06792 13.5925L3.44293 11.3424Z"
        fill="currentColor"
        {...pathProps}
      />
    </svg>
  )
}

export default ArtistIcon
