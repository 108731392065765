import { SVGProps } from 'react'

const RestaurantIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.5C5 3.22386 5.22386 3 5.5 3H6.5C6.77614 3 7 3.22386 7 3.5V9L8 9V3.5C8 3.22386 8.22386 3 8.5 3H9.5C9.77614 3 10 3.22386 10 3.5V9H11V3.5C11 3.22386 11.2239 3 11.5 3H12.5C12.7761 3 13 3.22386 13 3.5V9C13 10.1046 12.1046 11 11 11H10V20.5C10 20.7761 9.77614 21 9.5 21H8.5C8.22386 21 8 20.7761 8 20.5V11H7C5.89543 11 5 10.1046 5 9V3.5ZM15.5 3C15.2239 3 15 3.22386 15 3.5V12V20.5C15 20.7761 15.2239 21 15.5 21H16.5C16.7761 21 17 20.7761 17 20.5V14C18.1046 14 19 13.1046 19 12V5C19 3.89543 18.1046 3 17 3H15.5Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default RestaurantIcon
