import create from 'zustand'

export type ZoomLevelStore = {
  zoomLevel: 1 | 2 | 3 | 4
  setZoomLevel: (payload: 1 | 2 | 3 | 4) => void
}
export const useZoomLevelStore = create<ZoomLevelStore>((set) => ({
  zoomLevel: 4,
  setZoomLevel: (payload) => set(() => ({ zoomLevel: payload })),
}))

export const getZoomLevel = (state: ZoomLevelStore) => state.zoomLevel
