import create from 'zustand'

export type BreakPointsStore = {
  breakPoints: 'lg' | 'md' | 'sm'
  setBreakPoints: (payload: 'lg' | 'md' | 'sm') => void
}
export const useBreakPointsStore = create<BreakPointsStore>((set) => ({
  breakPoints: 'lg',
  setBreakPoints: (payload) => set(() => ({ breakPoints: payload })),
}))

export const getBreakPoints = (state: BreakPointsStore) => state.breakPoints
