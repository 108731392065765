import { OrbitControls as OrbitControlsImpl } from 'three-stdlib/controls/OrbitControls'
import ThreeStore from '../lib/store'
import { MutableRefObject, useCallback, useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { setZoomLevel as calcZoomLevel } from '../lib/set-zoom-level'
import { useDistanceStore } from './store/use-distance'
import { useZoomLevelStore } from './store/use-zoom-level'

export const useZoom = (
  controller: MutableRefObject<OrbitControlsImpl | null>,
) => {
  const setDistance = useDistanceStore(
    useCallback((state) => state.setDistance, []),
  )
  const setZoomLevel = useZoomLevelStore(
    useCallback((state) => state.setZoomLevel, []),
  )
  const lastDistance = useRef<number>(0)
  useFrame(() => {
    const { isPinching, distance } = ThreeStore
    if (
      !controller.current ||
      (!isPinching && distance === lastDistance.current)
    ) {
      return
    }
    controller.current.maxDistance = distance
    controller.current.minDistance = distance
    setDistance(distance)
    setZoomLevel(calcZoomLevel(distance))
    lastDistance.current = distance
  })
}
