import { SVGProps } from 'react'

const LikeFilledIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22L10.55 20.7052C5.4 16.1243 2 13.103 2 9.3951C2 6.37384 4.42 4 7.5 4C9.5 4 10.91 5.77548 12 7.03106C13.09 5.77548 14.5 4 16.5 4C19.58 4 22 6.37384 22 9.3951C22 13.103 18.6 16.1243 13.45 20.715L12 22Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default LikeFilledIcon
