import { useCallback, useEffect } from 'react'
import { useIsLoadingStore } from '../../hooks/store/use-is-loading'

export const Loader = () => {
  const setIsLoading = useIsLoadingStore(
    useCallback((state) => state.setIsLoading, []),
  )
  useEffect(() => {
    setIsLoading(true)
    return () => {
      setIsLoading(false)
    }
  })
  return null
}
