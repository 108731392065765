import { SVGProps } from 'react'

const StairCardIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1.5C16 1.22386 16.2239 1 16.5 1H21.5C21.7761 1 22 1.22386 22 1.5V2.5C22 2.77614 21.7761 3 21.5 3H18V7.5C18 7.77614 17.7761 8 17.5 8H13V12.5C13 12.7761 12.7761 13 12.5 13H8V17.5C8 17.7761 7.77614 18 7.5 18H3V21.5C3 21.7761 2.77614 22 2.5 22H1.5C1.22386 22 1 21.7761 1 21.5V16.5C1 16.2239 1.22386 16 1.5 16H6V11.5C6 11.2239 6.22386 11 6.5 11H11V6.5C11 6.22386 11.2239 6 11.5 6H16V1.5Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default StairCardIcon
