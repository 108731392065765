import { Tag } from '../../types/types'
import create from 'zustand'

export type ActiveTagListStore = {
  activeTagList: Tag | null
  setActiveTagList: (payload: Tag | null) => void
}
export const useActiveTagListStore = create<ActiveTagListStore>((set) => ({
  activeTagList: null,
  setActiveTagList: (payload) => set(() => ({ activeTagList: payload })),
}))

export const getActiveTagList = (state: ActiveTagListStore) =>
  state.activeTagList

export const getActiveTagListString = (state: ActiveTagListStore) =>
  state.activeTagList?.id || '-'
