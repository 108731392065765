import { Tag } from '../../types/types'
import create from 'zustand'

export type ActivePillStore = {
  activePill: Tag | null
  setActivePill: (payload: Tag | null) => void
}
export const useActivePillStore = create<ActivePillStore>((set) => ({
  activePill: null,
  setActivePill: (payload) => set(() => ({ activePill: payload })),
}))

export const getActivePill = (state: ActivePillStore) => state.activePill

export const setActivePillStore = (state: ActivePillStore) =>
  state.setActivePill
