import { SVGProps } from 'react'

const CloseIconSmall = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ ...props.style }}
    >
      <path
        d="M2.59079 4.00501C2.45411 3.86832 2.45411 3.64672 2.59079 3.51003L3.51003 2.59079C3.64672 2.45411 3.86832 2.45411 4.00501 2.59079L13.4095 11.9953C13.5462 12.132 13.5462 12.3536 13.4095 12.4903L12.4903 13.4095C12.3536 13.5462 12.132 13.5462 11.9953 13.4095L2.59079 4.00501Z"
        fill="currentColor"
      />
      <path
        d="M11.9953 2.59079C12.132 2.45411 12.3536 2.45411 12.4903 2.59079L13.4095 3.51003C13.5462 3.64672 13.5462 3.86832 13.4095 4.00501L4.00501 13.4095C3.86832 13.5462 3.64672 13.5462 3.51003 13.4095L2.59079 12.4903C2.45411 12.3536 2.45411 12.132 2.59079 11.9953L11.9953 2.59079Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CloseIconSmall
