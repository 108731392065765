import { SVGProps } from 'react'

const OnBoardingIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`text-typo-100 ${props.className}`}
    >
      <path
        d="M23.9999 23.8636V15.5C23.9999 14.8354 23.8084 14.1762 23.3161 13.6839C22.8238 13.1915 22.1647 13 21.5 13C20.8353 13 20.1762 13.1916 19.6838 13.6839C19.1915 14.1762 19 14.8353 19 15.5V30.089L17.5972 27.2724L17.5864 27.2507L17.5745 27.2295C17.5366 27.1619 17.4979 27.0887 17.4569 27.0113C17.1441 26.4202 16.7023 25.5853 15.5387 25.1468L15.4422 25.1104L15.3402 25.0945C14.1214 24.9043 12.7835 25.6286 12.2977 26.8944L12.2811 26.9375L12.2686 26.982C12.0879 27.6246 12.2241 28.4557 12.6318 29.5036L12.6347 29.5111L12.6378 29.5187C14.4919 34.0655 17.1328 39.9843 20.2372 43.6466L20.5367 44H21H31.5H31.8637L32.1425 43.7663C32.6476 43.3428 33.1431 42.6738 33.5791 41.9727C34.028 41.2509 34.463 40.4142 34.831 39.5879L33.9175 39.1811L34.831 39.5879C35.6205 37.8151 36.2312 35.7046 36.5414 33.6954C36.8498 31.6988 36.8547 29.7058 36.4353 28.2295L35.4734 28.5028L36.4353 28.2295C36.2369 27.531 35.9613 26.8841 35.5072 26.385C35.0241 25.8539 34.4106 25.5673 33.6986 25.4763L33.6455 25.4695L33.592 25.4684C33.081 25.4581 32.5899 25.5179 32.0777 25.7714L32.0745 25.773C32.0493 25.4656 31.977 25.1745 31.8326 24.907C31.6436 24.5571 31.3844 24.3482 31.1975 24.2159C30.935 24.0301 30.6455 23.9245 30.438 23.8655C30.2458 23.8108 30.0065 23.7634 29.8076 23.7662L29.8061 23.7662C29.5024 23.7709 29.0819 23.8159 28.6845 24.137C28.4955 24.2898 28.3446 24.481 28.2169 24.6831C28.1811 24.4651 28.1179 24.2576 28.0175 24.0628C27.8354 23.7097 27.5828 23.5014 27.4032 23.3712C27.1548 23.1912 26.8793 23.0834 26.659 23.0198C26.4391 22.9564 26.1966 22.915 25.9786 22.915C25.5032 22.915 25.0331 23.1059 24.694 23.3038C24.4759 23.4312 24.215 23.6179 23.9999 23.8636Z"
        stroke="currentColor"
        strokeWidth="2"
        {...pathProps}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4141 9.00001L15.8535 11.4394C16.0487 11.6346 16.0487 11.9512 15.8535 12.1465L15.1464 12.8536C14.9511 13.0488 14.6345 13.0488 14.4392 12.8536L9.93925 8.35357C9.74398 8.15831 9.74398 7.84172 9.93925 7.64646L14.4392 3.14646C14.6345 2.9512 14.9511 2.9512 15.1464 3.14646L15.8535 3.85357C16.0487 4.04883 16.0487 4.36541 15.8535 4.56067L13.4141 7.00001H29.5857L27.1464 4.56067C26.9511 4.36541 26.9511 4.04883 27.1464 3.85357L27.8535 3.14646C28.0487 2.9512 28.3653 2.9512 28.5606 3.14646L33.0606 7.64646C33.2558 7.84172 33.2558 8.15831 33.0606 8.35357L28.5606 12.8536C28.3653 13.0488 28.0487 13.0488 27.8535 12.8536L27.1464 12.1465C26.9511 11.9512 26.9511 11.6346 27.1464 11.4394L29.5857 9.00001H13.4141Z"
        fill="currentColor"
        {...pathProps}
      />
    </svg>
  )
}

export default OnBoardingIcon
