import Link from 'next/link'
import {
  OpeningHours as OpeningHoursType,
  OpeningHoursDisplay,
} from '../../types/types'
import { renderIcon } from '../icons/render-icons'

export const OpeningHours = ({
  display,
  data,
}: {
  data: OpeningHoursType
  display: OpeningHoursDisplay[]
}) => {
  const refererUrl = window.location.href

  return (
    <div
      itemScope
      itemType="https://schema.org/Museum"
      className="py-6 border-t border-mono-700"
    >
      <div className="flex w-full justify-between">
        <div className="flex-col text-base text-typo-100 w-[142px] leading-4.5 flex-shrink-0">
          {data.openingHour.title}
        </div>
        <div className="text-typo-200 leading-4.5 flex-grow flex flex-col">
          {display.map(({ hours, days }, index) => {
            const maxDays = days.length
            if (!days.length) {
              return null
            }
            const renderDays = days.reduce((acc, entry, renderIndex) => {
              if (entry.firstDay === entry.lastDay) {
                acc += `${entry.name}${
                  maxDays > 0 && renderIndex < maxDays - 1 ? ', ' : ' '
                }`
                return acc
              }
              acc += `${entry.firstDay} - ${entry.lastDay}`
              return acc
            }, '')
            return (
              <time
                className="w-full pb-2"
                key={`time_${hours}_${renderDays}_${index}`}
              >
                {`${renderDays}`}
                <br />
                {`${hours}`}
              </time>
            )
          })}{' '}
          {data.openingHour.time.map(({ hour, day }) => {
            return day.map(({ shortName }) => {
              return (
                <meta
                  itemProp="openingHours"
                  content={`${shortName} ${hour.replace(' ', '')}`}
                  key={`meta_openingHours_${hour}_${shortName}`}
                />
              )
            })
          })}
        </div>
      </div>

      {data.openingHour.link ? (
        <Link
          href={`${data.openingHour.link.url}${
            data.openingHour.link.withReferer ? `?referrer=${refererUrl}` : ''
          }`}
        >
          <a className="flex flex-row h-[33px] mt-5 leading-4.5 items-center">
            {data.openingHour.link.endIcon ? (
              renderIcon({
                name: data.openingHour.link.endIcon.icon,
                withBG: false,
                props: { className: 'mr-2 items-center' },
              })
            ) : (
              <div />
            )}
            <span className="text-typo-100"> {data.openingHour.link.text}</span>
          </a>
        </Link>
      ) : null}
    </div>
  )
}
