import { RippleEffect } from '../ripple/ripple'
import { Tag } from '../../types/types'
import { forwardRef, useCallback, useMemo } from 'react'
import ArrowBackIcon from '../icons/arrow-back-icon'
import { NavListItem } from '../menu/nav-list-item'
import ArrowForwardIcon from '../icons/arrow-forward-icon'
import Link from 'next/link'
import {
  getActiveTagList,
  getActiveTagListString,
  useActiveTagListStore,
} from '../../hooks/store/use-active-tag-list'
import { getTags, useTagsStore } from '../../hooks/store/use-tags'
import {
  getFavoritesString,
  useFavoritesStore,
} from '../../hooks/store/use-favorites'
import {
  getTaggedString,
  useTaggedCardIdsStore,
} from '../../hooks/store/use-tagged-card-ids'
import {
  getActiveCardString,
  useActiveCardStore,
} from '../../hooks/store/use-active-card'
import { getOrbit, useOrbitStore } from '../../hooks/store/use-orbit-store'
import { useTranslationsStore } from '../../hooks/store/use-translations'

const useLink = () => {
  const cardId = useActiveCardStore(getActiveCardString)
  const favoritesString = useFavoritesStore(getFavoritesString)
  const taggedString = useTaggedCardIdsStore(getTaggedString)
  const activeTagListId = useActiveTagListStore(getActiveTagListString)
  const orbit = useOrbitStore(getOrbit)

  return useCallback(
    (navLevel: number, activeTagId: string) => {
      return `${cardId}/${orbit}/-/true/${navLevel}/${favoritesString}/${
        navLevel === 0 ? '-' : activeTagListId
      }/${activeTagId}/${taggedString}`
    },
    [cardId, orbit, favoritesString, activeTagListId, taggedString],
  )
}

export const AlphabeticListView = forwardRef<HTMLDivElement>(({}, ref) => {
  const goBackLink = useLink()
  const tagLink = useLink()
  const activeTagList = useActiveTagListStore(getActiveTagList)
  const tags = useTagsStore(getTags)

  const noEntries = useTranslationsStore(
    useCallback(
      (state) =>
        state.translations.find((translation) => translation.id === '58287879')
          ?.text || '',
      [],
    ),
  )

  const sortedByLetter = useMemo(() => {
    return activeTagList
      ? tags
          .filter(
            (tag) =>
              activeTagList.tagType &&
              tag.tagType &&
              tag.tagType.name === activeTagList.tagType.name,
          )
          .sort((last, next) => {
            const lastName = last.sortingName || last.name
            const nextName = next.sortingName || next.name
            return lastName > nextName ? 1 : -1
          })
          .reduce((acc, tag) => {
            const lastName = tag.sortingName ? tag.sortingName : tag.name

            const foundLetter = acc.find(({ letter }) => letter === lastName[0])
            if (foundLetter) {
              foundLetter.tags.push(tag)
              return acc
            }
            acc.push({ letter: lastName[0], tags: [tag] })
            return acc
          }, [] as { letter: string; tags: Tag[] }[])
          .sort((last, next) => {
            return last.letter > next.letter ? 1 : -1
          })
      : []
  }, [tags])

  return (
    <div ref={ref}>
      <div className="sticky desktop:relative top-0 desktop:top-none z-[100] bg-bg-900 desktop:bg-none">
        <div className="flex justify-between items-center desktop:pt-0 pt-7 pb-3 desktop:pb-0">
          <div>
            <h4 className="text-4xl font-black">
              {activeTagList && activeTagList.tagType
                ? activeTagList.tagType.title
                : ''}
            </h4>
          </div>
          <div className="flex items-end flex-col">
            <Link href={goBackLink(0, '-')} passHref shallow>
              <a>
                <RippleEffect className="rounded-full bg-mono-700 desktop:hover:bg-mono-600 w-13 h-13 flex justify-center items-center cursor-pointer">
                  <ArrowBackIcon />
                </RippleEffect>
              </a>
            </Link>
          </div>
        </div>
      </div>
      <ul>
        {sortedByLetter.length ? (
          sortedByLetter.map(({ letter, tags: tagEntries }) => {
            return tagEntries.map((tag, index) => {
              return (
                <li key={`listView_${letter}_${index}`}>
                  {index === 0 && (
                    <div className="pt-6 pb-2">
                      <p className="text-xs">{letter}</p>
                    </div>
                  )}
                  <Link href={tagLink(2, tag.id)} passHref shallow>
                    <a>
                      <NavListItem
                        title={tag.name}
                        icon={<ArrowForwardIcon />}
                      />
                    </a>
                  </Link>
                </li>
              )
            })
          })
        ) : (
          <li>
            <div>{noEntries}</div>
          </li>
        )}
      </ul>
    </div>
  )
})
