import Link from 'next/link'
import { RippleEffect } from '../ripple/ripple'
import { useRouter } from 'next/router'

export const NavListLink = ({
  href,
  icon,
  startIcon,
  isLast,
  title,
  external,
  referer,
}: {
  href?: string
  icon?: JSX.Element
  startIcon?: JSX.Element
  isLast?: boolean
  title: string
  external?: boolean
  locale?: string
  referer?: boolean | null
}) => {
  const { asPath } = useRouter()
  const refererUrl = window.location.href

  return (
    <Link
      href={`${href}${referer ? `?referrer=${refererUrl}` : ''}` || asPath}
      passHref={external}
      shallow
    >
      <a
        className={`relative flex justify-between item-center w-full px-3 pt-3.5 pb-3.5 bg-bg-800 desktop:hover:bg-mono-600  text-l ${
          isLast ? '' : 'mb-0.5'
        } items-center`}
        target={external ? '_blank' : undefined}
        rel={external ? 'noopener noreferrer' : undefined}
      >
        {startIcon && <div className="mr-2.5">{startIcon}</div>}
        <div className="flex-grow" style={{ flexBasis: '100%' }}>
          {title}
        </div>
        <div className="flex-grow">{icon && icon}</div>
        <RippleEffect className="left-0 top-0 w-full h-full" absolute />
      </a>
    </Link>
  )
}
