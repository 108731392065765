import { SVGProps } from 'react'

export const IconChevronLeft = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5492 7.75925C14.752 7.56278 14.752 7.23748 14.5492 7.04102L13.8227 6.33717C13.6288 6.14933 13.3208 6.14933 13.1269 6.33717L7.6521 11.641C7.4493 11.8375 7.4493 12.1628 7.6521 12.3592L13.1269 17.6631C13.3208 17.8509 13.6288 17.8509 13.8227 17.6631L14.5492 16.9592C14.752 16.7628 14.752 16.4375 14.5492 16.241L10.5423 12.3592C10.3395 12.1628 10.3395 11.8375 10.5423 11.641L14.5492 7.75925Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}

export default IconChevronLeft
