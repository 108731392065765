import { Card } from '../../types/types'
import create from 'zustand'

export type ActivecCardListStore = {
  activeCardList: Card[]
  setActiveCardList: (payload: Card[]) => void
}
export const useActiveCardListStore = create<ActivecCardListStore>((set) => ({
  activeCardList: [],
  setActiveCardList: (payload) => set(() => ({ activeCardList: payload })),
}))

export const getActiveCardList = (state: ActivecCardListStore) =>
  state.activeCardList
