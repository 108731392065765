import { Link } from '../../types/types'
import create from 'zustand'

export type QuicklinksStore = {
  quicklinks: Link[]
  setQuicklinks: (payload: Link[]) => void
}
export const useQuicklinksStore = create<QuicklinksStore>((set) => ({
  quicklinks: [],
  setQuicklinks: (payload) => set(() => ({ quicklinks: payload })),
}))

export const getQuicklinks = (state: QuicklinksStore) => state.quicklinks
