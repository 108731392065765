import create from 'zustand'

export type DistanceStore = {
  distance: number
  setDistance: (payload: number) => void
}
export const useDistanceStore = create<DistanceStore>((set) => ({
  distance: 4,
  setDistance: (payload) => set(() => ({ distance: payload })),
}))

export const getDistance = (state: DistanceStore) => state.distance
