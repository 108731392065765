import { SVGProps } from 'react'

const CloseIcon = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#F0F0F0"
        d="M17.9393 19.3536C18.1346 19.5488 18.4512 19.5488 18.6464 19.3536L19.3536 18.6464C19.5488 18.4512 19.5488 18.1346 19.3536 17.9393L13.4143 12.0001L19.3537 6.06066C19.549 5.8654 19.549 5.54882 19.3537 5.35355L18.6466 4.64645C18.4513 4.45119 18.1348 4.45119 17.9395 4.64645L12.0001 10.5859L6.06066 4.64645C5.8654 4.45118 5.54882 4.45118 5.35355 4.64645L4.64645 5.35355C4.45118 5.54882 4.45118 5.8654 4.64645 6.06066L10.5859 12.0001L4.6466 17.9393C4.45133 18.1346 4.45133 18.4512 4.6466 18.6464L5.3537 19.3536C5.54897 19.5488 5.86555 19.5488 6.06081 19.3536L12.0001 13.4143L17.9393 19.3536Z"
        {...pathProps}
      />
    </svg>
  )
}

export default CloseIcon
