import { Youtube } from './yotube'
import { useMemo } from 'react'

export const RenderVideo = ({
  title,
  caption,
  video,
}: {
  title: string
  caption: string
  video: { providerUid: string; provider: string }
}) => {
  const videoType = useMemo(() => {
    if (video.provider === 'youtube') {
      return <Youtube id={video.providerUid} title={title} />
    }
  }, [video])
  return (
    <div className="pt-6 pb-3">
      <div className="w-full relative" style={{ paddingTop: '56.25%' }}>
        {videoType}
      </div>
      {caption && (
        <div className="px-3.5 text-typo-300 text-xs pt-4">{caption}</div>
      )}
    </div>
  )
}
