import { Card } from '../../types/types'
import create from 'zustand'

export type CardsStore = {
  cards: Card[]
  setCards: (payload: Card[]) => void
}
export const useCardsStore = create<CardsStore>((set) => ({
  cards: [],
  setCards: (payload) => set(() => ({ cards: payload })),
}))

export const getCards = (state: CardsStore) => state.cards
