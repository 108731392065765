import create from 'zustand'
import { arrayToString } from '../../lib/array-to-string'

export type FavoritesStore = {
  favorites: string[]
  setFavorites: (payload: string[]) => void
}
export const useFavoritesStore = create<FavoritesStore>((set) => ({
  favorites: [],
  setFavorites: (payload) => set(() => ({ favorites: payload })),
}))

export const getFavorites = (state: FavoritesStore) => state.favorites

export const getFavoritesString = (state: FavoritesStore) =>
  arrayToString(state.favorites)
