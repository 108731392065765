import { SVGProps } from 'react'

export const IconChevronUp = ({
  pathProps,
  ...props
}: {
  pathProps?: SVGProps<SVGPathElement>
} & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.241 15.6293C16.4375 15.8321 16.7628 15.8321 16.9592 15.6293L17.6631 14.9028C17.8509 14.7089 17.8509 14.4008 17.6631 14.2069L12.3592 8.73217C12.1628 8.52938 11.8375 8.52938 11.641 8.73218L6.33717 14.2069C6.14933 14.4008 6.14933 14.7089 6.33717 14.9028L7.04102 15.6293C7.23748 15.8321 7.56278 15.8321 7.75925 15.6293L11.641 11.6224C11.8375 11.4196 12.1628 11.4196 12.3592 11.6224L16.241 15.6293Z"
        fill="#F0F0F0"
        {...pathProps}
      />
    </svg>
  )
}
export default IconChevronUp
