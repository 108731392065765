import { MutableRefObject, useEffect } from 'react'
import { Box3 } from 'three'
import { getIsReady, useIsReadyStore } from '../../hooks/store/use-is-ready'
import {
  getActiveBuilding,
  useActiveBuildingStore,
} from '../../hooks/store/use-active-card'

export const SetBoundingBox = ({
  boundingBox,
  exteriorBoundingBox,
  moserBoundingBox,
  cfBoundingBox,
}: {
  boundingBox: MutableRefObject<Box3 | null>
  exteriorBoundingBox: MutableRefObject<Box3 | null>
  moserBoundingBox: MutableRefObject<Box3 | null>
  cfBoundingBox: MutableRefObject<Box3 | null>
}) => {
  const activeBuilding = useActiveBuildingStore(getActiveBuilding)
  const isReady = useIsReadyStore(getIsReady)
  useEffect(() => {
    if (boundingBox.current !== null) {
      return
    }

    boundingBox.current = exteriorBoundingBox.current
  }, [isReady])

  useEffect(() => {
    if (activeBuilding === 'Moser') {
      boundingBox.current = moserBoundingBox.current
      return
    }
    if (activeBuilding === 'Chipperfield') {
      boundingBox.current = cfBoundingBox.current
      return
    }

    boundingBox.current = exteriorBoundingBox.current
  }, [activeBuilding])

  return null
}
